import { Box, Text, Grid, useToast, Button } from '@chakra-ui/react'
import { useNavigate, useParams } from "react-router-dom"
import { animation } from '../../BackData/animation.js'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { androidstudio } from 'react-syntax-highlighter/dist/esm/styles/hljs';


export const AnimationPage = ({ side, setSide }) => {
    const { id } = useParams()
    const toast = useToast();
    const navigate = useNavigate()

    const copyToClipboard = (ele) => {
        navigator.clipboard.writeText(ele);
        toast({ title: 'Code Copied', status: 'success', duration: 4000, isClosable: true, position: 'top' })
    }

    return (
        <Box w={'95%'} m={side ? '1.4cm auto 1cm auto' : '0.5cm auto'}>
            {!side && <Box display={'flex'} alignItems={'center'} fontSize={'25px'} fontWeight={'bold'} m={'0cm auto 1cm auto'}>
                <Text m={'0 10px'} cursor={'pointer'} onClick={() => setSide(!side)}>|||</Text>
                <Box display={'flex'} cursor={'pointer'} onClick={() => navigate('/artkon')}>
                    <Text color={'#66BB6A'}>Art</Text>
                    <Text color={'#424242'}>Korner</Text>
                </Box>
            </Box>}

            <Box onClick={() => navigate(-1)} cursor={'pointer'} m={'0 10px'} mb={'-20px'} fontWeight={'bold'} letterSpacing={'0.7px'} _hover={{ color: 'grey' }} color={'#424242'} transition={'0.3s ease'}>← Back</Box>
            <Box display={'flex'} flexDirection={['column', 'column', 'row']} justifyContent={'space-between'} alignItems={'center'} gap={'10px'} mb={'1cm'}>
                <Box m={'auto'} display={'block'}>
                    <style>{animation[id].CSS}</style>
                    <Box w={'200px'} h={'200px'} display={'flex'} justifyContent={'center'} alignItems={'center'} dangerouslySetInnerHTML={{ __html: animation[id].HTML }} />
                </Box>

                <Box w={['95%', '90%', '40%']} display={'flex'} flexDirection={'column'} gap={'10px'}>
                    <SyntaxHighlighter SyntaxHighlighter language="javascript" style={androidstudio} customStyle={{ width: '100%', maxHeight: '250px' }} >
                        {animation[id].HTML_ORG}
                    </SyntaxHighlighter>
                    <Box>
                        <Button onClick={() => copyToClipboard(animation[id].HTML_ORG)} w={'100%'} p={'10px'} bg={'#66BB6A'} color={'white'}>Copy HTML</Button>
                    </Box>
                    <SyntaxHighlighter SyntaxHighlighter language="javascript" style={androidstudio} customStyle={{ width: '100%', maxHeight: '250px' }} >
                        {animation[id].CSS}
                    </SyntaxHighlighter>
                    <Box>
                        <Button onClick={() => copyToClipboard(animation[id].CSS)} w={'100%'} p={'10px'} bg={'#66BB6A'} color={'white'}>Copy CSS</Button>
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}