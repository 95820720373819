export const linear =
  "#5C6BC0";
export const linear_btn =
  "#5C6BC0";

export const SkeletonData = [
  {
    id: 0,
    HTML: `
<div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
    <div style="height: 120px; background: #ff9900; background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    <div>
        <div style="width: 60px; height: 60px; border-radius: 50%; background: #ff3399; margin: 10px 0;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 20px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    </div>
</div>`,
    CSS: `@keyframes waves {
        100% {
            background-position: -100% 0;
        }
    }`,
  },
  {
    id: 1,
    HTML: `
        <div style="width: 100%;padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
        <div style="height: 120px; background: #ff9900; background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    </div>`,
    CSS: `
        @keyframes waves {
        100% {
            background-position: -100% 0;
        }
    }`,
  },
  {
    id: 2,
    HTML: `
    <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
        <div style="height: 120px; background: #ff9900; background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 70%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 40%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    </div>`,
    CSS: `
        @keyframes waves {
        100% {
            background-position: -100% 0;
        }
    }`,
  },
  {
    id: 3,
    HTML: `
        <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
        <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 45px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    </div>  `,
    CSS: `
        @keyframes waves {
        100% {
            background-position: -100% 0;
        }
    }`,
  },
  {
    id: 4,
    HTML: `
        <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
        <div style="display: flex;width: 100%;">
        <div>
        <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>
            <div style="width: 100%;margin: auto 10px ">
                <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>
        </div>
        <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    </div>`,
    CSS: `
        @keyframes waves {
        100% {
            background-position: -100% 0;
        }
    }`,
  },
  {
    id: 5,
    HTML: `
        <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
        <div style="display: flex;width: 100%;">
        <div>
        <div style="width: 150px; height: 150px; background: #ff3399;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>
            <div style="width: 100%;margin: auto 10px ">
                <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>
        </div>
        <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    </div>`,
    CSS: `
        @keyframes waves {
        100% {
            background-position: -100% 0;
        }
    }`,
  },
  {
    id: 6,
    HTML: `
        <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
        <div style="display: flex;width: 100%;">
        <div>
        <div style="width: 150px; height: 150px; background: #ff3399;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>
            <div style="width: 100%;margin: auto 10px ">
                <div style="width: 60%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                <div style="width: 95%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                <div style="width: 30%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>
        </div>
    </div>`,
    CSS: `
        @keyframes waves {
        100% {
            background-position: -100% 0;
        }
    }`,
  },
  {
    id: 7,
    HTML: `
        <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
        <div style="display: flex;width: 100%;">
        <div>
        <div style="width: 150px; height: 150px;border-radius:50% ; background: #ff3399;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>
            <div style="width: 100%;margin: auto 10px ">
                <div style="width: 60%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                <div style="width: 95%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                <div style="width: 30%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>
        </div>
    </div>`,
    CSS: `
        @keyframes waves {
        100% {
            background-position: -100% 0;
        }
    }`,
  },
  {
    id: 8,
    HTML: `
        <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    </div>
        `,
    CSS: `
        @keyframes waves {
        100% {
            background-position: -100% 0;
        }
    }`,
  },
  {
    id: 9,
    HTML: `
        <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
        <div style="display: flex;width: 100%;">
        <div>
        <div style="width: 150px; height: 150px; background: #ff3399;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>
            <div style="width: 100%;margin: auto 0 auto 10px ">
                <div style="width: 100%; height: 75px; background: #E0E0E0; border-radius: 2px; margin: auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                <div style="width: 100%; height: 27px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                <div style="width: 100%; height: 27px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>
        </div>
    </div>`,
    CSS: `
        @keyframes waves {
        100% {
            background-position: -100% 0;
        }
    }`,
  },
  {
    id: 10,
    HTML: `
        <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
        <div style="display: flex; width: 100%;">
        <div style="width: 50%">
        <div style="width: 100%; height: 150px; background: #ff3399;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>
            <div style="width: 50%; margin: auto 0 auto 10px ">
                <div style="width: 100%; height: 75px; background: #E0E0E0; border-radius: 2px; margin: auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                <div style="width: 100%; height: 27px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                <div style="width: 100%; height: 27px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>
        </div>
    </div>`,
    CSS: `
        @keyframes waves {
        100% {
            background-position: -100% 0;
        }
    }`,
  },
  {
    id: 11,
    HTML: `
        <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
        <div style="display: flex;width: 100%;">
        <div>
        <div style="width: 150px; height: 150px; border-radius: 50% ;background: #ff3399;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>
            <div style="width: 100%;margin: auto 0 auto 10px ">
                <div style="width: 100%; height: 75px; background: #E0E0E0; border-radius: 2px; margin: auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                <div style="width: 100%; height: 27px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                <div style="width: 100%; height: 27px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>
        </div>
    </div>`,
    CSS: `
        @keyframes waves {
        100% {
            background-position: -100% 0;
        }
    }`,
  },
];

export const SkeletonChildData = {
  "0fr": [
    {
      id: 1,
      HTML: `<div style="width: 100%; margin: auto; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
        <div style="height: 120px; background: #ff9900; background-image: linear-gradient(120deg, #6699ff 50%, #3366ff 60%, #3366ff 61%, #ff9900 70%); background-size: 200%; background-position: 100% 0; animation: waves1 2s linear infinite;"></div>
        <div>
            <div style="width: 60px; height: 60px; border-radius: 50%; background: #ff3399; margin: 10px 0;background-image: linear-gradient(120deg, #6699ff 50%, #3366ff 60%, #3366ff 61%, #ff9900 70%); background-size: 200%; background-position: 100% 0; animation: waves1 2s linear infinite;"></div>
            <div style="width: 100%; height: 20px; background: #6699ff; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #6699ff 50%, #3366ff 60%, #3366ff 61%, #ff9900 70%); background-size: 200%; background-position: 100% 0; animation: waves1 2s linear infinite;"></div>
        </div>
    </div>`,
      CSS: `@keyframes waves1 {
        100% {
            background-position: -100% 0;
        }
    }`,
    },
    {
      id: 2,
      HTML: `<div style="width: 100%; margin: auto; background: #f2f2f2; border-radius: 10px 0; overflow: hidden;">
        <div style="height: 150px; background: #ff6600; background-image: linear-gradient(45deg, #ffcc00 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves2 2s linear infinite;"></div>
        <div>
            <div style="width: 70px; height: 70px; border-radius: 50%; background: #339966; margin: 15px;background-image: linear-gradient(45deg, #33cc33 40%, #339966 60%); background-size: 200%; background-position: 100% 0; animation: waves2 2s linear infinite;"></div>
            <div style="width: 90%; height: 30px; background: #66ccff; border-radius: 5px; margin: 15px;background-image: linear-gradient(45deg, #66ccff 40%, #33cc33 60%); background-size: 200%; background-position: 100% 0; animation: waves2 2s linear infinite;"></div>
        </div>
    </div>`,
      CSS: `@keyframes waves2 {
        100% {
            background-position: -100% 0;
        }
    }`,
    },
    {
      id: 3,
      HTML: `<div style="width: 100%; margin: auto; background: #e0e0e0; border-radius: 8px; overflow: hidden;">
        <div style="height: 100px; background: #ff3399; background-image: linear-gradient(90deg, #ff6600 40%, #ff3399 60%); background-size: 200%; background-position: 100% 0; animation: waves3 2s linear infinite;"></div>
        <div>
            <div style="width: 50px; height: 50px; border-radius: 50%; background: #3366ff; margin: 12px;background-image: linear-gradient(90deg, #3366ff 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves3 2s linear infinite;"></div>
            <div style="width: 80%; height: 25px; background: #ff9900; border-radius: 3px; margin: 12px;background-image: linear-gradient(90deg, #ff9900 40%, #3366ff 60%); background-size: 200%; background-position: 100% 0; animation: waves3 2s linear infinite;"></div>
        </div>
    </div>`,
      CSS: `@keyframes waves3 {
        100% {
            background-position: -100% 0;
        }
    }`,
    },
    {
      id: 4,
      HTML: `<div style="width: 100%; margin: auto; background: #f0f0f0; border-radius: 6px; overflow: hidden;">
        <div style="height: 130px; background: #33cc33; background-image: linear-gradient(135deg, #33cc33 40%, #ffcc00 60%); background-size: 200%; background-position: 100% 0; animation: waves4 2s linear infinite;"></div>
        <div>
            <div style="width: 75px; height: 75px; border-radius: 50%; background: #ffcc00; margin: 14px;background-image: linear-gradient(135deg, #ffcc00 40%, #33cc33 60%); background-size: 200%; background-position: 100% 0; animation: waves4 2s linear infinite;"></div>
            <div style="width: 85%; height: 30px; background: #ff3399; border-radius: 4px; margin: 14px;background-image: linear-gradient(135deg, #ff3399 40%, #ffcc00 60%); background-size: 200%; background-position: 100% 0; animation: waves4 2s linear infinite;"></div>
        </div>
    </div>`,
      CSS: `@keyframes waves4 {
        100% {
            background-position: -100% 0;
        }
    }`,
    },
    {
      id: 5,
      HTML: `<div style="width: 100%; margin: auto; background: #f9f9f9; border-radius: 7px; overflow: hidden;">
        <div style="height: 140px; background: #6699ff; background-image: linear-gradient(60deg, #ff6600 40%, #6699ff 60%); background-size: 200%; background-position: 100% 0; animation: waves5 2s linear infinite;"></div>
        <div>
            <div style="width: 70px; height: 70px; border-radius: 50%; background: #ff9900; margin: 13px;background-image: linear-gradient(60deg, #ff9900 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves5 2s linear infinite;"></div>
            <div style="width: 90%; height: 25px; background: #ff3399; border-radius: 4px; margin: 13px;background-image: linear-gradient(60deg, #ff3399 40%, #ff9900 60%); background-size: 200%; background-position: 100% 0; animation: waves5 2s linear infinite;"></div>
        </div>
    </div>`,
      CSS: `@keyframes waves5 {
        100% {
            background-position: -100% 0;
        }
    }`,
    },
    {
      id: 6,
      HTML: `<div style="width: 100%; margin: auto; background: #f7f7f7; border-radius: 8px; overflow: hidden;">
        <div style="height: 110px 0; background: #ffcc00; background-image: linear-gradient(90deg, #ffcc00 40%, #ff9900 60%); background-size: 200%; background-position: 100% 0; animation: waves6 2s linear infinite;"></div>
        <div>
            <div style="width: 65px; height: 65px; border-radius: 50%; background: #ff3399; margin: 10px 0; background-image: linear-gradient(90deg, #ff3399 40%, #ffcc00 60%); background-size: 200%; background-position: 100% 0; animation: waves6 2s linear infinite;"></div>
            <div style="width: 85%; height: 28px; background: #6699ff; border-radius: 4px; margin: 10px 0; background-image: linear-gradient(90deg, #6699ff 40%, #ff3399 60%); background-size: 200%; background-position: 100% 0; animation: waves6 2s linear infinite;"></div>
        </div>
    </div>`,
      CSS: `@keyframes waves6 {
        100% {
            background-position: -100% 0;
        }
    }`,
    },
    {
      id: 7,
      HTML: `<div style="width: 100%; margin: auto; background: #e6e6e6; border-radius: 6px; overflow: hidden;">
        <div style="height: 120px; background: #ff6600; background-image: linear-gradient(135deg, #ff6600 40%, #ffcc00 60%); background-size: 200%; background-position: 100% 0; animation: waves7 2s linear infinite;"></div>
        <div>
            <div style="width: 75px; height: 75px; border-radius: 50%; background: #ff9900; margin: 14px; background-image: linear-gradient(135deg, #ff9900 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves7 2s linear infinite;"></div>
            <div style="width: 85%; height: 30px; background: #33cc33; border-radius: 4px; margin: 14px; background-image: linear-gradient(135deg, #33cc33 40%, #ff9900 60%); background-size: 200%; background-position: 100% 0; animation: waves7 2s linear infinite;"></div>
        </div>
    </div>`,
      CSS: `@keyframes waves7 {
        100% {
            background-position: -100% 0;
        }
    }`,
    },
    {
      id: 8,
      HTML: `<div style="width: 100%; margin: auto; background: #f5f5f5; border-radius: 10px 0; overflow: hidden;">
        <div style="height: 130px; background: #6699ff; background-image: linear-gradient(60deg, #6699ff 40%, #33cc33 60%); background-size: 200%; background-position: 100% 0; animation: waves8 2s linear infinite;"></div>
        <div>
            <div style="width: 70px; height: 70px; border-radius: 50%; background: #ff3399; margin: 12px; background-image: linear-gradient(60deg, #ff3399 40%, #6699ff 60%); background-size: 200%; background-position: 100% 0; animation: waves8 2s linear infinite;"></div>
            <div style="width: 90%; height: 25px; background: #ffcc00; border-radius: 3px; margin: 12px; background-image: linear-gradient(60deg, #ffcc00 40%, #ff3399 60%); background-size: 200%; background-position: 100% 0; animation: waves8 2s linear infinite;"></div>
        </div>
    </div>`,
      CSS: `@keyframes waves8 {
        100% {
            background-position: -100% 0;
        }
    }`,
    },
    {
      id: 9,
      HTML: `<div style="width: 100%; margin: auto; background: #f8f8f8; border-radius: 7px; overflow: hidden;">
        <div style="height: 140px; background: #ff3399; background-image: linear-gradient(120deg, #ff3399 40%, #ff9900 60%); background-size: 200%; background-position: 100% 0; animation: waves9 2s linear infinite;"></div>
        <div>
            <div style="width: 60px; height: 60px; border-radius: 50%; background: #6699ff; margin: 13px; background-image: linear-gradient(120deg, #6699ff 40%, #ff3399 60%); background-size: 200%; background-position: 100% 0; animation: waves9 2s linear infinite;"></div>
            <div style="width: 80%; height: 22px; background: #ffcc00; border-radius: 5px; margin: 13px; background-image: linear-gradient(120deg, #ffcc00 40%, #6699ff 60%); background-size: 200%; background-position: 100% 0; animation: waves9 2s linear infinite;"></div>
        </div>
    </div>`,
      CSS: `@keyframes waves9 {
        100% {
            background-position: -100% 0;
        }
    }`,
    },
    {
      id: 10,
      HTML: `<div style="width: 100%; margin: auto; background: #e8e8e8; border-radius: 6px; overflow: hidden;">
        <div style="height: 120px; background: #ff6600; background-image: linear-gradient(135deg, #ff6600 40%, #33cc33 60%); background-size: 200%; background-position: 100% 0; animation: waves10 2s linear infinite;"></div>
        <div>
            <div style="width: 75px; height: 75px; border-radius: 50%; background: #ffcc00; margin: 14px; background-image: linear-gradient(135deg, #ffcc00 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves10 2s linear infinite;"></div>
            <div style="width: 85%; height: 30px; background: #33cc33; border-radius: 4px; margin: 14px; background-image: linear-gradient(135deg, #33cc33 40%, #ffcc00 60%); background-size: 200%; background-position: 100% 0; animation: waves10 2s linear infinite;"></div>
        </div>
    </div>`,
      CSS: `@keyframes waves10 {
        100% {
            background-position: -100% 0;
        }
    }`,
    },
  ],
  "1fr": [
    {
      id: 0,
      HTML: `
            <div style="width: 100%;padding: 10px; box-sizing: border-box; background: #f2f2f2; border-radius: 5px; overflow: hidden;">
            <div style="height: 120px; background: #ff9900; background-image: linear-gradient(120deg, #6699ff 50%, #3366ff 60%, #3366ff 61%, #ff9900 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image:linear-gradient(120deg, #6699ff 50%, #3366ff 60%, #3366ff 61%, #ff9900 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #6699ff 50%, #3366ff 60%, #3366ff 61%, #ff9900 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>`,
      CSS: `
            @keyframes waves16 {
            100% {
                background-position: -100% 0;
            }
        }`,
    },
    {
      id: 1,
      HTML: `
            <div style="width: 100%;padding: 10px; box-sizing: border-box; background: #f2f2f2; border-radius: 5px; overflow: hidden;">
        <div style="height: 120px; background: #ff9900; background-image: linear-gradient(45deg, #ffcc00 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(45deg, #33cc33 40%, #339966 60%); background-size: 200%; background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(45deg, #66ccff 40%, #33cc33 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    </div>`,
      CSS: `
            @keyframes waves17 {
            100% {
                background-position: -100% 0;
            }
        }`,
    },
    {
      id: 2,
      HTML: `
            <div style="width: 100%;padding: 10px; box-sizing: border-box; background: #f2f2f2; border-radius: 5px; overflow: hidden;">
        <div style="height: 120px; background: #ff9900; background-image: linear-gradient(90deg, #ff6600 40%, #ff3399 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(90deg, #3366ff 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(90deg, #ff9900 40%, #3366ff 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    </div>`,
      CSS: `
            @keyframes waves19 {
            100% {
                background-position: -100% 0;
            }
        }`,
    },
    {
      id: 3,
      HTML: `
            <div style="width: 100%;padding: 10px; box-sizing: border-box; background: #f2f2f2; border-radius: 5px; overflow: hidden;">
        <div style="height: 120px; background: #ff9900; background-image: linear-gradient(135deg, #33cc33 40%, #ffcc00 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image:  linear-gradient(135deg, #ffcc00 40%, #33cc33 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image:  linear-gradient(135deg, #ff3399 40%, #ffcc00 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    </div>`,
      CSS: `
            @keyframes waves {
              100% {
                background-position: -100% 0;
              }
            }`,
    },
    {
      id: 4,
      HTML: `
            <div style="width: 100%;padding: 10px; box-sizing: border-box; background: #f2f2f2; border-radius: 5px; overflow: hidden;">
        <div style="height: 120px; background: #ff9900; background-image: linear-gradient(60deg, #ff6600 40%, #6699ff 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(60deg, #ff9900 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(60deg, #ff3399 40%, #ff9900 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    </div>`,
      CSS: `
            @keyframes waves {
              100% {
                background-position: -100% 0;
              }
            }`,
    },
    {
      id: 5,
      HTML: `
            <div style="width: 100%;padding: 10px; box-sizing: border-box; background: #f2f2f2; border-radius: 5px; overflow: hidden;">
        <div style="height: 120px; background: #ff9900; background-image: linear-gradient(90deg, #ffcc00 40%, #ff9900 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(90deg, #ff3399 40%, #ffcc00 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(90deg, #6699ff 40%, #ff3399 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    </div>`,
      CSS: `
            @keyframes waves {
              100% {
                background-position: -100% 0;
              }
            }`,
    },
    {
      id: 6,
      HTML: `
            <div style="width: 100%;padding: 10px; box-sizing: border-box; background: #f2f2f2; border-radius: 5px; overflow: hidden;">
        <div style="height: 120px; background: #ff9900; background-image: linear-gradient(135deg, #ff6600 40%, #ffcc00 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(135deg, #ff9900 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(135deg, #33cc33 40%, #ff9900 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    </div>`,
      CSS: `
            @keyframes waves {
              100% {
                background-position: -100% 0;
              }
            }`,
    },
    {
      id: 7,
      HTML: `
            <div style="width: 100%;padding: 10px; box-sizing: border-box; background: #f2f2f2; border-radius: 5px; overflow: hidden;">
        <div style="height: 120px; background: #ff9900; background-image: linear-gradient(60deg, #6699ff 40%, #33cc33 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(60deg, #ff3399 40%, #6699ff 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(60deg, #ffcc00 40%, #ff3399 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    </div>`,
      CSS: `
            @keyframes waves {
              100% {
                background-position: -100% 0;
              }
            }`,
    },
  ],
  "2fr": [
    {
      id: 1,
      HTML: `
            <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
              <div style="height: 120px; background: #ff9900; background-image: linear-gradient(120deg, #6699ff 50%, #3366ff 60%, #3366ff 61%, #ff9900 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(120deg, #6699ff 50%, #3366ff 60%, #3366ff 61%, #ff9900 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 70%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(120deg, #6699ff 50%, #3366ff 60%, #3366ff 61%, #ff9900 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 40%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(120deg, #6699ff 50%, #3366ff 60%, #3366ff 61%, #ff9900 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>`,
      CSS: `
            @keyframes waves {
              100% {
                background-position: -100% 0;
              }
            }`,
    },
    {
      id: 2,
      HTML: `
            <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
              <div style="height: 120px; background: #ff9900; background-image: linear-gradient(120deg, #66CDAA 50%, #20B2AA 60%, #20B2AA 61%, #66CDAA 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(120deg, #66CDAA 50%, #20B2AA 60%, #20B2AA 61%, #66CDAA 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 70%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(120deg, #66CDAA 50%, #20B2AA 60%, #20B2AA 61%, #66CDAA 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 40%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(120deg, #66CDAA 50%, #20B2AA 60%, #20B2AA 61%, #66CDAA 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>`,
      CSS: `
            @keyframes waves {
              100% {
                background-position: -100% 0;
              }
            }`,
    },
    {
      id: 3,
      HTML: `
            <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
              <div style="height: 120px; background: #ff9900; background-image: linear-gradient(120deg, #6495ED 50%, #4169E1 60%, #4169E1 61%, #6495ED 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(120deg, #6495ED 50%, #4169E1 60%, #4169E1 61%, #6495ED 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 70%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(120deg, #6495ED 50%, #4169E1 60%, #4169E1 61%, #6495ED 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 40%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(120deg, #6495ED 50%, #4169E1 60%, #4169E1 61%, #6495ED 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>`,
      CSS: `
            @keyframes waves {
              100% {
                background-position: -100% 0;
              }
            }`,
    },
    {
      id: 4,
      HTML: `
            <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
              <div style="height: 120px; background: #ff9900; background-image: linear-gradient(120deg, #FF8C00 50%, #FFA500 60%, #FFA500 61%, #FF8C00 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(120deg, #FF8C00 50%, #FFA500 60%, #FFA500 61%, #FF8C00 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 70%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(120deg, #FF8C00 50%, #FFA500 60%, #FFA500 61%, #FF8C00 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 40%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(120deg, #FF8C00 50%, #FFA500 60%, #FFA500 61%, #FF8C00 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>`,
      CSS: `
            @keyframes waves {
              100% {
                background-position: -100% 0;
              }
            }`,
    },
    {
      id: 5,
      HTML: `
            <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
              <div style="height: 120px; background: #ff9900; background-image: linear-gradient(120deg, #8A2BE2 50%, #9932CC 60%, #9932CC 61%, #8A2BE2 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(120deg, #8A2BE2 50%, #9932CC 60%, #9932CC 61%, #8A2BE2 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 70%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(120deg, #8A2BE2 50%, #9932CC 60%, #9932CC 61%, #8A2BE2 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 40%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(120deg, #8A2BE2 50%, #9932CC 60%, #9932CC 61%, #8A2BE2 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>`,
      CSS: `
            @keyframes waves {
              100% {
                background-position: -100% 0;
              }
            }`,
    },
    {
      id: 6,
      HTML: `
            <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
              <div style="height: 120px; background: #ff9900; background-image: linear-gradient(135deg, #ff6600 40%, #ffcc00 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(135deg, #ff9900 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 70%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(135deg, #33cc33 40%, #ff9900 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 40%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(135deg, #ff9900 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>`,
      CSS: `
            @keyframes waves {
              100% {
                background-position: -100% 0;
              }
            }`,
    },
    {
      id: 7,
      HTML: `
            <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
              <div style="height: 120px; background: #ff9900; background-image: linear-gradient(60deg, #6699ff 40%, #33cc33 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(135deg, #ff9900 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 70%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(60deg, #ff3399 40%, #6699ff 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
              <div style="width: 40%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0; background-image: linear-gradient(60deg, #ffcc00 40%, #ff3399 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>`,
      CSS: `
            @keyframes waves {
              100% {
                background-position: -100% 0;
              }
            }`,
    },
  ],
  "3fr": [
    {
      id: 0,
      HTML: `
            <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
            <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399; margin: 10px auto;background-image: linear-gradient(120deg, #6699ff 50%, #3366ff 60%, #3366ff 61%, #ff9900 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 45px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #6699ff 50%, #3366ff 60%, #3366ff 61%, #ff9900 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #6699ff 50%, #3366ff 60%, #3366ff 61%, #ff9900 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(120deg, #6699ff 50%, #3366ff 60%, #3366ff 61%, #ff9900 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>  `,
      CSS: `
            @keyframes waves {
            100% {
                background-position: -100% 0;
            }
        }`,
    },
    {
      id: 1,
      HTML: `
        <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
        <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399; margin: 10px auto;background-image: linear-gradient(45deg, #ffcc00 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 45px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(45deg, #33cc33 40%, #339966 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(45deg, #66ccff 40%, #33cc33 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(45deg, #33cc33 40%, #339966 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    </div>  `,
      CSS: `
        @keyframes waves {
        100% {
            background-position: -100% 0;
        }
    }`,
    },
    {
      id: 2,
      HTML: `
    <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
    <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399; margin: 10px auto;background-image: linear-gradient(90deg, #ff6600 40%, #ff3399 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    <div style="width: 100%; height: 45px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(90deg, #3366ff 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(90deg, #ff9900 40%, #3366ff 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(90deg, #3366ff 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
</div>  `,
      CSS: `
    @keyframes waves {
    100% {
        background-position: -100% 0;
    }
}`,
    },
    {
      id: 3,
      HTML: `
    <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
    <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399; margin: 10px auto;background-image: linear-gradient(135deg, #33cc33 40%, #ffcc00 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    <div style="width: 100%; height: 45px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(135deg, #ffcc00 40%, #33cc33 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(135deg, #ff3399 40%, #ffcc00 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(135deg, #ffcc00 40%, #33cc33 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
</div>  `,
      CSS: `
    @keyframes waves {
    100% {
        background-position: -100% 0;
    }
}`,
    },
    {
      id: 4,
      HTML: `
    <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
    <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399; margin: 10px auto;background-image: linear-gradient(60deg, #ff6600 40%, #6699ff 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    <div style="width: 100%; height: 45px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(60deg, #ff3399 40%, #ff9900 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(60deg, #ff6600 40%, #6699ff 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
    <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(60deg, #ff3399 40%, #ff9900 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
</div>  `,
      CSS: `
    @keyframes waves {
    100% {
        background-position: -100% 0;
    }
}`,
    },
    {
      id: 5,
      HTML: `
      <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
      <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399; margin: 10px auto;background-image: linear-gradient(90deg, #ffcc00 40%, #ff9900 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
      <div style="width: 100%; height: 45px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(90deg, #ff3399 40%, #ffcc00 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
      <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(90deg, #6699ff 40%, #ff3399 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
      <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(90deg, #ff3399 40%, #ffcc00 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
  </div>  `,
      CSS: `
      @keyframes waves {
      100% {
          background-position: -100% 0;
      }
  }`,
    },
    {
      id: 6,
      HTML: `
      <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
      <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399; margin: 10px auto;background-image: linear-gradient(135deg, #ff6600 40%, #ffcc00 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
      <div style="width: 100%; height: 45px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(135deg, #ff9900 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
      <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(135deg, #33cc33 40%, #ff9900 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
      <div style="width: 100%; height: 30px; background: #E0E0E0; border-radius: 2px; margin: 10px 0;background-image: linear-gradient(135deg, #ff9900 40%, #ff6600 60%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
  </div>  `,
      CSS: `
      @keyframes waves {
      100% {
          background-position: -100% 0;
      }
  }`,
    },
  ],
  "4fr": [
    {
      id: 0,
      HTML: `
            <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
            <div style="display: flex;width: 100%;">
            <div>
            <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>
                <div style="width: 100%;margin: auto 10px ">
                    <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                    <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                </div>
            </div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>`,
      CSS: `
            @keyframes waves {
            100% {
                background-position: -100% 0;
            }
        }`,
    },
    {
      id: 1,
      HTML: `
            <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
            <div style="display: flex;width: 100%;">
            <div>
            <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>
                <div style="width: 100%;margin: auto 10px ">
                    <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                    <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                </div>
            </div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>`,
      CSS: `
            @keyframes waves {
            100% {
                background-position: -100% 0;
            }
        }`,
    },
    {
      id: 2,
      HTML: `
            <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
            <div style="display: flex;width: 100%;">
            <div>
            <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>
                <div style="width: 100%;margin: auto 10px ">
                    <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                    <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                </div>
            </div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>`,
      CSS: `
            @keyframes waves {
            100% {
                background-position: -100% 0;
            }
        }`,
    },
    {
      id: 3,
      HTML: `
            <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
            <div style="display: flex;width: 100%;">
            <div>
            <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>
                <div style="width: 100%;margin: auto 10px ">
                    <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                    <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                </div>
            </div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>`,
      CSS: `
            @keyframes waves {
            100% {
                background-position: -100% 0;
            }
        }`,
    },
    {
      id: 4,
      HTML: `
            <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
            <div style="display: flex;width: 100%;">
            <div>
            <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>
                <div style="width: 100%;margin: auto 10px ">
                    <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                    <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                </div>
            </div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>`,
      CSS: `
            @keyframes waves {
            100% {
                background-position: -100% 0;
            }
        }`,
    },
    {
      id: 5,
      HTML: `
            <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
            <div style="display: flex;width: 100%;">
            <div>
            <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>
                <div style="width: 100%;margin: auto 10px ">
                    <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                    <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                </div>
            </div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>`,
      CSS: `
            @keyframes waves {
            100% {
                background-position: -100% 0;
            }
        }`,
    },
    {
      id: 6,
      HTML: `
            <div style="width: 100%; padding: 10px; box-sizing: border-box; background: #F5F5F5; border-radius: 5px; overflow: hidden;">
            <div style="display: flex;width: 100%;">
            <div>
            <div style="width: 150px; height: 150px; border-radius: 50%; background: #ff3399;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            </div>
                <div style="width: 100%;margin: auto 10px ">
                    <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                    <div style="width: 100%; height: 35px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
                </div>
            </div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
            <div style="width: 100%; height: 25px; background: #E0E0E0; border-radius: 2px; margin: 10px auto;background-image: linear-gradient(120deg, #E0E0E0 50%, #FAFAFA 60%, #FAFAFA 61%, #E0E0E0 70%); background-size: 200%; background-position: 100% 0; animation: waves 2s linear infinite;"></div>
        </div>`,
      CSS: `
            @keyframes waves {
            100% {
                background-position: -100% 0;
            }
        }`,
    },
  ],
};
