import { Box, useMediaQuery } from '@chakra-ui/react';
import { MainRouter } from './Components/AllRoutes/MainRouter';

function App() {


  return (
    <Box>
      <MainRouter />
    </Box>
  );
}

export default App;
