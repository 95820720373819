import React, { useState } from 'react';
import { Box, Text, Grid, useToast, Input, Button } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';

function generateGradientColor(colors, steps, angle) {
  const numColors = colors.length;
  const colorSteps = steps / (numColors - 1);

  const gradientColors = [];
  for (let i = 0; i < steps; i++) {
    const segment = Math.floor(i / colorSteps);
    const t = (i % colorSteps) / (colorSteps - 1);

    const color1 = colors[segment];
    const color2 = colors[Math.min(segment + 1, numColors - 1)];

    const r = Math.round(color1[0] + (color2[0] - color1[0]) * t);
    const g = Math.round(color1[1] + (color2[1] - color1[1]) * t);
    const b = Math.round(color1[2] + (color2[2] - color1[2]) * t);
    gradientColors.push(`rgb(${r}, ${g}, ${b})`);
  }

  const angleValue = `${angle}deg`;
  return `linear-gradient(${angleValue}, ${gradientColors.join(', ')})`;
}

export const GradientMaker = ({ setSide, side }) => {
  const [numColors, setNumColors] = useState(4);
  const [colors, setColors] = useState([[255, 0, 0], [41, 147, 219], [1, 231, 136], [255, 213, 0]]);
  const [steps, setSteps] = useState(20);
  const [angle, setAngle] = useState(30);
  const navigate = useNavigate()
  const toast = useToast();

  const handleNumColorsChange = (event) => {
    const newNumColors = parseInt(event.target.value);
    setNumColors(newNumColors);
    setColors([...Array.from({ length: newNumColors }, (_, index) => colors[index] || [0, 0, 0])]);
  };

  const gradientValue = generateGradientColor(colors, steps, angle);

  const copyToClipboard = (ele) => {
    navigator.clipboard.writeText(ele);
    toast({ title: 'Gradient Color Copied', status: 'success', duration: 2000, isClosable: true, position: 'top' })
  }

  return (
    <Box m={'0.5cm auto 1cm auto'}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} m={'0.5cm auto 0 auto'}>
        <Box>{!side && <Box display={'flex'} alignItems={'center'} fontSize={['20px', '25px']} fontWeight={'bold'} m={'auto'}>
          <Text ml={'10px'} cursor={'pointer'} onClick={() => setSide(!side)}>|||</Text>
          <Text ml={'15px'} cursor={'pointer'} onClick={() => navigate('/')}>ArtKorner</Text>
        </Box>}
        </Box>
        <select value={numColors} onChange={handleNumColorsChange} style={{ width: '200px', fontSize: '14px', padding: '10px', border: '1px solid grey', borderRadius: '25px' }}>
          <option value={2}>2 Colors</option>
          <option value={3}>3 Colors</option>
          <option value={4}>4 Colors</option>
        </select>
      </Box>
        <Box onClick={()=>navigate('/GradientColors')} m={['20px 15px','10px','10px']} fontSize={'15px'} fontWeight={'medium'} cursor={'pointer'} color={'grey'} _hover={{color:'#424242'}} transition={'0.3s ease'}>
          Try New Gradients →
        </Box>


      <Box w={['100%']} display={'flex'} justifyContent={'space-between'} flexDirection={['column', 'column', 'row', 'row']}>
        <Box w={['95%', '95%', '50%', '50%']} m={'0 auto'}>
          <Grid templateColumns={['repeat(2, 1fr)']}>
            {colors.map((color, index) => (
              <Box key={index} w={'100%'}>
                <Input w={'100%'} h={['150px', '150px', '200px', '200px']} key={index} type="color" value={`#${color.map(c => c.toString(16).padStart(2, '0')).join('')}`} onChange={e => { const newColor = e.target.value.slice(1).match(/.{1,2}/g).map(c => parseInt(c, 16)); const newColors = [...colors]; newColors[index] = newColor; setColors(newColors); }} />

              </Box>
            ))}
          </Grid>
        </Box>
        <Box w={['95%', '95%', '50%', '50%']} p={'10px'} m={'0 auto'}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Box borderRadius={'10px'} w={['95%', '100%', '80%', '80%']} height={['200px']} m={'auto'} background={gradientValue}></Box>
          </Box>
          <Box w={'80%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} m={'10px auto 5px auto'}>
            <Text>Steps:</Text>
            <Input maxLength={'3'} border={'1px solid grey'} w={'50%'} type="number" value={steps} onChange={e => setSteps(e.target.value)} />
          </Box>
          <Box w={'80%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} m={'5px auto'}>
            <Text>Angle:</Text>
            <Input border={'1px solid grey'} w={'50%'} type="number" value={angle} onChange={e => setAngle(e.target.value)} />
          </Box>

          <Box w={'80%'} m={'1cm auto'}>
            <Input value={gradientValue} color={'grey'} placeholder={'Gradient Value'} />
            <Button onClick={() => copyToClipboard(gradientValue)} bg={gradientValue} mt={'10px'} w={'100%'} color={'whiteAlpha.900'}>Copy</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
