import { Route, Routes } from 'react-router-dom'
import { QuizHome } from '../../Quiz/QuizHome/QuizHome'
import { QuizPage } from '../../Quiz/QuizPage/QuizPage'
import { QuizSelector } from '../../Quiz/QuizSelector/QuizSelector'
import { Dashboard } from '../../Quiz/Dashboard/Dashboard.jsx'
import { useEffect } from 'react'



export const QuizRouter = ({ }) => {
    
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the new page
    }, []);

    return (
        <Routes>
            <Route path='/' element={<QuizHome />}></Route>
            <Route path='/QuizSelector' element={<QuizSelector/>}></Route>
            <Route path='/QuizSelector/QuizPage' element={<QuizPage/>}></Route>
            <Route path='/Dashboard' element={<Dashboard/>}></Route>
        </Routes>
    )
}