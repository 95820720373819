import { Box, Heading, Text } from "@chakra-ui/react"
import { useLocation, useNavigate } from "react-router-dom"
import { QuizSelector_data } from '../Quiz_data/Quiz_data.js'
import { RoadMap } from "../RoadMap/RoadMap.jsx"
import { useEffect } from "react"



export const QuizSelector = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const language = queryParams.get('language');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box minH={'100vh'} color={'white'} bg={'white'}>

            <Box onClick={() => navigate('/quiz')} cursor={'pointer'} w={'fit-content'} display={'flex'} gap={'10px'} p={'15px'} pb={'0.5cm'}>
                <Text color={'orange'} fontSize={'25px'} fontWeight={'bold'}>|| Quiz</Text>
                <Text color={'grey'} fontSize={'25px'} fontWeight={'bold'}>Time</Text>
            </Box>

            <Heading w={'fit-content'} m={'auto'} display={'flex'} gap={'10px'}>
                <Text color={'grey'}>{language}</Text>
                <Text color={'orange'}>Quiz</Text>
            </Heading>
            <Box w={'50px'} m={'0.5cm auto'} h={'2px'} bg={'orange'} transition={"0.5s"} _hover={{ w: '70px' }}></Box>

            <Box w={'80%'} display={'flex'} gap={'10px'} m={['0.5cm auto', '1.5cm auto', '1.5cm auto']} flexWrap={'wrap'}>
                {QuizSelector_data[language] && QuizSelector_data[language].length !== 0 && QuizSelector_data[language]?.map((ele, index) => (
                    <Box onClick={() => navigate(ele.url)} key={index} m={['auto', '0', '0']} borderRadius={'4px'} w={'70px'} h={'70px'} border={`2.5px solid #3232324c`} color={'#32323235'} display={'flex'} justifyContent={'center'} alignItems={'center'} fontWeight={'bold'} fontSize={'25px'} cursor={'pointer'} _hover={{ color: 'orange' }} transition={'0.3s ease'}>
                        {ele.problem}
                    </Box>
                ))}
                <Box w={'100px'} h={'70px'} border={`2.5px solid #3232324c`} color={'#32323235'} borderRadius={'4px'} display={'flex'} justifyContent={'center'} alignItems={'center'} fontWeight={'medium'} fontSize={'20px'} cursor={'pointer'} _hover={{ color: 'orange' }} transition={'0.3s ease'}>Soon...</Box>
            </Box>

            <Box w={'100%'} bg={'linear-gradient(30deg, rgb(0, 46, 107), rgb(0, 65, 131), rgb(0, 84, 156), rgb(0, 103, 180), rgb(0, 122, 204), rgb(0, 122, 204), rgb(0, 103, 176), rgb(0, 85, 148), rgb(0, 66, 120), rgb(0, 47, 92))'}>
                <Box w={'100%'} textAlign={'center'} fontWeight={'medium'} fontSize={'20px'} p={'10px 0'} bg={'white'} color={'#FF6D00'}>Try Our {language} RoadMap</Box>
                <RoadMap map={language} />
            </Box>
        </Box>
    )
}