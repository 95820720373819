import { Box, Grid, Text } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { contect_Data } from '../Quiz_data/Quiz_data.js';

export const QuizHome = () => {
    const navigate = useNavigate()

    return (
        <Box>
            <Box w={'100%'}>
                {/* navbar */}
                <Box w={['95%']} m={'auto'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'10px'}>
                    <Box display={'flex'} gap={'7px'}>
                        <Text color={'orange'} fontSize={'25px'} fontWeight={'bold'}>|| Quiz</Text>
                        <Text color={'grey'} fontSize={'25px'} fontWeight={'bold'}>Time</Text>
                    </Box>
                </Box>

                {/* banner */}
                <Box textAlign={'center'} mt={['1.5cm', '2cm', '2cm']} mb={'0.5cm'}>
                    <Box color={'#424242'} fontSize={['30px', '40px', '50px']} fontWeight={'medium'} display={'flex'} justifyContent={'center'}>
                        <Text>Quiz</Text>
                        <Text ml={'10px'} color={'orange'}>Time</Text>
                        <Text>Tools</Text>
                    </Box>
                    <Box cursor={'pointer'} w={'50px'} m={'0 auto 0.5cm auto'} h={'2px'} bg={'orange'} transition={"0.5s"} _hover={{ w: '70px' }}></Box>
                    <Text color={'#616161c8'} fontSize={['14px']}>Quiz Time are interactive platform where students participate <br /> to gain knowledge and excel beyond academics.</Text>
                </Box>

            </Box>

            <Box border={'1px solid transparent'} borderRadius={'20px 20px 0 0'}>

                <Grid w={'90%'} m={'1cm auto'} templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={['30px 20px']}>
                    {contect_Data && contect_Data?.map((ele, index) => (
                        <Box key={index} onClick={() => navigate(ele.url)} display={'flex'} flexDirection={'column'} textAlign={'center'} w={'100%'} minH={'300px'} p={'20px'} cursor={'pointer'} boxShadow={index % 2 === 1 && 'rgba(145, 158, 171, 0.16) -40px 40px 80px'}>
                            <Box h={'100px'}>
                                {/* blanck content will added soon */}
                            </Box>
                            <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
                                <Box fontSize={'25px'} color={'#424242'} fontWeight={'bold'}>{ele.title}</Box>
                                <Box w={'50px'} m={'auto'} h={'2px'} bg={ele.textcolor} transition={"0.5s"} _hover={{ w: '70px' }}></Box>
                                {/* <Box fontSize={'14px'} color={'grey'}>{ele.name}</Box> */}
                            </Box>
                        </Box>
                    ))}
                </Grid>

            </Box>
        </Box>
    )
}




// <Box w={['100%', '80%']} display={'flex'} flexDirection={['column', 'column', 'row', 'row']} justifyContent={'space-between'} alignItems={'center'} m={['1cm auto', '1cm auto', '1.5cm auto']}>

// <Box w={['95%', '80%', '50%', '50%']} p={'15px 10px'} borderRadius={'7px'} boxShadow={'rgba(0, 0, 0, 0.24) 0px 4px 10px'}>
//     <Box display={'flex'} gap={'10px'} w={'90%'} m={'10px auto'} fontWeight={'bold'}>
//         <Text fontSize={['14px', '16px', '18px']} color={'#616161'}>1.</Text>
//         <Text fontSize={['14px', '16px', '18px']} color={'orange'} fontWeight={'bold'}>Will You try Quiz Time?</Text>
//     </Box>

//     <Box w={'90%'} m={'10px auto'} fontWeight={'medium'} color={'grey'} border={'1px solid grey'} p={'10px'}>Maybe</Box>
//     <Box w={'90%'} m={'10px auto'} fontWeight={'medium'} border={'1px solid #69F0AE'} p={'10px'} color={'whiteAlpha.900'} bg={'rgb(91, 229, 64)'}>Yes</Box>
//     <Box w={'90%'} m={'10px auto'} fontWeight={'medium'} color={'grey'} border={'1px solid grey'} p={'10px'}>No</Box>
//     <Box w={'90%'} m={'10px auto'} fontWeight={'medium'} color={'grey'} border={'1px solid grey'} p={'10px'}>Not Now</Box>
// </Box>

// <Box w={['90%', '80%', '40%', '45%']} fontSize={['18px']} fontWeight={'medium'} color={'grey'} p={'10px'} m={'10px'}>
//     <Text>
//         Quiz Time are interactive platform where students participate to gain knowledge and excel beyond academics.
//     </Text>
//     {personal['token'] ?
//         <Button w={'200px'} bg={'orange'} color={'whiteAlpha.900'} mt={'15px'} onClick={() => navigate('Dashboard')}>Dashboard</Button>
//         :
//         <Login />
//     }
// </Box>


// <Box w={'300px'} h={'300px'} color={'grey'} fontWeight={'bold'} fontSize={'14px'} m={'10px'} display={'flex'} justifyContent={'center'} alignItems={'center'} border={'1px solid orange'} _hover={{ boxShadow: 'red 0px 0px 30px', border: '1px solid red' }} transition={'0.5s ease'} borderRadius={'50%'} boxShadow={'orange 0px 0px 30px'}>
//     <Text >
//         Quiz Time are interactive platform where students participate to gain knowledge and excel beyond academics.
//     </Text>
//     <Button w={'200px'} bg={'orange'} color={'whiteAlpha.900'} mt={'15px'}>START</Button>
// </Box>

// </Box>