import { Box, Text, Grid, useToast, Input, Heading, Button, Image } from '@chakra-ui/react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


function extractSubstringFromURL(url) {
    const match = url.match(/\/d\/(.*?)\/view/);

    if (match && match[1]) {
        return match[1];
    } else {
        return null;
    }
}


export const GDriveURLConverter = ({ setSide, side }) => {
    const [show, setShow] = useState(false);
    const [type, setType] = useState('Image')
    const navigate = useNavigate()
    const toast = useToast();

    const [inputUrl, setInputUrl] = useState('');
    const [extractedString, setExtractedString] = useState(null);

    const handleInputChange = (e) => {
        setInputUrl(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const extracted = extractSubstringFromURL(inputUrl);
        setExtractedString(extracted);
    };

    const handleOutput = () => {
        if (extractedString) {
            setShow(!show)
        } else {
            toast({ title: 'Note', description: 'Extract the Link To see the output', status: 'success', duration: 4000, isClosable: true, position: 'top' })
        }
    }
    const copyToClipboard = () => {

        navigator.clipboard.writeText(`https://drive.google.com/uc?id=${extractedString}`);
        toast({ title: 'Color copied', description: `https://drive.google.com/uc?id=${extractedString}`, status: 'success', duration: 1500, isClosable: true, position: 'top' })
    }

    return (
        <Box w={'100%'} m={side ? ['2.3cm auto 1cm auto', '1.5cm auto 1cm auto', '1.5cm auto 1cm auto'] : '0.5cm auto'}>
            {!side && <Box display={'flex'} alignItems={'center'} fontSize={'25px'} fontWeight={'bold'} m={'0cm auto 1cm auto'}>
                <Text ml={'10px'} cursor={'pointer'} onClick={() => setSide(!side)}>|||</Text>
                <Text ml={'15px'} cursor={'pointer'} onClick={() => navigate('/')}>ArtKorner</Text>
            </Box>}

            <Heading textAlign={'center'}>G-Drive URL Converter</Heading>

            <form onSubmit={handleSubmit}>
                <Box w={['93%', '65%', '52%']} m={'0.5cm auto'}>
                    <Box w={'100%'} display={'flex'} alignItems={'center'} m={'auto'}>
                        <Input onChange={handleInputChange} value={inputUrl} required textAlign={'center'} w={'100%'} h={'55px'} type="text" display={'block'} m={'10px auto'} border={'1px solid grey'} id="colorValue" placeholder="Google Drive File Link" />
                        <select onChange={(e) => setType(e.target.value)} style={{ border: '1px solid grey', height: '55px', margin: '0 5px', padding: '3px 7px', borderRadius: '7px', fontSize: '14px' }}>
                            <option value="Image">Image</option>
                            <option value="Video">Video</option>
                        </select>
                    </Box>
                    <Box w={'100%'} display={'flex'} justifyContent={'space-between'}>
                        <Button type={'submit'} w={'45%'} display={'block'} m={'10px auto'} fontWeight={'medium'} border={'1.1px solid grey'}>Extract</Button>
                        <Button type={'submit'} w={'45%'} display={'block'} m={'10px auto'} fontWeight={'medium'} border={'1.1px solid grey'} onClick={handleOutput}>Output</Button>
                    </Box>
                    {extractedString && <Button onClick={() => copyToClipboard()} w={'95%'} display={'block'} m={'10px auto'} fontWeight={'medium'} border={'1.1px solid grey'}>Copy</Button>}
                </Box>
            </form>

            {show ? <Box onClick={() => copyToClipboard()} w={['80%', '65%', '50%']} m={'auto'} border={'1px solid grey'} borderRadius={'7px'} minH={'200px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                {type == 'Image' ?
                    <Image cursor={'pointer'} w={'40%'} src={`http://drive.google.com/uc?export=view&id=${extractedString}`} alt='Try Video Type' />
                    :
                    <video width="640" height="360" controls>
                        <source src={`http://drive.google.com/uc?export=view&id=${extractedString}`} type="video/mp4" />
                    </video>
                }
            </Box> :
                <Box textAlign={'center'}>
                    {extractedString !== null ? (
                        <p style={{ width: '80%', margin: '1cm auto', color: '#757575', fontSize: '15px' }}>http://drive.google.com/uc?export=view&id={extractedString}</p>
                    ) : (
                        <div style={{ width: '80%', margin: 'auto', color: '#757575', fontSize: '15px' }}>
                            <p>Enter the Google Drive File Link and get modify link which you can directly use in your Website</p>
                            <b style={{ color: '#EF5350' }}>( Use Valid Link Only )</b>
                        </div>
                    )}
                </Box>
            }
        </Box>
    )
}