import { Box, Heading, Text } from "@chakra-ui/react"

const Road = ({ content, question }) => {

    return (
        <Box fontFamily={'sans-serif'} m={'auto'} w={'fit-content'} p={'10px'}>
            {question && <Text textAlign={'center'} fontWeight={'bold'} fontSize={'20px'}>{question}</Text>}
            <Box display={'flex'} flexWrap={'wrap'} m={'20px auto'} gap={['10px', '15px']}>
                {content && content.length != 0 && content?.map((ele, index) => (
                    <Text key={index} bg={'white'} color={'#272727ac'} fontSize={['14px', '14px', '15px']} fontWeight={'medium'} borderRadius={'7px'} p={'20px'} m={'auto'} boxShadow={'#ededed 0, 0, 0.16) 0px 1px 4px'}>{ele}</Text>
                ))}
            </Box>
        </Box>
    )
}

const Breaker = () => {
    return (<Box w={'1px'} h={'50px'} border={'1px solid white'} m={'7px auto'}></Box>);
}

export const RoadMap = ({ map }) => {

    switch (map) {
        case "HTML":
            return <Box mt={'1cm'}>
                <Road question={'1. Introduction to HTML'} content={['What is HTML ?', 'History of  HTML']} />
                <Breaker />
                <Road question={'2. HTML Basic'} content={['HTML Syntax', '<!DOCTYPE>', 'Elements, tags and Attributes']} />
                <Breaker />
                <Road question={'3. Elements'} content={['Opening Tags', 'Content', 'Closing Tags', 'Nested Elements', 'Block and Inline Elements']} />
                <Breaker />
                <Road question={'4. Tags'} content={['<html>', '<head>', '<body>', '<h1> to <h6>', '<p>', '<a>']} />
                <Road question={''} content={['<ul>', '<ol>', '<img>', '<div>', '<span>', '<br> and <hr>', '<iframe>', 'Comment Tags']} />
                <Breaker />
                <Road question={'5. Attributes'} content={['class', 'id', 'style', 'src', 'href', 'alt']} />
                <Road question={''} content={['width and height', 'target', 'disable', 'required', 'name', 'title']} />
                <Breaker />

                <Box w={'98%'} m={'auto'} border={['1px solid white', '1px solid white', '0']}>
                    <Heading w={'80%'} p={'15px'} m={'10px auto'} borderBottom={'1px solid white'} fontSize={'25px'} textAlign={'center'}>5. Structural Elements</Heading>

                    <Box display={'flex'} flexWrap={['wrap', 'wrap', 'nowrap']}>
                        <Box w={'100%'}>
                            <Breaker />
                            <Road question={'List'} content={['<ol>', '<ul>', '<dl>', '<li>']} />
                        </Box>
                        <Box w={'100%'}>
                            <Breaker />
                            <Road question={'Forms'} content={['<form>', '<input>', '<textarea>', '<button>', 'Understaing of all input types']} />
                        </Box>
                        <Box w={'100%'}>
                            <Breaker />
                            <Road question={'Tables'} content={['<table>', '<thread>', '<tbody>', '<tr>', '<th>', '<td>']} />
                        </Box>
                    </Box>
                </Box>

                <Breaker />
                <Road question={'8. Semantic Tags'} content={['<header>', '<nav>', '<main>', '<section>', '<article>', '<footer>']} />
                <Breaker />
                <Road question={'9. HTML5 Elements'} content={['<video>', '<audio>', '<canvas>', '<svg>']} />
                <Breaker />
                <Road question={'10. Meta-Information'} content={['<title>', '<meta>', '<link>', '<style>', '<script>', '<base>']} />

                {/* <Road question={''} content={[]} />
            <Breaker /> */}
            </Box>
        case "JavaScript":
            return <Box mt={'1cm'}>
                <Road question={'1. Introduction to JavaScript'} content={['What is JavaScript ?', 'History of  JavaScript']} />
                <Breaker />
                <Road question={'2. Variables'} content={['Var, Let and Const', 'Hoisting', 'Variables Scopes']} />
                <Breaker />

                <Box w={'98%'} m={'auto'} border={['1px solid white', '1px solid white', '0']}>
                    <Heading w={'80%'} p={'15px'} m={'10px auto'} borderBottom={'1px solid white'} fontSize={'25px'} textAlign={'center'}>5. Data Types</Heading>

                    <Box display={'flex'} flexWrap={['wrap', 'wrap', 'nowrap']}>
                        <Box w={'100%'}>
                            <Breaker />
                            <Road question={'Primitive Data Types'} content={['Number', 'String', 'Boolean', 'Undefined', 'Null', 'Symbol', 'BigInt']} />
                        </Box>
                        <Box w={'100%'}>
                            <Breaker />
                            <Road question={'Reference Data Types'} content={['Object', 'Array', 'Function']} />
                        </Box>
                    </Box>
                </Box>

                <Breaker />
                <Road question={'4. Objects'} content={['Prototypal inheritance', 'Object Prototype', 'Build-in Objects']} />
                <Breaker />
                <Road question={'5. Type Casting'} content={['Implicit Type Casting', 'Explicit Type Casting', 'Type Conversion VS coersion']} />
                <Breaker />
                <Road question={'6. Loops'} content={['For Loop', 'While Loops', 'For-in', 'For-of', 'Do-While', 'Break,Continue']} />
                <Breaker />
                <Road question={'7. Conditional Statements'} content={['if, else', 'Switch']} />
                <Breaker />
                <Road question={'8. Expression and Operatiors'} content={['Assignment Operators', 'Comparison Operators', 'Arthematic Operators', 'Bitwise Operators']} />
                <Road question={''} content={['Logical Operators', 'Conditional Operator', 'Comma Operators', 'Unary Operators', 'Relational Operators']} />
                <Breaker />
                <Road question={'9. Function'} content={['Arrow Function', 'Funtion Parameters', 'IIFE', 'Built-in Function']} />
                <Breaker />
                <Road question={'10. Scope and Function Stack'} content={['Recursion', 'Closures', 'Lexical Scoping']} />
                <Breaker />
                <Road question={'11. "this" Key word'} content={['in event handlers', 'in a method', 'in a function']} />
                <Breaker />
                <Road question={'12. Explicit binding'} content={['call', 'apply', 'Bind']} />
                <Breaker />
                <Road question={'13. Asynchronous JavaScript'} content={['setInterval', 'setTimeout', 'callbacks', 'Event loop', 'promises', 'async/await']} />
                <Breaker />
                <Road question={'14. Working with APIs'} content={['Fetch', 'XMLHTTP Request']} />
                <Breaker />
                <Road question={'15. Classes, Iterators and Generators'} content={['Classes', 'Iterators', 'Generators']} />
                <Breaker />
                <Road question={'16. Modules in JavaScript'} content={['CommonJS', 'ECMA Script Modules']} />
                <Breaker />
                <Road question={'17. DOM'} content={['DOM Manipulation', 'Event Handling', 'CSS Manipulation', 'DOM Events']} />
                <Breaker />
                <Road question={'18. Testing'} content={['Jest', 'Mocha']} />
            </Box>
        case "React":
            return <Box mt={'1cm'}>
                <Road question={'1. React'} content={['What is React ?', 'History of React']} />
                <Breaker />
                <Road question={'2. React Basic'} content={['JSX', 'Props vs State', 'Composition']} />
                <Breaker />
                <Road question={'3. Components'} content={['Function Component', 'Class Component']} />
                <Breaker />
                <Road question={'4. Rendering'} content={['Component Life Cycle', 'Refs', 'Lists and Keys', 'Events', 'Render Props']} />
                <Breaker />
                <Road question={'5. Hooks'} content={['useReducer', 'useCallback', 'useState', 'useEffect', 'useContext', 'useRef', 'useMemo']} />
                <Breaker />
                <Road question={'6. Router'} content={['Fundamentals of React Routing', 'Advanced React Router Concepts', 'Optimizations and Additional Features']} />
                <Breaker />
                <Road question={'7. State Management'} content={['Context', 'Redux', 'State Manegment Basic']} />
                <Breaker />
                <Road question={'8. React Styling'} content={['Modules CSS', 'Styled Components', 'Tailwind CSS', 'Material UI', 'Chakra UI']} />
                <Breaker />
                <Road question={'9. Forms'} content={['Forms Basic', 'React Hooks Form', 'Formik']} />
                <Breaker />
                <Road question={'10. API Calls'} content={['fetch API', 'axios', 'react-query', 'rtk-query']} />
                <Breaker />
                <Road question={'11. Server Side Rendering'} content={['Gastby', 'Next.js']} />
                <Breaker />
                <Road question={'12. Testing'} content={['Cypress', 'Testing Basics', 'React Testing Library', 'Snapshot Testing']} />


                {/* <Road question={''} content={[]} />
    <Breaker /> */}

            </Box>
        case "NodeJS":
            return <Box mt={'1cm'}>
                <Road question={'1. Node JS'} content={['What is Node.js ?', 'History of Node.js', 'Why use Node.js']} />
                <Breaker />
                <Road question={'2. Node Module'} content={['Common JS', 'Custom Module', 'ESM']} />
                <Breaker />
                <Road question={'3. Package Manager'} content={['npm', 'npx', 'pnpm', 'yarn']} />
                <Breaker />
                <Road question={'4. NodeJS Web Development'} content={['Routing and middleware in Express.js', 'HTTP requests and responses', 'RESTful APIs with Express.js', 'Express.js framework']} />
                <Breaker />


                <Box w={'98%'} m={'auto'} border={['1px solid white', '1px solid white', '0']}>
                    <Heading w={'80%'} p={'15px'} m={'10px auto'} borderBottom={'1px solid white'} fontSize={'25px'} textAlign={'center'}>5. Database</Heading>

                    <Box display={'flex'} flexWrap={['wrap', 'wrap', 'nowrap']}>
                        <Box w={'100%'}>
                            <Breaker />
                            <Road question={'Relational Data base'} content={['Oracle', 'SQL Server', 'MySQL', 'PostgreSQL']} />
                        </Box>
                        <Box w={'100%'}>
                            <Breaker />
                            <Road question={'NoSQL'} content={['MongoDB', 'Apache Cassandra', 'Redis', 'Dynamo DB', 'Cosmos DB']} />
                        </Box>
                    </Box>
                </Box>
                <Breaker />


                <Road question={'6. Security Libraries'} content={['cros', 'bcrypt', 'jwt', 'Passport JS', 'Auth0', 'jsonwebtoken', 'nodemailer']} />
                <Breaker />
                <Road question={'7. Utility Libraries'} content={['JSHint', 'Async.js', 'Lodash', 'Moment', 'classnames', 'Numeral', 'ImmutableJS', 'Ramda']} />
                <Breaker />
                <Road question={'8. Real-Time Communication'} content={['Web sockets', 'Socket.IO']} />
                <Breaker />
                <Road question={'9. Testing'} content={['Cypress', 'Jest', 'Mocha', 'Pupperteer']} />


                {/* <Road question={''} content={[]} />
        <Breaker /> */}

            </Box>
        case "JAVA":
            return <Box mt={'1cm'}>
                <Road question={'1. Java Fundamentals'} content={['Basic Syntax', 'Conditionals, Functions','DataTypes, Variables', 'DataStructures', 'Packages']} />
                <Road content={['OOR Interfaces, Classes', 'Workin with Files and APIs', 'Working with Date / Time', 'Exception Handling']} />
                <Breaker />
                <Road ques content={[]} />
            </Box>
        default:
            return null;
    }

}