import { Box, Grid, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clipPathArray } from '../assets/clipPath.js'


export const ClipPath = ({ side, setSide }) => {
    const toast = useToast();
    const navigate = useNavigate()
    const [category, setCategory] = useState('base')

    const copyToClipboard = (ele) => {
        let total = `<div style={{ width: '120px', height: '120px', clipPath: ${ele}, background: 'linear-gradient(30deg, rgb(255, 0, 0), rgb(81, 63, 302))' }}></div>`

        if (category === 'base') {
            navigator.clipboard.writeText(ele);
            toast({ title: 'Clip-Path Copied', description: ele, status: 'success', duration: 1000, isClosable: true, position: 'top' })
        } else {
            navigator.clipboard.writeText(total);
            toast({ title: 'Clip-Path Copied',description:total, status: 'success', duration: 1000, isClosable: true, position: 'top' })
        }
    }


    return (
        <Box w={['95%','90%','80%']} m={side ? '1cm auto 1cm auto' : '0.5cm auto'}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} m={'0.5cm auto'}>
                <Box>{!side && <Box display={'flex'} alignItems={'center'} fontSize={['20px', '25px']} fontWeight={'bold'} m={'0.5cm auto 0.5cm auto'}>
                    <Text ml={'10px'} cursor={'pointer'} onClick={() => setSide(!side)}>|||</Text>
                    <Text ml={'15px'} cursor={'pointer'} onClick={() => navigate('/')}>ArtKorner</Text>
                </Box>}
                </Box>
                <Box display={'flex'} gap={'10px'} fontSize={'15px'}>
                    <Box onClick={() => setCategory('base')} cursor={'pointer'} borderBottom={category === 'base' ? '1.2px solid grey' : '1.2px solid white'}>BASE</Box>
                    <Box onClick={() => setCategory('full')} cursor={'pointer'} borderBottom={category !== 'base' ? '1.2px solid grey' : '1.2px solid white'}>FULL CODE</Box>
                </Box>
            </Box>

            <Grid templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(3, 1fr)']} gap={['30px 20px']}>
                {clipPathArray && clipPathArray?.map((ele, index) => (
                    <Box onClick={() => copyToClipboard(ele)} key={index} w={'100%'} cursor={'pointer'} display={'flex'} justifyContent={'center'} alignItems={'center'} p={'10px'} borderRadius={'10px'} boxShadow={index % 2 === 1 && 'rgba(145, 158, 171, 0.16) -40px 40px 80px'}>
                        <Box clipPath={ele} w={'120px'} h={'120px'} bg={'linear-gradient(30deg, rgb(102, 187, 106), rgb(108, 194, 112), rgb(114, 201, 118), rgb(120, 207, 124), rgb(126, 214, 130), rgb(131, 221, 137), rgb(137, 228, 143), rgb(143, 234, 149), rgb(149, 241, 155), rgb(155, 248, 161), rgb(155, 248, 161), rgb(149, 241, 154), rgb(143, 234, 147), rgb(137, 228, 140), rgb(131, 221, 133), rgb(126, 214, 125), rgb(120, 207, 118), rgb(114, 201, 111), rgb(108, 194, 104), rgb(102, 187, 97))'}></Box>
                    </Box>
                ))}
            </Grid>
        </Box>
    );
};

