export const red = [
    { hex: '#FFEBEE', rgb: 'rgb(255, 235, 238)', hsl: 'hsl(6, 100%, 92%)' },
    { hex: '#FFCDD2', rgb: 'rgb(255, 205, 210)', hsl: 'hsl(7, 100%, 88%)' },
    { hex: '#EF9A9A', rgb: 'rgb(239, 154, 154)', hsl: 'hsl(0, 77%, 73%)' },
    { hex: '#E57373', rgb: 'rgb(229, 115, 115)', hsl: 'hsl(0, 80%, 67%)' },
    { hex: '#EF5350', rgb: 'rgb(239, 83, 80)', hsl: 'hsl(1, 83%, 61%)' },
    { hex: '#F44336', rgb: 'rgb(244, 67, 54)', hsl: 'hsl(1, 89%, 58%)' },
    { hex: '#E53935', rgb: 'rgb(229, 57, 53)', hsl: 'hsl(1, 79%, 56%)' },
    { hex: '#D32F2F', rgb: 'rgb(211, 47, 47)', hsl: 'hsl(0, 65%, 52%)' },
    { hex: '#C62828', rgb: 'rgb(198, 40, 40)', hsl: 'hsl(0, 66%, 48%)' },
    { hex: '#B71C1C', rgb: 'rgb(183, 28, 28)', hsl: 'hsl(0, 75%, 42%)' },
    { hex: '#FF8A80', rgb: 'rgb(255, 138, 128)', hsl: 'hsl(7, 100%, 84%)' },
    { hex: '#FF5252', rgb: 'rgb(255, 82, 82)', hsl: 'hsl(0, 100%, 67%)' },
    { hex: '#FF1744', rgb: 'rgb(255, 23, 68)', hsl: 'hsl(0, 100%, 54%)' },
    { hex: '#D50000', rgb: 'rgb(213, 0, 0)', hsl: 'hsl(0, 100%, 42%)' }
];

export const pink = [
    { hex: '#FCE4EC', rgb: 'rgb(252, 228, 236)', hsl: 'hsl(340, 85%, 95%)' },
    { hex: '#F8BBD0', rgb: 'rgb(248, 187, 208)', hsl: 'hsl(330, 83%, 86%)' },
    { hex: '#F48FB1', rgb: 'rgb(244, 143, 177)', hsl: 'hsl(330, 83%, 72%)' },
    { hex: '#F06292', rgb: 'rgb(240, 98, 146)', hsl: 'hsl(330, 76%, 63%)' },
    { hex: '#EC407A', rgb: 'rgb(236, 64, 122)', hsl: 'hsl(330, 73%, 60%)' },
    { hex: '#E91E63', rgb: 'rgb(233, 30, 99)', hsl: 'hsl(337, 81%, 54%)' },
    { hex: '#D81B60', rgb: 'rgb(216, 27, 96)', hsl: 'hsl(334, 79%, 52%)' },
    { hex: '#C2185B', rgb: 'rgb(194, 24, 91)', hsl: 'hsl(327, 79%, 48%)' },
    { hex: '#AD1457', rgb: 'rgb(173, 20, 87)', hsl: 'hsl(320, 80%, 43%)' },
    { hex: '#880E4F', rgb: 'rgb(136, 14, 79)', hsl: 'hsl(307, 81%, 34%)' },
    { hex: '#FF80AB', rgb: 'rgb(255, 128, 171)', hsl: 'hsl(330, 100%, 75%)' },
    { hex: '#FF4081', rgb: 'rgb(255, 64, 129)', hsl: 'hsl(330, 100%, 64%)' },
    { hex: '#F50057', rgb: 'rgb(245, 0, 87)', hsl: 'hsl(337, 100%, 48%)' },
    { hex: '#C51162', rgb: 'rgb(197, 17, 98)', hsl: 'hsl(330, 84%, 43%)' }
];

export const purple = [
    { hex: '#F3E5F5', rgb: 'rgb(243, 229, 245)', hsl: 'hsl(275, 47%, 92%)' },
    { hex: '#E1BEE7', rgb: 'rgb(225, 190, 231)', hsl: 'hsl(275, 47%, 80%)' },
    { hex: '#CE93D8', rgb: 'rgb(206, 147, 216)', hsl: 'hsl(275, 49%, 63%)' },
    { hex: '#BA68C8', rgb: 'rgb(186, 104, 200)', hsl: 'hsl(275, 46%, 57%)' },
    { hex: '#AB47BC', rgb: 'rgb(171, 71, 188)', hsl: 'hsl(282, 45%, 53%)' },
    { hex: '#9C27B0', rgb: 'rgb(156, 39, 176)', hsl: 'hsl(288, 60%, 44%)' },
    { hex: '#8E24AA', rgb: 'rgb(142, 36, 170)', hsl: 'hsl(288, 63%, 41%)' },
    { hex: '#7B1FA2', rgb: 'rgb(123, 31, 162)', hsl: 'hsl(288, 68%, 37%)' },
    { hex: '#6A1B9A', rgb: 'rgb(106, 27, 154)', hsl: 'hsl(288, 68%, 33%)' },
    { hex: '#4A148C', rgb: 'rgb(74, 20, 140)', hsl: 'hsl(271, 75%, 29%)' },
    { hex: '#EA80FC', rgb: 'rgb(234, 128, 252)', hsl: 'hsl(282, 100%, 75%)' },
    { hex: '#E040FB', rgb: 'rgb(224, 64, 251)', hsl: 'hsl(282, 85%, 64%)' },
    { hex: '#D500F9', rgb: 'rgb(213, 0, 249)', hsl: 'hsl(282, 100%, 49%)' },
    { hex: '#AA00FF', rgb: 'rgb(170, 0, 255)', hsl: 'hsl(280, 100%, 50%)' }
];

export const deepPurple = [
    { hex: '#F3E5F5', rgb: 'rgb(243, 229, 245)', hsl: 'hsl(270, 47%, 92%)' },
    { hex: '#D1C4E9', rgb: 'rgb(209, 196, 233)', hsl: 'hsl(270, 46%, 79%)' },
    { hex: '#B39DDB', rgb: 'rgb(179, 157, 219)', hsl: 'hsl(270, 49%, 66%)' },
    { hex: '#9575CD', rgb: 'rgb(149, 117, 205)', hsl: 'hsl(262, 45%, 60%)' },
    { hex: '#7E57C2', rgb: 'rgb(126, 87, 194)', hsl: 'hsl(262, 50%, 53%)' },
    { hex: '#673AB7', rgb: 'rgb(103, 58, 183)', hsl: 'hsl(262, 53%, 47%)' },
    { hex: '#5E35B1', rgb: 'rgb(94, 53, 177)', hsl: 'hsl(260, 54%, 45%)' },
    { hex: '#512DA8', rgb: 'rgb(81, 45, 168)', hsl: 'hsl(258, 57%, 41%)' },
    { hex: '#4527A0', rgb: 'rgb(69, 39, 160)', hsl: 'hsl(256, 61%, 37%)' },
    { hex: '#311B92', rgb: 'rgb(49, 27, 146)', hsl: 'hsl(250, 63%, 31%)' },
    { hex: '#B388FF', rgb: 'rgb(179, 136, 255)', hsl: 'hsl(262, 100%, 74%)' },
    { hex: '#7C4DFF', rgb: 'rgb(124, 77, 255)', hsl: 'hsl(259, 100%, 66%)' },
    { hex: '#651FFF', rgb: 'rgb(101, 31, 255)', hsl: 'hsl(251, 100%, 57%)' },
    { hex: '#6200EA', rgb: 'rgb(98, 0, 234)', hsl: 'hsl(249, 100%, 46%)' }
];


export const indigo = [
    { hex: '#E8EAF6', rgb: 'rgb(232, 234, 246)', hsl: 'hsl(234, 45%, 91%)' },
    { hex: '#C5CAE9', rgb: 'rgb(197, 202, 233)', hsl: 'hsl(233, 40%, 80%)' },
    { hex: '#9FA8DA', rgb: 'rgb(159, 168, 218)', hsl: 'hsl(233, 46%, 68%)' },
    { hex: '#7986CB', rgb: 'rgb(121, 134, 203)', hsl: 'hsl(234, 45%, 58%)' },
    { hex: '#5C6BC0', rgb: 'rgb(92, 107, 192)', hsl: 'hsl(233, 44%, 50%)' },
    { hex: '#3F51B5', rgb: 'rgb(63, 81, 181)', hsl: 'hsl(233, 56%, 48%)' },
    { hex: '#3F51B5', rgb: 'rgb(63, 81, 181)', hsl: 'hsl(233, 56%, 48%)' },
    { hex: '#303F9F', rgb: 'rgb(48, 63, 159)', hsl: 'hsl(240, 54%, 45%)' },
    { hex: '#283593', rgb: 'rgb(40, 53, 147)', hsl: 'hsl(237, 58%, 41%)' },
    { hex: '#1A237E', rgb: 'rgb(26, 35, 126)', hsl: 'hsl(235, 64%, 29%)' },
    { hex: '#8C9EFF', rgb: 'rgb(140, 158, 255)', hsl: 'hsl(234, 100%, 77%)' },
    { hex: '#536DFE', rgb: 'rgb(83, 109, 254)', hsl: 'hsl(235, 100%, 63%)' },
    { hex: '#3D5AFE', rgb: 'rgb(61, 90, 254)', hsl: 'hsl(235, 100%, 60%)' },
    { hex: '#304FFE', rgb: 'rgb(48, 79, 254)', hsl: 'hsl(236, 100%, 58%)' }
];

export const blue = [
    { hex: '#E3F2FD', rgb: 'rgb(227, 242, 253)', hsl: 'hsl(207, 92%, 93%)' },
    { hex: '#BBDEFB', rgb: 'rgb(187, 222, 251)', hsl: 'hsl(207, 80%, 79%)' },
    { hex: '#90CAF9', rgb: 'rgb(144, 202, 249)', hsl: 'hsl(207, 73%, 66%)' },
    { hex: '#64B5F6', rgb: 'rgb(100, 181, 246)', hsl: 'hsl(207, 79%, 59%)' },
    { hex: '#42A5F5', rgb: 'rgb(66, 165, 245)', hsl: 'hsl(207, 84%, 56%)' },
    { hex: '#2196F3', rgb: 'rgb(33, 150, 243)', hsl: 'hsl(207, 89%, 49%)' },
    { hex: '#1E88E5', rgb: 'rgb(30, 136, 229)', hsl: 'hsl(207, 80%, 47%)' },
    { hex: '#1976D2', rgb: 'rgb(25, 118, 210)', hsl: 'hsl(207, 79%, 43%)' },
    { hex: '#1565C0', rgb: 'rgb(21, 101, 192)', hsl: 'hsl(207, 80%, 38%)' },
    { hex: '#0D47A1', rgb: 'rgb(13, 71, 161)', hsl: 'hsl(210, 85%, 32%)' },
    { hex: '#82B1FF', rgb: 'rgb(130, 177, 255)', hsl: 'hsl(216, 100%, 77%)' },
    { hex: '#448AFF', rgb: 'rgb(68, 138, 255)', hsl: 'hsl(217, 100%, 59%)' },
    { hex: '#2979FF', rgb: 'rgb(41, 121, 255)', hsl: 'hsl(217, 100%, 56%)' },
    { hex: '#2962FF', rgb: 'rgb(41, 98, 255)', hsl: 'hsl(217, 100%, 53%)' }
];

export const lightBlue = [
    { hex: '#E1F5FE', rgb: 'rgb(225, 245, 254)', hsl: 'hsl(197, 85%, 94%)' },
    { hex: '#B3E5FC', rgb: 'rgb(179, 229, 252)', hsl: 'hsl(197, 77%, 82%)' },
    { hex: '#81D4FA', rgb: 'rgb(129, 212, 250)', hsl: 'hsl(197, 71%, 70%)' },
    { hex: '#4FC3F7', rgb: 'rgb(79, 195, 247)', hsl: 'hsl(197, 85%, 60%)' },
    { hex: '#29B6F6', rgb: 'rgb(41, 182, 246)', hsl: 'hsl(197, 89%, 54%)' },
    { hex: '#03A9F4', rgb: 'rgb(3, 169, 244)', hsl: 'hsl(197, 96%, 48%)' },
    { hex: '#039BE5', rgb: 'rgb(3, 155, 229)', hsl: 'hsl(204, 98%, 45%)' },
    { hex: '#0288D1', rgb: 'rgb(2, 136, 209)', hsl: 'hsl(198, 98%, 41%)' },
    { hex: '#0277BD', rgb: 'rgb(2, 119, 189)', hsl: 'hsl(199, 97%, 37%)' },
    { hex: '#01579B', rgb: 'rgb(1, 87, 155)', hsl: 'hsl(203, 98%, 30%)' },
    { hex: '#80D8FF', rgb: 'rgb(128, 216, 255)', hsl: 'hsl(197, 100%, 75%)' },
    { hex: '#40C4FF', rgb: 'rgb(64, 196, 255)', hsl: 'hsl(197, 100%, 63%)' },
    { hex: '#00B0FF', rgb: 'rgb(0, 176, 255)', hsl: 'hsl(197, 100%, 50%)' },
    { hex: '#0091EA', rgb: 'rgb(0, 145, 234)', hsl: 'hsl(199, 100%, 46%)' }
];


export const cyan = [
    { hex: '#E0F7FA', rgb: 'rgb(224, 247, 250)', hsl: 'hsl(180, 62%, 93%)' },
    { hex: '#B2EBF2', rgb: 'rgb(178, 235, 242)', hsl: 'hsl(181, 67%, 80%)' },
    { hex: '#80DEEA', rgb: 'rgb(128, 222, 234)', hsl: 'hsl(181, 58%, 69%)' },
    { hex: '#4DD0E1', rgb: 'rgb(77, 208, 225)', hsl: 'hsl(182, 66%, 57%)' },
    { hex: '#26C6DA', rgb: 'rgb(38, 198, 218)', hsl: 'hsl(182, 70%, 50%)' },
    { hex: '#00BCD4', rgb: 'rgb(0, 188, 212)', hsl: 'hsl(182, 100%, 42%)' },
    { hex: '#00ACC1', rgb: 'rgb(0, 172, 193)', hsl: 'hsl(182, 100%, 38%)' },
    { hex: '#0097A7', rgb: 'rgb(0, 151, 167)', hsl: 'hsl(182, 100%, 33%)' },
    { hex: '#00838F', rgb: 'rgb(0, 131, 143)', hsl: 'hsl(182, 100%, 29%)' },
    { hex: '#006064', rgb: 'rgb(0, 96, 100)', hsl: 'hsl(182, 100%, 20%)' },
    { hex: '#84FFFF', rgb: 'rgb(132, 255, 255)', hsl: 'hsl(180, 100%, 75%)' },
    { hex: '#18FFFF', rgb: 'rgb(24, 255, 255)', hsl: 'hsl(180, 100%, 54%)' },
    { hex: '#00E5FF', rgb: 'rgb(0, 229, 255)', hsl: 'hsl(186, 100%, 50%)' },
    { hex: '#00B8D4', rgb: 'rgb(0, 184, 212)', hsl: 'hsl(187, 100%, 41%)' }
];

export const teal = [
    { hex: '#E0F2F1', rgb: 'rgb(224, 242, 241)', hsl: 'hsl(174, 32%, 91%)' },
    { hex: '#B2DFDB', rgb: 'rgb(178, 223, 219)', hsl: 'hsl(174, 39%, 77%)' },
    { hex: '#80CBC4', rgb: 'rgb(128, 203, 196)', hsl: 'hsl(174, 34%, 60%)' },
    { hex: '#4DB6AC', rgb: 'rgb(77, 182, 172)', hsl: 'hsl(174, 41%, 48%)' },
    { hex: '#26A69A', rgb: 'rgb(38, 166, 154)', hsl: 'hsl(174, 63%, 40%)' },
    { hex: '#009688', rgb: 'rgb(0, 150, 136)', hsl: 'hsl(174, 100%, 29%)' },
    { hex: '#00897B', rgb: 'rgb(0, 137, 123)', hsl: 'hsl(174, 100%, 27%)' },
    { hex: '#00796B', rgb: 'rgb(0, 121, 107)', hsl: 'hsl(174, 100%, 24%)' },
    { hex: '#00695C', rgb: 'rgb(0, 105, 92)', hsl: 'hsl(174, 100%, 21%)' },
    { hex: '#004D40', rgb: 'rgb(0, 77, 64)', hsl: 'hsl(174, 100%, 16%)' },
    { hex: '#A7FFEB', rgb: 'rgb(167, 255, 235)', hsl: 'hsl(174, 100%, 77%)' },
    { hex: '#64FFDA', rgb: 'rgb(100, 255, 218)', hsl: 'hsl(174, 100%, 64%)' },
    { hex: '#1DE9B6', rgb: 'rgb(29, 233, 182)', hsl: 'hsl(174, 81%, 49%)' },
    { hex: '#00BFA5', rgb: 'rgb(0, 191, 165)', hsl: 'hsl(174, 100%, 37%)' }
];

export const green = [
    { hex: '#E8F5E9', rgb: 'rgb(232, 245, 233)', hsl: 'hsl(120, 40%, 92%)' },
    { hex: '#C8E6C9', rgb: 'rgb(200, 230, 201)', hsl: 'hsl(120, 40%, 78%)' },
    { hex: '#A5D6A7', rgb: 'rgb(165, 214, 167)', hsl: 'hsl(120, 40%, 66%)' },
    { hex: '#81C784', rgb: 'rgb(129, 199, 132)', hsl: 'hsl(120, 39%, 54%)' },
    { hex: '#66BB6A', rgb: 'rgb(102, 187, 106)', hsl: 'hsl(120, 39%, 47%)' },
    { hex: '#4CAF50', rgb: 'rgb(76, 175, 80)', hsl: 'hsl(120, 39%, 42%)' },
    { hex: '#43A047', rgb: 'rgb(67, 160, 71)', hsl: 'hsl(120, 39%, 40%)' },
    { hex: '#43A047', rgb: 'rgb(67, 160, 71)', hsl: 'hsl(120, 39%, 40%)' },
    { hex: '#2E7D32', rgb: 'rgb(46, 125, 50)', hsl: 'hsl(120, 45%, 33%)' },
    { hex: '#1B5E20', rgb: 'rgb(27, 94, 32)', hsl: 'hsl(120, 56%, 23%)' },
    { hex: '#B9F6CA', rgb: 'rgb(185, 246, 202)', hsl: 'hsl(120, 88%, 82%)' },
    { hex: '#69F0AE', rgb: 'rgb(105, 240, 174)', hsl: 'hsl(120, 85%, 68%)' },
    { hex: '#00E676', rgb: 'rgb(0, 230, 118)', hsl: 'hsl(140, 100%, 45%)' },
    { hex: '#00C853', rgb: 'rgb(0, 200, 83)', hsl: 'hsl(140, 100%, 39%)' }
];

export const lightGreen = [
    { hex: '#F1F8E9', rgb: 'rgb(241, 248, 233)', hsl: 'hsl(76, 56%, 92%)' },
    { hex: '#DCEDC8', rgb: 'rgb(220, 237, 200)', hsl: 'hsl(79, 52%, 80%)' },
    { hex: '#C5E1A5', rgb: 'rgb(197, 225, 165)', hsl: 'hsl(79, 49%, 70%)' },
    { hex: '#AED581', rgb: 'rgb(174, 213, 129)', hsl: 'hsl(79, 49%, 60%)' },
    { hex: '#9CCC65', rgb: 'rgb(156, 204, 101)', hsl: 'hsl(79, 50%, 52%)' },
    { hex: '#8BC34A', rgb: 'rgb(139, 195, 74)', hsl: 'hsl(79, 39%, 45%)' },
    { hex: '#7CB342', rgb: 'rgb(124, 179, 66)', hsl: 'hsl(79, 46%, 39%)' },
    { hex: '#689F38', rgb: 'rgb(104, 159, 56)', hsl: 'hsl(79, 47%, 34%)' },
    { hex: '#558B2F', rgb: 'rgb(85, 139, 47)', hsl: 'hsl(79, 49%, 28%)' },
    { hex: '#33691E', rgb: 'rgb(51, 105, 30)', hsl: 'hsl(79, 54%, 20%)' },
    { hex: '#CCFF90', rgb: 'rgb(204, 255, 144)', hsl: 'hsl(79, 100%, 75%)' },
    { hex: '#B2FF59', rgb: 'rgb(178, 255, 89)', hsl: 'hsl(79, 100%, 68%)' },
    { hex: '#76FF03', rgb: 'rgb(118, 255, 3)', hsl: 'hsl(89, 100%, 50%)' },
    { hex: '#64DD17', rgb: 'rgb(100, 221, 23)', hsl: 'hsl(93, 81%, 47%)' }
];


export const lime = [
    { hex: '#F9FBE7', rgb: 'rgb(249, 251, 231)', hsl: 'hsl(65, 85%, 94%)' },
    { hex: '#F0F4C3', rgb: 'rgb(240, 244, 195)', hsl: 'hsl(65, 67%, 80%)' },
    { hex: '#E6EE9C', rgb: 'rgb(230, 238, 156)', hsl: 'hsl(65, 70%, 70%)' },
    { hex: '#DCE775', rgb: 'rgb(220, 231, 117)', hsl: 'hsl(65, 67%, 62%)' },
    { hex: '#D4E157', rgb: 'rgb(212, 225, 87)', hsl: 'hsl(65, 69%, 55%)' },
    { hex: '#CDDC39', rgb: 'rgb(205, 220, 57)', hsl: 'hsl(65, 70%, 49%)' },
    { hex: '#C0CA33', rgb: 'rgb(192, 202, 51)', hsl: 'hsl(65, 59%, 45%)' },
    { hex: '#AFB42B', rgb: 'rgb(175, 180, 43)', hsl: 'hsl(65, 61%, 41%)' },
    { hex: '#9E9D24', rgb: 'rgb(158, 157, 36)', hsl: 'hsl(65, 63%, 36%)' },
    { hex: '#827717', rgb: 'rgb(130, 119, 23)', hsl: 'hsl(54, 69%, 30%)' },
    { hex: '#F4FF81', rgb: 'rgb(244, 255, 129)', hsl: 'hsl(65, 100%, 77%)' },
    { hex: '#EEFF41', rgb: 'rgb(238, 255, 65)', hsl: 'hsl(65, 100%, 63%)' },
    { hex: '#C6FF00', rgb: 'rgb(198, 255, 0)', hsl: 'hsl(75, 100%, 50%)' },
    { hex: '#AEEA00', rgb: 'rgb(174, 234, 0)', hsl: 'hsl(75, 100%, 46%)' }
];

export const yellow = [
    { hex: '#FFFDE7', rgb: 'rgb(255, 253, 231)', hsl: 'hsl(60, 100%, 94%)' },
    { hex: '#FFF9C4', rgb: 'rgb(255, 249, 196)', hsl: 'hsl(60, 100%, 86%)' },
    { hex: '#FFF59D', rgb: 'rgb(255, 245, 157)', hsl: 'hsl(60, 100%, 75%)' },
    { hex: '#FFF176', rgb: 'rgb(255, 241, 118)', hsl: 'hsl(54, 100%, 70%)' },
    { hex: '#FFEE58', rgb: 'rgb(255, 238, 88)', hsl: 'hsl(50, 100%, 65%)' },
    { hex: '#FFEB3B', rgb: 'rgb(255, 235, 59)', hsl: 'hsl(45, 100%, 60%)' },
    { hex: '#FDD835', rgb: 'rgb(253, 216, 53)', hsl: 'hsl(51, 98%, 60%)' },
    { hex: '#FBC02D', rgb: 'rgb(251, 192, 45)', hsl: 'hsl(46, 97%, 57%)' },
    { hex: '#F9A825', rgb: 'rgb(249, 168, 37)', hsl: 'hsl(42, 95%, 53%)' },
    { hex: '#F57F17', rgb: 'rgb(245, 127, 23)', hsl: 'hsl(35, 91%, 50%)' },
    { hex: '#FFFF8D', rgb: 'rgb(255, 255, 141)', hsl: 'hsl(60, 100%, 77%)' },
    { hex: '#FFFF00', rgb: 'rgb(255, 255, 0)', hsl: 'hsl(60, 100%, 50%)' },
    { hex: '#FFEA00', rgb: 'rgb(255, 234, 0)', hsl: 'hsl(59, 100%, 49%)' },
    { hex: '#FFD600', rgb: 'rgb(255, 214, 0)', hsl: 'hsl(55, 100%, 50%)' }
];


export const amber = [
    { hex: '#FFF8E1', rgb: 'rgb(255, 248, 225)', hsl: 'hsl(45, 100%, 92%)' },
    { hex: '#FFECB3', rgb: 'rgb(255, 236, 179)', hsl: 'hsl(45, 100%, 80%)' },
    { hex: '#FFE082', rgb: 'rgb(255, 224, 130)', hsl: 'hsl(45, 100%, 72%)' },
    { hex: '#FFD54F', rgb: 'rgb(255, 213, 79)', hsl: 'hsl(45, 100%, 63%)' },
    { hex: '#FFCA28', rgb: 'rgb(255, 202, 40)', hsl: 'hsl(45, 100%, 55%)' },
    { hex: '#FFC107', rgb: 'rgb(255, 193, 7)', hsl: 'hsl(45, 100%, 50%)' },
    { hex: '#FFB300', rgb: 'rgb(255, 179, 0)', hsl: 'hsl(45, 100%, 47%)' },
    { hex: '#FFA000', rgb: 'rgb(255, 160, 0)', hsl: 'hsl(45, 100%, 44%)' },
    { hex: '#FF8F00', rgb: 'rgb(255, 143, 0)', hsl: 'hsl(36, 100%, 41%)' },
    { hex: '#FF6F00', rgb: 'rgb(255, 111, 0)', hsl: 'hsl(33, 100%, 38%)' },
    { hex: '#FFE57F', rgb: 'rgb(255, 229, 127)', hsl: 'hsl(45, 100%, 75%)' },
    { hex: '#FFD740', rgb: 'rgb(255, 215, 64)', hsl: 'hsl(45, 100%, 64%)' },
    { hex: '#FFC400', rgb: 'rgb(255, 196, 0)', hsl: 'hsl(45, 100%, 50%)' },
    { hex: '#FFAB00', rgb: 'rgb(255, 171, 0)', hsl: 'hsl(45, 100%, 42%)' }
];

export const orange = [
    { hex: '#FFF3E0', rgb: 'rgb(255, 243, 224)', hsl: 'hsl(36, 100%, 92%)' },
    { hex: '#FFE0B2', rgb: 'rgb(255, 224, 178)', hsl: 'hsl(36, 100%, 80%)' },
    { hex: '#FFCC80', rgb: 'rgb(255, 204, 128)', hsl: 'hsl(36, 100%, 70%)' },
    { hex: '#FFB74D', rgb: 'rgb(255, 183, 77)', hsl: 'hsl(36, 100%, 63%)' },
    { hex: '#FFA726', rgb: 'rgb(255, 167, 38)', hsl: 'hsl(36, 100%, 50%)' },
    { hex: '#FF9800', rgb: 'rgb(255, 152, 0)', hsl: 'hsl(36, 100%, 47%)' },
    { hex: '#FB8C00', rgb: 'rgb(251, 140, 0)', hsl: 'hsl(36, 100%, 44%)' },
    { hex: '#F57C00', rgb: 'rgb(245, 124, 0)', hsl: 'hsl(35, 100%, 41%)' },
    { hex: '#EF6C00', rgb: 'rgb(239, 108, 0)', hsl: 'hsl(33, 100%, 38%)' },
    { hex: '#E65100', rgb: 'rgb(230, 81, 0)', hsl: 'hsl(24, 100%, 34%)' },
    { hex: '#FFD180', rgb: 'rgb(255, 209, 128)', hsl: 'hsl(36, 100%, 76%)' },
    { hex: '#FFAB40', rgb: 'rgb(255, 171, 64)', hsl: 'hsl(36, 100%, 64%)' },
    { hex: '#FF9100', rgb: 'rgb(255, 145, 0)', hsl: 'hsl(36, 100%, 50%)' },
    { hex: '#FF6D00', rgb: 'rgb(255, 109, 0)', hsl: 'hsl(29, 100%, 40%)' }
];


export const deepOrange = [
    { hex: '#FBE9E7', rgb: 'rgb(251, 233, 231)', hsl: 'hsl(9, 89%, 94%)' },
    { hex: '#FFCCBC', rgb: 'rgb(255, 204, 188)', hsl: 'hsl(16, 100%, 85%)' },
    { hex: '#FFAB91', rgb: 'rgb(255, 171, 145)', hsl: 'hsl(17, 100%, 75%)' },
    { hex: '#FF8A65', rgb: 'rgb(255, 138, 101)', hsl: 'hsl(17, 100%, 65%)' },
    { hex: '#FF7043', rgb: 'rgb(255, 112, 67)', hsl: 'hsl(17, 100%, 56%)' },
    { hex: '#FF5722', rgb: 'rgb(255, 87, 34)', hsl: 'hsl(17, 100%, 53%)' },
    { hex: '#F4511E', rgb: 'rgb(244, 81, 30)', hsl: 'hsl(17, 89%, 50%)' },
    { hex: '#E64A19', rgb: 'rgb(230, 74, 25)', hsl: 'hsl(13, 81%, 47%)' },
    { hex: '#D84315', rgb: 'rgb(216, 67, 21)', hsl: 'hsl(10, 83%, 43%)' },
    { hex: '#BF360C', rgb: 'rgb(191, 54, 12)', hsl: 'hsl(13, 87%, 39%)' },
    { hex: '#FF9E80', rgb: 'rgb(255, 158, 128)', hsl: 'hsl(18, 100%, 80%)' },
    { hex: '#FF6E40', rgb: 'rgb(255, 110, 64)', hsl: 'hsl(18, 100%, 65%)' },
    { hex: '#FF3D00', rgb: 'rgb(255, 61, 0)', hsl: 'hsl(16, 100%, 50%)' },
    { hex: '#DD2C00', rgb: 'rgb(221, 44, 0)', hsl: 'hsl(11, 100%, 43%)' }
];


export const brown = [
    { hex: '#EFEBE9', rgb: 'rgb(239, 235, 233)', hsl: 'hsl(30, 24%, 91%)' },
    { hex: '#D7CCC8', rgb: 'rgb(215, 204, 200)', hsl: 'hsl(30, 25%, 79%)' },
    { hex: '#BCAAA4', rgb: 'rgb(188, 170, 164)', hsl: 'hsl(30, 25%, 68%)' },
    { hex: '#A1887F', rgb: 'rgb(161, 136, 127)', hsl: 'hsl(30, 21%, 57%)' },
    { hex: '#8D6E63', rgb: 'rgb(141, 110, 99)', hsl: 'hsl(30, 19%, 46%)' },
    { hex: '#795548', rgb: 'rgb(121, 85, 72)', hsl: 'hsl(30, 25%, 39%)' },
    { hex: '#6D4C41', rgb: 'rgb(109, 76, 65)', hsl: 'hsl(30, 25%, 34%)' },
    { hex: '#5D4037', rgb: 'rgb(93, 64, 55)', hsl: 'hsl(30, 25%, 29%)' },
    { hex: '#4E342E', rgb: 'rgb(78, 52, 46)', hsl: 'hsl(12, 25%, 24%)' },
    { hex: '#3E2723', rgb: 'rgb(62, 39, 35)', hsl: 'hsl(12, 30%, 19%)' },

    { hex: '#4d4443', rgb: 'rgb(62, 39, 35)', hsl: 'hsl(12, 30%, 19%)' },
    { hex: '#37221e', rgb: 'rgb(62, 39, 35)', hsl: 'hsl(12, 30%, 19%)' },
    { hex: '#281916', rgb: 'rgb(62, 39, 35)', hsl: 'hsl(12, 30%, 19%)' },
    { hex: '#241512', rgb: 'rgb(62, 39, 35)', hsl: 'hsl(12, 30%, 19%)' },
    
];


export const grey = [
    { hex: '#FAFAFA', rgb: 'rgb(250, 250, 250)', hsl: 'hsl(0, 0%, 98%)' },
    { hex: '#F5F5F5', rgb: 'rgb(245, 245, 245)', hsl: 'hsl(0, 0%, 96%)' },
    { hex: '#EEEEEE', rgb: 'rgb(238, 238, 238)', hsl: 'hsl(0, 0%, 93%)' },
    { hex: '#E0E0E0', rgb: 'rgb(224, 224, 224)', hsl: 'hsl(0, 0%, 88%)' },
    { hex: '#BDBDBD', rgb: 'rgb(189, 189, 189)', hsl: 'hsl(0, 0%, 74%)' },
    { hex: '#9E9E9E', rgb: 'rgb(158, 158, 158)', hsl: 'hsl(0, 0%, 62%)' },
    { hex: '#757575', rgb: 'rgb(117, 117, 117)', hsl: 'hsl(0, 0%, 46%)' },
    { hex: '#616161', rgb: 'rgb(97, 97, 97)', hsl: 'hsl(0, 0%, 38%)' },
    { hex: '#424242', rgb: 'rgb(66, 66, 66)', hsl: 'hsl(0, 0%, 26%)' },
    { hex: '#212121', rgb: 'rgb(33, 33, 33)', hsl: 'hsl(0, 0%, 13%)' },

    { hex: '#373131', rgb: 'rgb(33, 33, 33)', hsl: 'hsl(0, 0%, 13%)' },
    { hex: '#191919', rgb: 'rgb(33, 33, 33)', hsl: 'hsl(0, 0%, 13%)' },
    { hex: '#141414', rgb: 'rgb(33, 33, 33)', hsl: 'hsl(0, 0%, 13%)' },
    { hex: '#000000', rgb: 'rgb(33, 33, 33)', hsl: 'hsl(0, 0%, 13%)' },
];



export const blueGrey = [
    { hex: '#ECEFF1', rgb: 'rgb(236, 239, 241)', hsl: 'hsl(207, 33%, 94%)' },
    { hex: '#CFD8DC', rgb: 'rgb(207, 216, 220)', hsl: 'hsl(207, 27%, 79%)' },
    { hex: '#B0BEC5', rgb: 'rgb(176, 190, 197)', hsl: 'hsl(207, 21%, 72%)' },
    { hex: '#90A4AE', rgb: 'rgb(144, 164, 174)', hsl: 'hsl(207, 15%, 63%)' },
    { hex: '#78909C', rgb: 'rgb(120, 144, 156)', hsl: 'hsl(207, 14%, 52%)' },
    { hex: '#607D8B', rgb: 'rgb(96, 125, 139)', hsl: 'hsl(207, 17%, 45%)' },
    { hex: '#546E7A', rgb: 'rgb(84, 110, 122)', hsl: 'hsl(207, 18%, 39%)' },
    { hex: '#455A64', rgb: 'rgb(69, 90, 100)', hsl: 'hsl(207, 18%, 33%)' },
    { hex: '#37474F', rgb: 'rgb(55, 71, 79)', hsl: 'hsl(207, 18%, 27%)' },
    { hex: '#263238', rgb: 'rgb(38, 50, 56)', hsl: 'hsl(207, 18%, 18%)' },

    { hex: '#3a4043', rgb: 'rgb(38, 50, 56)', hsl: 'hsl(207, 18%, 18%)' },
    { hex: '#1c333c', rgb: 'rgb(38, 50, 56)', hsl: 'hsl(207, 18%, 18%)' },
    { hex: '#152830', rgb: 'rgb(38, 50, 56)', hsl: 'hsl(207, 18%, 18%)' },
    { hex: '#112127', rgb: 'rgb(38, 50, 56)', hsl: 'hsl(207, 18%, 18%)' },
];

