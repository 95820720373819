import { Box, Button, Grid, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react"
import { contect_Data } from '../Quiz_data/Quiz_data.js';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";



export const Dashboard = () => {
    const personal = JSON.parse(localStorage.getItem('personal')) || {};
    const navigate = useNavigate();
    const toast = useToast();
    const [allquiz, setAllquiz] = useState();

    useEffect(() => {
        axios.get(`http://localhost:8080/quiz/get`, {
            headers: { "Authorization": personal['token'] }
        })
            .then((res) => {
                // console.log(res.data.quiz)
                setAllquiz(res.data.quiz)
            })
            .catch(() => {
                toast({ title: "Somthing went wrong Please try again!", status: 'error', duration: 3000, isClosable: true, position: 'top', })
            })
    }, [])


    return (
        <Box w={'100%'}>
            <Box w={['95%']} m={'auto'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'10px'}>
                <Box onClick={() => navigate('/quiz')} cursor={'pointer'} display={'flex'} gap={'10px'}>
                    <Text color={'orange'} fontSize={'25px'} fontWeight={'bold'}>|| Quiz</Text>
                    <Text color={'grey'} fontSize={'25px'} fontWeight={'bold'}>Time</Text>
                </Box>
                <Box display={'flex'} gap={'5px'}>
                    <Text color={'orange'} fontWeight={'medium'}>Hi,</Text>
                    <Text>{personal['userName'] && personal['userName'].split(' ')[0]}</Text>
                </Box>
            </Box>

            <Box w={['95%', '90%', '90%', '80%']} gap={'15px'} m={'1cm auto'} display={'flex'} flexDirection={['column', 'column', 'row', 'row']} justifyContent={'space-between'} alignItems={'center'}>
                {!allquiz || allquiz.length == 0 ?
                    <Box w={'170px'} h={'170px'} color={'grey'} fontWeight={'bold'} fontSize={'30px'} m={'10px'} display={'flex'} justifyContent={'center'} alignItems={'center'} border={'1px solid orange'} _hover={{ boxShadow: 'red 0px 0px 30px', border: '1px solid red' }} transition={'0.5s ease'} borderRadius={'50%'} boxShadow={'orange 0px 0px 30px'}>
                        Quiz
                    </Box>
                    :
                    <Box w={['95%', '95%', 'auto']} maxH={'270px'} overflow={'scroll'} css={{ '-ms-overflow-style': 'none', scrollbarWidth: 'none' }}>
                        <style>{`::-webkit-scrollbar {display: none}`}</style>

                        <TableContainer >
                            <Table variant='striped' colorScheme='orange'>
                                <Thead>
                                    <Tr>
                                        <Th>Score</Th>
                                        <Th>Language</Th>
                                        <Th>Time</Th>
                                        <Th>Corrent</Th>
                                        <Th>Worng</Th>
                                        <Th>Total</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {allquiz && allquiz.length != 0 && allquiz?.map((ele, index) => (
                                        <Tr key={index} fontSize={['13px', '14px', '15px']}>
                                            <Td textAlign={'center'}>
                                                {ele.persentage >= 60 && <Box w={'25px'} h={'25px'} display={'flex'} justifyContent={'center'} alignItems={'center'} bg={'#00C853'} color={'white'} borderRadius={'50%'}>{ele.persentage}</Box>}
                                                {(ele.persentage < 60 && ele.persentage >= 40) && <Box w={'25px'} h={'25px'} display={'flex'} justifyContent={'center'} alignItems={'center'} bg={'#FFB74D'} color={'white'} borderRadius={'50%'}>{ele.persentage}</Box>}
                                                {ele.persentage < 40 && <Box w={'25px'} h={'25px'} display={'flex'} justifyContent={'center'} alignItems={'center'} bg={'#E57373'} color={'white'} borderRadius={'50%'}>{ele.persentage}</Box>}

                                            </Td>
                                            <Td textAlign={'center'}>{ele.language}</Td>
                                            <Td textAlign={'center'} color={'grey'}>{ele.time}</Td>
                                            <Td textAlign={'center'}>{ele.currect}</Td>
                                            <Td textAlign={'center'}>{ele.wrong}</Td>
                                            <Td textAlign={'center'}>{ele.total}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <Box display={'flex'} m={'7px'} gap={'7px'}>
                            <Button bg={'#FFB74D'} fontSize={'14px'} color={'white'}>Pre</Button>
                            <Button>0</Button>
                            <Button bg={'#FFB74D'} fontSize={'14px'} color={'white'}>Next</Button>
                        </Box>
                    </Box>
                }

                <Box w={['95%', '95%', '40%', '40%']} fontSize={['16px', '17px', '15px', '18px']} fontWeight={'medium'} color={'grey'} p={'10px'} m={'10px'}>
                    <Text>
                        Quiz Time are interactive platform where students participate to gain knowledge and excel beyond academics.
                    </Text>
                    <Button w={'200px'} bg={'orange'} color={'whiteAlpha.900'} mt={'15px'}>START</Button>
                </Box>

            </Box>

            <Box border={'1px solid transparent'} borderRadius={'20px 20px 0 0'} bg={'linear-gradient(30deg, rgb(0, 7, 61), rgb(0, 10, 63), rgb(0, 13, 66), rgb(0, 16, 68), rgb(0, 19, 71), rgb(0, 22, 73), rgb(0, 25, 76), rgb(0, 28, 78), rgb(0, 31, 80), rgb(0, 34, 83), rgb(0, 37, 85), rgb(0, 40, 88), rgb(0, 43, 90), rgb(0, 46, 92), rgb(0, 49, 95), rgb(0, 52, 97), rgb(0, 55, 100), rgb(0, 58, 102), rgb(0, 61, 105), rgb(0, 64, 107))'}>
                <Grid w={'90%'} m={'1cm auto'} templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']} gap={['30px 20px']}>
                    {contect_Data && contect_Data?.map((ele, index) => (
                        <Box border={`3px solid ${ele.boxshadow}`} onClick={() => navigate(`/quiz/${ele.url}`)} cursor={'pointer'} key={index} borderRadius={'20px'}>
                            <Box bg={''} color={ele.textcolor} m={'15px auto'} fontSize={'25px'} fontWeight={'medium'} display={'flex'} justifyContent={'center'} alignItems={'center'} border={`1px solid ${ele.boxshadow}`} borderRadius={'50%'} width={'130px'} height={'130px'} boxShadow={`${ele.boxshadow} 0px 0px 5px`}>
                                {ele.title}
                            </Box>
                            <Text borderRadius={'0 0 20px 20px'} bg={'#8080801F'} color={'white'} _hover={{ color: ele.textcolor }} transition={'0.4s ease'} fontWeight={'medium'} p={'10px'} textAlign={'center'}>{ele.name}</Text>

                        </Box>
                    ))}
                </Grid>
            </Box>
        </Box>
    )
}