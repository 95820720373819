import { Route, Routes } from 'react-router-dom'
import { HomePage } from '../../Artkon/HomePage/HomePage'
import { BoxShadow } from '../../Artkon/BoxShadow/BoxShadow'
import { ColorShade } from '../../Artkon/ColorShade/ColorShade'
import { ColorPicker } from '../../Artkon/ColorPicker/ColorPicker'
import ColorConverter from '../../Artkon/ColorConverter/ColorConverter'
import { UniCode } from '../../Artkon/UniCode/UniCode'
import { Emoji } from '../../Artkon/Emoji/Emoji'
import { DownloadMedia } from '../../Artkon/DownloadMedia/DownloadMedia'
import { GradientColors } from '../../Artkon/GradientColors/GradientColors'
import { ColorMaterial } from '../../Artkon/ColorMaterial/ColorMaterial'
import { GDriveURLConverter } from '../../Artkon/GDriveURLConverter/GDriveURLConverter'
import { ClipPath } from '../../Artkon/ClipPath/ClipPath'
import { GradientMaker } from '../../Artkon/GradientMaker/GradientMaker'
import { Animation } from '../../Artkon/Animation/Animation'
import { AnimationPage } from '../../Artkon/Animation/AnimationPage'
import { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { Footer } from '../Footer/Footer'
import { Sidebar } from '../Sidebar/Sidebar'


export const ArtkonRouter = () => {
    const isSmallerThan1000 = window.matchMedia("(max-width:1000px)").matches
    const [side, setSide] = useState(isSmallerThan1000 ? false : true)

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the new page
    }, []);

    return (

        <Box>
            <Box display={'flex'} w={'100%'} h={'100vh'}>
                {!isSmallerThan1000 ?
                    <Box style={{ width: side ? '19%' : '0px', backgroundColor: 'white' }} overflow={'scroll'} css={{ '-ms-overflow-style': 'none', scrollbarWidth: 'none' }}>
                        <style>{`::-webkit-scrollbar {display: none;}`}</style>
                        {side ? <Box><Sidebar side={side} setSide={setSide} isSmallerThan1000={isSmallerThan1000} /></Box> : null}
                    </Box>
                    :
                    <Box overflow={'scroll'} css={{ '-ms-overflow-style': 'none', scrollbarWidth: 'none' }}>
                        <style>{`::-webkit-scrollbar {display: none;}`}</style>
                        {side ? <Box style={{ width: !isSmallerThan1000 ? '39%' : '50%', position: 'fixed', zIndex: '10', top: '0', backgroundColor: 'rgba(0, 0, 0, 0.589)', height: '100vh' }}>
                            {side ? <Box><Sidebar side={side} setSide={setSide} isSmallerThan1000={isSmallerThan1000} /></Box> : null}</Box> : null}
                    </Box>
                }

                <Box w={isSmallerThan1000 ? '95%' : '80%'} overflow={'scroll'} css={{ '-ms-overflow-style': 'none', scrollbarWidth: 'none' }} m={'0 auto'}>
                    <style>{`::-webkit-scrollbar {display: none;}`}</style>
                    <Routes>
                        <Route path='/' element={<HomePage side={side} setSide={setSide} />}></Route>
                        <Route path='/Box-Shadow' element={<BoxShadow side={side} setSide={setSide} />}></Route>
                        <Route path='/ColorShade' element={<ColorShade side={side} setSide={setSide} />}></Route>
                        <Route path='/ColorPicker' element={<ColorPicker side={side} setSide={setSide} />}></Route>
                        <Route path='/ColorConverter' element={<ColorConverter side={side} setSide={setSide} />}></Route>
                        <Route path='/UniCode' element={<UniCode side={side} setSide={setSide} />}></Route>
                        <Route path='/Emoji' element={<Emoji side={side} setSide={setSide} />}></Route>
                        {/* <Route path='/DownloadMedia' element={<DownloadMedia side={side} setSide={setSide} />}></Route> */}
                        <Route path='/GradientColors' element={<GradientColors side={side} setSide={setSide} />}></Route>
                        <Route path='/ColorMaterial' element={<ColorMaterial side={side} setSide={setSide} />}></Route>
                        <Route path='/GDriveURLConverter' element={<GDriveURLConverter side={side} setSide={setSide} />}></Route>
                        <Route path='/ClipPath' element={<ClipPath side={side} setSide={setSide} />}></Route>
                        <Route path='/GradientMaker' element={<GradientMaker side={side} setSide={setSide} />}></Route>
                        <Route path='/Animation' element={<Animation side={side} setSide={setSide} />}></Route>
                        <Route path='/AnimationPage/:id' element={<AnimationPage side={side} setSide={setSide} />}></Route>
                    </Routes>
                </Box>
            </Box>
            {/* <Footer /> */}
        </Box>
    )
}

