// import artkon from '../../assets/MainImage/artkon.jpg'
import artkorner from '../../assets/artkorner.jpg';
import quiz_page from '../../assets/quiz_page.jpg';
import skeleton from '../../assets/skeleton.jpg';
import uimaster from '../../assets/uimaster.jpg';


export const starter_products = [
    {
        title: 'Skeleton',
        url: '/Skeleton',
        description: 'Skeleton loading is a user interface (UI) design technique used to enhance the user experience during content loading. Instead of displaying a traditional loading spinner or indicator use our pre-made Skeleton for Free.',
        image: skeleton,
    },
    {
        title: 'Quiz Time',
        url: '/quiz',
        description: 'Quiz Time is a website where you can interact with programming language quizzes. We also provide the roadmap for that programming language to help you become a pro developer.',
        image: quiz_page,
    },
    {
        title: 'Art Korner',
        url: '/artkon',
        description: 'Art korner provide you Color Meterial, Box Shadow, Drive Converter, Word Animation and many more UI tools, that helps you to make your website faster and better. Try now!!',
        image: artkorner,
    },
    // {
    //     title: 'TicTacToe',
    //     url: '/game/TicTacToe',
    //     description: 'Make you website fast & colorful with Artkon ',
    //     image: 'https://drive.google.com/uc?id=13ZQ4JzPT_8NEeWpj4WsIcrsvqcEBBM9O',
    // },
    // {
    //     title: 'UI Master',
    //     url: '/UiMaster',
    //     description: 'UI Master helps you by providing lots of fontend features',
    //     image: uimaster
    // }
];