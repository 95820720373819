export const uni_all_arrow = [
    {
        symbol: "↞",
        unicode: "\u{219E}",
        hex: "U+219E",
        htmlEntity: "&#x219E;",
        cssEscape: "\\219E"
    },
    {
        symbol: "↤",
        unicode: "\u{21A4}",
        hex: "U+21A4",
        htmlEntity: "&#x21A4;",
        cssEscape: "\\21A4"
    },
    {
        symbol: "↥",
        unicode: "\u{21A5}",
        hex: "U+21A5",
        htmlEntity: "&#x21A5;",
        cssEscape: "\\21A5"
    },
    {
        symbol: "↦",
        unicode: "\u{21A6}",
        hex: "U+21A6",
        htmlEntity: "&#x21A6;",
        cssEscape: "\\21A6"
    },
    {
        symbol: "↟",
        unicode: "\u{219F}",
        hex: "U+219F",
        htmlEntity: "&#x219F;",
        cssEscape: "\\219F"
    },
    {
        symbol: "➤",
        unicode: "\u{27A4}",
        hex: "U+27A4",
        htmlEntity: "&#x27A4;",
        cssEscape: "\\27A4"
    },
    {
        symbol: "⤷",
        unicode: "\u{2937}",
        hex: "U+2937",
        htmlEntity: "&#x2937;",
        cssEscape: "\\2937"
    },
    {
        symbol: "⇛",
        unicode: "\u{21DB}",
        hex: "U+21DB",
        htmlEntity: "&#x21DB;",
        cssEscape: "\\21DB"
    },
    {
        symbol: "↠",
        unicode: "\u{21A0}",
        hex: "U+21A0",
        htmlEntity: "&#x21A0;",
        cssEscape: "\\21A0"
    },
    {
        symbol: "↡",
        unicode: "\u{21A1}",
        hex: "U+21A1",
        htmlEntity: "&#x21A1;",
        cssEscape: "\\21A1"
    },
    {
        symbol: "↢",
        unicode: "\u{21A2}",
        hex: "U+21A2",
        htmlEntity: "&#x21A2;",
        cssEscape: "\\21A2"
    },
    {
        symbol: "↣",
        unicode: "\u{21A3}",
        hex: "U+21A3",
        htmlEntity: "&#x21A3;",
        cssEscape: "\\21A3"
    },
    {
        symbol: "↧",
        unicode: "\u{21A7}",
        hex: "U+21A7",
        htmlEntity: "&#x21A7;",
        cssEscape: "\\21A7"
    },
    {
        symbol: "↨",
        unicode: "\u{21A8}",
        hex: "U+21A8",
        htmlEntity: "&#x21A8;",
        cssEscape: "\\21A8"
    },
    {
        symbol: "↩",
        unicode: "\u{21A9}",
        hex: "U+21A9",
        htmlEntity: "&#x21A9;",
        cssEscape: "\\21A9"
    },
    {
        symbol: "↪",
        unicode: "\u{21AA}",
        hex: "U+21AA",
        htmlEntity: "&#x21AA;",
        cssEscape: "\\21AA"
    },
    {
        symbol: "↫",
        unicode: "\u{21AB}",
        hex: "U+21AB",
        htmlEntity: "&#x21AB;",
        cssEscape: "\\21AB"
    },
    {
        symbol: "↬",
        unicode: "\u{21AC}",
        hex: "U+21AC",
        htmlEntity: "&#x21AC;",
        cssEscape: "\\21AC"
    },
    {
        symbol: "↭",
        unicode: "\u{21AD}",
        hex: "U+21AD",
        htmlEntity: "&#x21AD;",
        cssEscape: "\\21AD"
    },
    {
        symbol: "↮",
        unicode: "\u{21AE}",
        hex: "U+21AE",
        htmlEntity: "&#x21AE;",
        cssEscape: "\\21AE"
    },
    {
        symbol: "↯",
        unicode: "\u{21AF}",
        hex: "U+21AF",
        htmlEntity: "&#x21AF;",
        cssEscape: "\\21AF"
    },
    {
        symbol: "⬓",
        unicode: "\u{2B53}",
        hex: "U+2B53",
        htmlEntity: "&#x2B53;",
        cssEscape: "\\2B53"
    },
    {
        symbol: "⭅",
        unicode: "\u{2B85}",
        hex: "U+2B85",
        htmlEntity: "&#x2B85;",
        cssEscape: "\\2B85"
    },
    {
        symbol: "⭆",
        unicode: "\u{2B86}",
        hex: "U+2B86",
        htmlEntity: "&#x2B86;",
        cssEscape: "\\2B86"
    },
    {
        symbol: "⮍",
        unicode: "\u{2B8D}",
        hex: "U+2B8D",
        htmlEntity: "&#x2B8D;",
        cssEscape: "\\2B8D"
    },
    {
        symbol: "➡",
        unicode: "\u{2192}",
        hex: "U+2192",
        htmlEntity: "&#x2192;",
        cssEscape: "\\2192"
    },
    {
        symbol: "⬅",
        unicode: "\u{2190}",
        hex: "U+2190",
        htmlEntity: "&#x2190;",
        cssEscape: "\\2190"
    },
    {
        symbol: "⬆",
        unicode: "\u{2191}",
        hex: "U+2191",
        htmlEntity: "&#x2191;",
        cssEscape: "\\2191"
    },
    {
        symbol: "⬇",
        unicode: "\u{2193}",
        hex: "U+2193",
        htmlEntity: "&#x2193;",
        cssEscape: "\\2193"
    },
    {
        symbol: "↔",
        unicode: "\u{2194}",
        hex: "U+2194",
        htmlEntity: "&#x2194;",
        cssEscape: "\\2194"
    },
    {
        symbol: "↕",
        unicode: "\u{2195}",
        hex: "U+2195",
        htmlEntity: "&#x2195;",
        cssEscape: "\\2195"
    },
    {
        symbol: "⇄",
        unicode: "\u{21C4}",
        hex: "U+21C4",
        htmlEntity: "&#x21C4;",
        cssEscape: "\\21C4"
    },
    {
        symbol: "⇅",
        unicode: "\u{21C5}",
        hex: "U+21C5",
        htmlEntity: "&#x21C5;",
        cssEscape: "\\21C5"
    },
    {
        symbol: "⇆",
        unicode: "\u{21C6}",
        hex: "U+21C6",
        htmlEntity: "&#x21C6;",
        cssEscape: "\\21C6"
    },
    {
        symbol: "⇩",
        unicode: "\u{21E9}",
        hex: "U+21E9",
        htmlEntity: "&#x21E9;",
        cssEscape: "\\21E9"
    },
    {
        symbol: "⇪",
        unicode: "\u{21EA}",
        hex: "U+21EA",
        htmlEntity: "&#x21EA;",
        cssEscape: "\\21EA"
    },
    {
        symbol: "↖",
        unicode: "\u{2196}",
        hex: "U+2196",
        htmlEntity: "&#x2196;",
        cssEscape: "\\2196"
    },
    {
        symbol: "↗",
        unicode: "\u{2197}",
        hex: "U+2197",
        htmlEntity: "&#x2197;",
        cssEscape: "\\2197"
    },
    {
        symbol: "↘",
        unicode: "\u{2198}",
        hex: "U+2198",
        htmlEntity: "&#x2198;",
        cssEscape: "\\2198"
    },
    {
        symbol: "↙",
        unicode: "\u{2199}",
        hex: "U+2199",
        htmlEntity: "&#x2199;",
        cssEscape: "\\2199"
    },
    {
        symbol: "⤴",
        unicode: "\u{2934}",
        hex: "U+2934",
        htmlEntity: "&#x2934;",
        cssEscape: "\\2934"
    },
    {
        symbol: "⤵",
        unicode: "\u{2935}",
        hex: "U+2935",
        htmlEntity: "&#x2935;",
        cssEscape: "\\2935"
    },
    {
        symbol: "⬈",
        unicode: "\u{2196}",
        hex: "U+2196",
        htmlEntity: "&#x2196;",
        cssEscape: "\\2196"
    },
    {
        symbol: "⬉",
        unicode: "\u{2191}",
        hex: "U+2191",
        htmlEntity: "&#x2191;",
        cssEscape: "\\2191"
    },
    {
        symbol: "⬊",
        unicode: "\u{2193}",
        hex: "U+2193",
        htmlEntity: "&#x2193;",
        cssEscape: "\\2193"
    },
    {
        symbol: "⬋",
        unicode: "\u{2198}",
        hex: "U+2198",
        htmlEntity: "&#x2198;",
        cssEscape: "\\2198"
    },
    {
        symbol: "⬏",
        unicode: "\u{2190}",
        hex: "U+2190",
        htmlEntity: "&#x2190;",
        cssEscape: "\\2190"
    },
    {
        symbol: "⬐",
        unicode: "\u{2193}",
        hex: "U+2193",
        htmlEntity: "&#x2193;",
        cssEscape: "\\2193"
    },
    {
        symbol: "⬑",
        unicode: "\u{2191}",
        hex: "U+2191",
        htmlEntity: "&#x2191;",
        cssEscape: "\\2191"
    },
    {
        symbol: "⬒",
        unicode: "\u{2190}",
        hex: "U+2190",
        htmlEntity: "&#x2190;",
        cssEscape: "\\2190"
    },
    {
        name: "US Dollar",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Euro",
        symbol: "€",
        unicode: "\u{20AC}",
        hex: "U+20AC",
        htmlEntity: "&#x20AC;",
        cssEscape: "\\20AC"
    },
    {
        name: "Japanese Yen",
        symbol: "¥",
        unicode: "\u{00A5}",
        hex: "U+00A5",
        htmlEntity: "&#x00A5;",
        cssEscape: "\\00A5"
    },
    {
        name: "British Pound",
        symbol: "£",
        unicode: "\u{00A3}",
        hex: "U+00A3",
        htmlEntity: "&#x00A3;",
        cssEscape: "\\00A3"
    },
    {
        name: "Canadian Dollar",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Australian Dollar",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Swiss Franc",
        symbol: "Fr",
        unicode: "\u{20A3}",
        hex: "U+20A3",
        htmlEntity: "&#x20A3;",
        cssEscape: "\\20A3"
    },
    {
        name: "Chinese Yuan",
        symbol: "¥",
        unicode: "\u{00A5}",
        hex: "U+00A5",
        htmlEntity: "&#x00A5;",
        cssEscape: "\\00A5"
    },
    {
        name: "Indian Rupee",
        symbol: "₹",
        unicode: "\u{20B9}",
        hex: "U+20B9",
        htmlEntity: "&#x20B9;",
        cssEscape: "\\20B9"
    },
    {
        name: "Brazilian Real",
        symbol: "R$",
        unicode: "\u{20A2}",
        hex: "U+20A2",
        htmlEntity: "&#x20A2;",
        cssEscape: "\\20A2"
    },
    {
        name: "South Korean Won",
        symbol: "₩",
        unicode: "\u{20A9}",
        hex: "U+20A9",
        htmlEntity: "&#x20A9;",
        cssEscape: "\\20A9"
    },
    {
        name: "Russian Ruble",
        symbol: "₽",
        unicode: "\u{20BD}",
        hex: "U+20BD",
        htmlEntity: "&#x20BD;",
        cssEscape: "\\20BD"
    },
    {
        name: "Mexican Peso",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "South African Rand",
        symbol: "R",
        unicode: "\u{0052}",
        hex: "U+0052",
        htmlEntity: "&#x0052;",
        cssEscape: "\\0052"
    },
    {
        name: "Turkish Lira",
        symbol: "₺",
        unicode: "\u{20BA}",
        hex: "U+20BA",
        htmlEntity: "&#x20BA;",
        cssEscape: "\\20BA"
    },
    {
        name: "Hong Kong Dollar",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Indonesian Rupiah",
        symbol: "Rp",
        unicode: "\u{20A8}",
        hex: "U+20A8",
        htmlEntity: "&#x20A8;",
        cssEscape: "\\20A8"
    },
    {
        name: "Canadian Dollar",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Israeli New Shekel",
        symbol: "₪",
        unicode: "\u{20AA}",
        hex: "U+20AA",
        htmlEntity: "&#x20AA;",
        cssEscape: "\\20AA"
    },
    {
        name: "Singapore Dollar",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "New Zealand Dollar",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Hungarian Forint",
        symbol: "Ft",
        unicode: "\u{20B8}",
        hex: "U+20B8",
        htmlEntity: "&#x20B8;",
        cssEscape: "\\20B8"
    },
    {
        name: "Swedish Krona",
        symbol: "kr",
        unicode: "\u{20AC}",
        hex: "U+20AC",
        htmlEntity: "&#x20AC;",
        cssEscape: "\\20AC"
    },
    {
        name: "Polish Złoty",
        symbol: "zł",
        unicode: "\u{007A}\u{0142}",
        hex: "U+007A U+0142",
        htmlEntity: "&#x007A;&#x0142;",
        cssEscape: "\\007A\\0142"
    },
    {
        name: "Norwegian Krone",
        symbol: "kr",
        unicode: "\u{20AC}",
        hex: "U+20AC",
        htmlEntity: "&#x20AC;",
        cssEscape: "\\20AC"
    },
    {
        name: "Czech Koruna",
        symbol: "Kč",
        unicode: "\u{004B}\u{010D}",
        hex: "U+004B U+010D",
        htmlEntity: "&#x004B;&#x010D;",
        cssEscape: "\\004B\\010D"
    },
    {
        name: "Danish Krone",
        symbol: "kr",
        unicode: "\u{20AC}",
        hex: "U+20AC",
        htmlEntity: "&#x20AC;",
        cssEscape: "\\20AC"
    },
    {
        name: "Icelandic Króna",
        symbol: "kr",
        unicode: "\u{20AC}",
        hex: "U+20AC",
        htmlEntity: "&#x20AC;",
        cssEscape: "\\20AC"
    },
    {
        name: "Romanian Leu",
        symbol: "lei",
        unicode: "\u{006C}\u{0065}\u{0069}",
        hex: "U+006C U+0065 U+0069",
        htmlEntity: "&#x006C;&#x0065;&#x0069;",
        cssEscape: "\\006C\\0065\\0069"
    },
    {
        name: "Philippine Peso",
        symbol: "₱",
        unicode: "\u{20B1}",
        hex: "U+20B1",
        htmlEntity: "&#x20B1;",
        cssEscape: "\\20B1"
    },
    {
        name: "Thai Baht",
        symbol: "฿",
        unicode: "\u{0E3F}",
        hex: "U+0E3F",
        htmlEntity: "&#x0E3F;",
        cssEscape: "\\0E3F"
    },
    {
        name: "Israeli New Shekel",
        symbol: "₪",
        unicode: "\u{20AA}",
        hex: "U+20AA",
        htmlEntity: "&#x20AA;",
        cssEscape: "\\20AA"
    },
    {
        name: "Argentine Peso",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Peruvian Sol",
        symbol: "S/",
        unicode: "\u{0053}\u{002F}",
        hex: "U+0053 U+002F",
        htmlEntity: "&#x0053;&#x002F;",
        cssEscape: "\\0053\\002F"
    },
    {
        name: "Chilean Peso",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Colombian Peso",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Malaysian Ringgit",
        symbol: "RM",
        unicode: "\u{0052}\u{004D}",
        hex: "U+0052 U+004D",
        htmlEntity: "&#x0052;&#x004D;",
        cssEscape: "\\0052\\004D"
    },
    {
        name: "Bulgarian Lev",
        symbol: "лв",
        unicode: "\u{043B}\u{0432}",
        hex: "U+043B U+0432",
        htmlEntity: "&#x043B;&#x0432;",
        cssEscape: "\\043B\\0432"
    },
    {
        name: "Russian Ruble",
        symbol: "₽",
        unicode: "\u{20BD}",
        hex: "U+20BD",
        htmlEntity: "&#x20BD;",
        cssEscape: "\\20BD"
    },
    {
        name: "Croatian Kuna",
        symbol: "kn",
        unicode: "\u{006B}\u{006E}",
        hex: "U+006B U+006E",
        htmlEntity: "&#x006B;&#x006E;",
        cssEscape: "\\006B\\006E"
    },
    {
        name: "Exclamation Mark",
        symbol: "!",
        unicode: "\u{0021}",
        hex: "U+0021",
        htmlEntity: "&#x0021;",
        cssEscape: "\\0021"
    },
    {
        name: "Quotation Mark",
        symbol: "\"",
        unicode: "\u{0022}",
        hex: "U+0022",
        htmlEntity: "&#x0022;",
        cssEscape: "\\0022"
    },
    {
        name: "Number Sign",
        symbol: "#",
        unicode: "\u{0023}",
        hex: "U+0023",
        htmlEntity: "&#x0023;",
        cssEscape: "\\0023"
    },
    {
        name: "Dollar Sign",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Percent Sign",
        symbol: "%",
        unicode: "\u{0025}",
        hex: "U+0025",
        htmlEntity: "&#x0025;",
        cssEscape: "\\0025"
    },
    {
        name: "Ampersand",
        symbol: "&",
        unicode: "\u{0026}",
        hex: "U+0026",
        htmlEntity: "&#x0026;",
        cssEscape: "\\0026"
    },
    {
        name: "Apostrophe",
        symbol: "'",
        unicode: "\u{0027}",
        hex: "U+0027",
        htmlEntity: "&#x0027;",
        cssEscape: "\\0027"
    },
    {
        name: "Left Parenthesis",
        symbol: "(",
        unicode: "\u{0028}",
        hex: "U+0028",
        htmlEntity: "&#x0028;",
        cssEscape: "\\0028"
    },
    {
        name: "Right Parenthesis",
        symbol: ")",
        unicode: "\u{0029}",
        hex: "U+0029",
        htmlEntity: "&#x0029;",
        cssEscape: "\\0029"
    },
    {
        name: "Asterisk",
        symbol: "*",
        unicode: "\u{002A}",
        hex: "U+002A",
        htmlEntity: "&#x002A;",
        cssEscape: "\\002A"
    },
    {
        name: "Plus Sign",
        symbol: "+",
        unicode: "\u{002B}",
        hex: "U+002B",
        htmlEntity: "&#x002B;",
        cssEscape: "\\002B"
    },
    {
        name: "Comma",
        symbol: ",",
        unicode: "\u{002C}",
        hex: "U+002C",
        htmlEntity: "&#x002C;",
        cssEscape: "\\002C"
    },
    {
        name: "Hyphen-Minus",
        symbol: "-",
        unicode: "\u{002D}",
        hex: "U+002D",
        htmlEntity: "&#x002D;",
        cssEscape: "\\002D"
    },
    {
        name: "Full Stop",
        symbol: ".",
        unicode: "\u{002E}",
        hex: "U+002E",
        htmlEntity: "&#x002E;",
        cssEscape: "\\002E"
    },
    {
        name: "Solidus (Slash)",
        symbol: "/",
        unicode: "\u{002F}",
        hex: "U+002F",
        htmlEntity: "&#x002F;",
        cssEscape: "\\002F"
    },
    {
        name: "Colon",
        symbol: ":",
        unicode: "\u{003A}",
        hex: "U+003A",
        htmlEntity: "&#x003A;",
        cssEscape: "\\003A"
    },
    {
        name: "Semicolon",
        symbol: ";",
        unicode: "\u{003B}",
        hex: "U+003B",
        htmlEntity: "&#x003B;",
        cssEscape: "\\003B"
    },
    {
        name: "Less-Than Sign",
        symbol: "<",
        unicode: "\u{003C}",
        hex: "U+003C",
        htmlEntity: "&#x003C;",
        cssEscape: "\\003C"
    },
    {
        name: "Equals Sign",
        symbol: "=",
        unicode: "\u{003D}",
        hex: "U+003D",
        htmlEntity: "&#x003D;",
        cssEscape: "\\003D"
    },
    {
        name: "Greater-Than Sign",
        symbol: ">",
        unicode: "\u{003E}",
        hex: "U+003E",
        htmlEntity: "&#x003E;",
        cssEscape: "\\003E"
    },
    {
        name: "Question Mark",
        symbol: "?",
        unicode: "\u{003F}",
        hex: "U+003F",
        htmlEntity: "&#x003F;",
        cssEscape: "\\003F"
    },
    {
        name: "Commercial At",
        symbol: "@",
        unicode: "\u{0040}",
        hex: "U+0040",
        htmlEntity: "&#x0040;",
        cssEscape: "\\0040"
    },
    {
        name: "Left Square Bracket",
        symbol: "[",
        unicode: "\u{005B}",
        hex: "U+005B",
        htmlEntity: "&#x005B;",
        cssEscape: "\\005B"
    },
    {
        name: "Reverse Solidus (Backslash)",
        symbol: "\\",
        unicode: "\u{005C}",
        hex: "U+005C",
        htmlEntity: "&#x005C;",
        cssEscape: "\\005C"
    },
    {
        name: "Right Square Bracket",
        symbol: "]",
        unicode: "\u{005D}",
        hex: "U+005D",
        htmlEntity: "&#x005D;",
        cssEscape: "\\005D"
    },
    {
        name: "Circumflex Accent (Caret)",
        symbol: "^",
        unicode: "\u{005E}",
        hex: "U+005E",
        htmlEntity: "&#x005E;",
        cssEscape: "\\005E"
    },
    {
        name: "Low Line (Underscore)",
        symbol: "_",
        unicode: "\u{005F}",
        hex: "U+005F",
        htmlEntity: "&#x005F;",
        cssEscape: "\\005F"
    },
    {
        name: "Grave Accent",
        symbol: "`",
        unicode: "\u{0060}",
        hex: "U+0060",
        htmlEntity: "&#x0060;",
        cssEscape: "\\0060"
    },
    {
        name: "Left Curly Bracket",
        symbol: "{",
        unicode: "\u{007B}",
        hex: "U+007B",
        htmlEntity: "&#x007B;",
        cssEscape: "\\007B"
    },
    {
        name: "Vertical Line (Pipe)",
        symbol: "|",
        unicode: "\u{007C}",
        hex: "U+007C",
        htmlEntity: "&#x007C;",
        cssEscape: "\\007C"
    },
    {
        name: "Right Curly Bracket",
        symbol: "}",
        unicode: "\u{007D}",
        hex: "U+007D",
        htmlEntity: "&#x007D;",
        cssEscape: "\\007D"
    },
    {
        name: "Tilde",
        symbol: "~",
        unicode: "\u{007E}",
        hex: "U+007E",
        htmlEntity: "&#x007E;",
        cssEscape: "\\007E"
    },
    {
        name: "Left Single Quotation Mark (‘)",
        symbol: "‘",
        unicode: "\u{2018}",
        hex: "U+2018",
        htmlEntity: "&#x2018;",
        cssEscape: "\\2018"
    },
    {
        name: "Right Single Quotation Mark (’)",
        symbol: "’",
        unicode: "\u{2019}",
        hex: "U+2019",
        htmlEntity: "&#x2019;",
        cssEscape: "\\2019"
    },
    {
        name: "Left Double Quotation Mark (“)",
        symbol: "“",
        unicode: "\u{201C}",
        hex: "U+201C",
        htmlEntity: "&#x201C;",
        cssEscape: "\\201C"
    },
    {
        name: "Right Double Quotation Mark (”)",
        symbol: "”",
        unicode: "\u{201D}",
        hex: "U+201D",
        htmlEntity: "&#x201D;",
        cssEscape: "\\201D"
    },
    {
        name: "Bullet (•)",
        symbol: "•",
        unicode: "\u{2022}",
        hex: "U+2022",
        htmlEntity: "&#x2022;",
        cssEscape: "\\2022"
    },
    {
        name: "En Dash (–)",
        symbol: "–",
        unicode: "\u{2013}",
        hex: "U+2013",
        htmlEntity: "&#x2013;",
        cssEscape: "\\2013"
    },
    {
        name: "Em Dash (—)",
        symbol: "—",
        unicode: "\u{2014}",
        hex: "U+2014",
        htmlEntity: "&#x2014;",
        cssEscape: "\\2014"
    },
    {
        name: "Plus-Minus Sign",
        symbol: "±",
        unicode: "\u{00B1}",
        hex: "U+00B1",
        htmlEntity: "&#x00B1;",
        cssEscape: "\\00B1"
    },
    {
        name: "Division Sign",
        symbol: "÷",
        unicode: "\u{00F7}",
        hex: "U+00F7",
        htmlEntity: "&#x00F7;",
        cssEscape: "\\00F7"
    },
    {
        name: "Degree Sign",
        symbol: "°",
        unicode: "\u{00B0}",
        hex: "U+00B0",
        htmlEntity: "&#x00B0;",
        cssEscape: "\\00B0"
    },
    {
        name: "Infinity",
        symbol: "∞",
        unicode: "\u{221E}",
        hex: "U+221E",
        htmlEntity: "&#x221E;",
        cssEscape: "\\221E"
    },
    {
        name: "Square Root",
        symbol: "√",
        unicode: "\u{221A}",
        hex: "U+221A",
        htmlEntity: "&#x221A;",
        cssEscape: "\\221A"
    },
    {
        name: "Less-Than or Equal To",
        symbol: "≤",
        unicode: "\u{2264}",
        hex: "U+2264",
        htmlEntity: "&#x2264;",
        cssEscape: "\\2264"
    },
    {
        name: "Greater-Than or Equal To",
        symbol: "≥",
        unicode: "\u{2265}",
        hex: "U+2265",
        htmlEntity: "&#x2265;",
        cssEscape: "\\2265"
    },
    {
        name: "Not Equal To",
        symbol: "≠",
        unicode: "\u{2260}",
        hex: "U+2260",
        htmlEntity: "&#x2260;",
        cssEscape: "\\2260"
    },
    {
        name: "Element Of",
        symbol: "∈",
        unicode: "\u{2208}",
        hex: "U+2208",
        htmlEntity: "&#x2208;",
        cssEscape: "\\2208"
    },
    {
        name: "Not an Element Of",
        symbol: "∉",
        unicode: "\u{2209}",
        hex: "U+2209",
        htmlEntity: "&#x2209;",
        cssEscape: "\\2209"
    },
    {
        name: "Subset Of",
        symbol: "⊆",
        unicode: "\u{2286}",
        hex: "U+2286",
        htmlEntity: "&#x2286;",
        cssEscape: "\\2286"
    },
    {
        name: "Superset Of",
        symbol: "⊇",
        unicode: "\u{2287}",
        hex: "U+2287",
        htmlEntity: "&#x2287;",
        cssEscape: "\\2287"
    },
    {
        name: "Union",
        symbol: "∪",
        unicode: "\u{222A}",
        hex: "U+222A",
        htmlEntity: "&#x222A;",
        cssEscape: "\\222A"
    },
    {
        name: "Intersection",
        symbol: "∩",
        unicode: "\u{2229}",
        hex: "U+2229",
        htmlEntity: "&#x2229;",
        cssEscape: "\\2229"
    },
    {
        name: "Integral",
        symbol: "∫",
        unicode: "\u{222B}",
        hex: "U+222B",
        htmlEntity: "&#x222B;",
        cssEscape: "\\222B"
    },
    {
        name: "Summation",
        symbol: "∑",
        unicode: "\u{2211}",
        hex: "U+2211",
        htmlEntity: "&#x2211;",
        cssEscape: "\\2211"
    },
    {
        name: "Proportional To",
        symbol: "∝",
        unicode: "\u{221D}",
        hex: "U+221D",
        htmlEntity: "&#x221D;",
        cssEscape: "\\221D"
    },
    {
        name: "Dot Product",
        symbol: "⋅",
        unicode: "\u{22C5}",
        hex: "U+22C5",
        htmlEntity: "&#x22C5;",
        cssEscape: "\\22C5"
    },
    {
        name: "Degree Celsius",
        symbol: "℃",
        unicode: "\u{2103}",
        hex: "U+2103",
        htmlEntity: "&#x2103;",
        cssEscape: "\\2103"
    },
    {
        name: "Per Mille Sign",
        symbol: "‰",
        unicode: "\u{2030}",
        hex: "U+2030",
        htmlEntity: "&#x2030;",
        cssEscape: "\\2030"
    },
    {
        name: "Per Ten Thousand Sign",
        symbol: "‱",
        unicode: "\u{2031}",
        hex: "U+2031",
        htmlEntity: "&#x2031;",
        cssEscape: "\\2031"
    },
    {
        name: "Partial Differential",
        symbol: "∂",
        unicode: "\u{2202}",
        hex: "U+2202",
        htmlEntity: "&#x2202;",
        cssEscape: "\\2202"
    },
    {
        name: "Nabla (Backward Difference)",
        symbol: "∇",
        unicode: "\u{2207}",
        hex: "U+2207",
        htmlEntity: "&#x2207;",
        cssEscape: "\\2207"
    },
    {
        name: "Prime",
        symbol: "′",
        unicode: "\u{2032}",
        hex: "U+2032",
        htmlEntity: "&#x2032;",
        cssEscape: "\\2032"
    },
    {
        name: "Double Prime",
        symbol: "″",
        unicode: "\u{2033}",
        hex: "U+2033",
        htmlEntity: "&#x2033;",
        cssEscape: "\\2033"
    },
    {
        name: "Tilde (Spacing)",
        symbol: "˜",
        unicode: "\u{02DC}",
        hex: "U+02DC",
        htmlEntity: "&#x02DC;",
        cssEscape: "\\02DC"
    },
    {
        name: "Logical And",
        symbol: "∧",
        unicode: "\u{2227}",
        hex: "U+2227",
        htmlEntity: "&#x2227;",
        cssEscape: "\\2227"
    },
    {
        name: "Logical Or",
        symbol: "∨",
        unicode: "\u{2228}",
        hex: "U+2228",
        htmlEntity: "&#x2228;",
        cssEscape: "\\2228"
    },
    {
        name: "For All",
        symbol: "∀",
        unicode: "\u{2200}",
        hex: "U+2200",
        htmlEntity: "&#x2200;",
        cssEscape: "\\2200"
    },
    {
        name: "Exists",
        symbol: "∃",
        unicode: "\u{2203}",
        hex: "U+2203",
        htmlEntity: "&#x2203;",
        cssEscape: "\\2203"
    },
    {
        name: "Empty Set",
        symbol: "∅",
        unicode: "\u{2205}",
        hex: "U+2205",
        htmlEntity: "&#x2205;",
        cssEscape: "\\2205"
    },
    {
        name: "There Exists",
        symbol: "∃!",
        unicode: "\u{2204}",
        hex: "U+2204",
        htmlEntity: "&#x2204;",
        cssEscape: "\\2204"
    },
    {
        name: "N-ary Product",
        symbol: "∏",
        unicode: "\u{220F}",
        hex: "U+220F",
        htmlEntity: "&#x220F;",
        cssEscape: "\\220F"
    },
    {
        name: "N-ary Coproduct",
        symbol: "∐",
        unicode: "\u{2210}",
        hex: "U+2210",
        htmlEntity: "&#x2210;",
        cssEscape: "\\2210"
    },
    {
        name: "Square Root (Radical)",
        symbol: "√",
        unicode: "\u{221A}",
        hex: "U+221A",
        htmlEntity: "&#x221A;",
        cssEscape: "\\221A"
    },
    {
        name: "Cubed Root (Radical)",
        symbol: "∛",
        unicode: "\u{221B}",
        hex: "U+221B",
        htmlEntity: "&#x221B;",
        cssEscape: "\\221B"
    },
    {
        name: "Fourth Root (Radical)",
        symbol: "∜",
        unicode: "\u{221C}",
        hex: "U+221C",
        htmlEntity: "&#x221C;",
        cssEscape: "\\221C"
    },
    {
        name: "Empty Set (Null Set)",
        symbol: "∅",
        unicode: "\u{2205}",
        hex: "U+2205",
        htmlEntity: "&#x2205;",
        cssEscape: "\\2205"
    },
    {
        name: "Not a Subset Of",
        symbol: "⊈",
        unicode: "\u{2288}",
        hex: "U+2288",
        htmlEntity: "&#x2288;",
        cssEscape: "\\2288"
    },
    {
        name: "Not a Superset Of",
        symbol: "⊉",
        unicode: "\u{2289}",
        hex: "U+2289",
        htmlEntity: "&#x2289;",
        cssEscape: "\\2289"
    },
    {
        name: "Ring Operator",
        symbol: "∘",
        unicode: "\u{2218}",
        hex: "U+2218",
        htmlEntity: "&#x2218;",
        cssEscape: "\\2218"
    },
    {
        name: "Angle",
        symbol: "∠",
        unicode: "\u{2220}",
        hex: "U+2220",
        htmlEntity: "&#x2220;",
        cssEscape: "\\2220"
    },
    {
        name: "Logical Not",
        symbol: "¬",
        unicode: "\u{00AC}",
        hex: "U+00AC",
        htmlEntity: "&#x00AC;",
        cssEscape: "\\00AC"
    },
    {
        name: "Perpendicular",
        symbol: "⊥",
        unicode: "\u{22A5}",
        hex: "U+22A5",
        htmlEntity: "&#x22A5;",
        cssEscape: "\\22A5"
    },
    {
        name: "Right Angle",
        symbol: "∟",
        unicode: "\u{221F}",
        hex: "U+221F",
        htmlEntity: "&#x221F;",
        cssEscape: "\\221F"
    },
    {
        name: "Partial Differential",
        symbol: "∂",
        unicode: "\u{2202}",
        hex: "U+2202",
        htmlEntity: "&#x2202;",
        cssEscape: "\\2202"
    },
    {
        name: "Cursor Pointer",
        symbol: "➡",
        unicode: "\u{27A1}",
        hex: "U+27A1",
        htmlEntity: "&#x27A1;",
        cssEscape: "\\27A1"
    },
    {
        name: "Hand Pointer",
        symbol: "☞",
        unicode: "\u{261E}",
        hex: "U+261E",
        htmlEntity: "&#x261E;",
        cssEscape: "\\261E"
    },
    {
        name: "Zoom In",
        symbol: "🔍",
        unicode: "\u{1F50D}",
        hex: "U+1F50D",
        htmlEntity: "&#x1F50D;",
        cssEscape: "\\1F50D"
    },
    {
        name: "Zoom Out",
        symbol: "🔎",
        unicode: "\u{1F50E}",
        hex: "U+1F50E",
        htmlEntity: "&#x1F50E;",
        cssEscape: "\\1F50E"
    },
    {
        name: "Drag Handle",
        symbol: "🖐",
        unicode: "\u{1F590}",
        hex: "U+1F590",
        htmlEntity: "&#x1F590;",
        cssEscape: "\\1F590"
    },
    {
        name: "Mouse",
        symbol: "🖱",
        unicode: "\u{1F5B1}",
        hex: "U+1F5B1",
        htmlEntity: "&#x1F5B1;",
        cssEscape: "\\1F5B1"
    },
    {
        name: "Joystick",
        symbol: "🕹",
        unicode: "\u{1F579}",
        hex: "U+1F579",
        htmlEntity: "&#x1F579;",
        cssEscape: "\\1F579"
    },
    {
        name: "Keyboard",
        symbol: "⌨",
        unicode: "\u{2328}",
        hex: "U+2328",
        htmlEntity: "&#x2328;",
        cssEscape: "\\2328"
    },
    {
        name: "Clipboard",
        symbol: "📋",
        unicode: "\u{1F4CB}",
        hex: "U+1F4CB",
        htmlEntity: "&#x1F4CB;",
        cssEscape: "\\1F4CB"
    },
    {
        name: "Scissors",
        symbol: "✂",
        unicode: "\u{2702}",
        hex: "U+2702",
        htmlEntity: "&#x2702;",
        cssEscape: "\\2702"
    },
    {
        name: "Paperclip",
        symbol: "📎",
        unicode: "\u{1F4CE}",
        hex: "U+1F4CE",
        htmlEntity: "&#x1F4CE;",
        cssEscape: "\\1F4CE"
    },
    {
        name: "Pin",
        symbol: "📌",
        unicode: "\u{1F4CC}",
        hex: "U+1F4CC",
        htmlEntity: "&#x1F4CC;",
        cssEscape: "\\1F4CC"
    },
    {
        name: "Tag",
        symbol: "🏷",
        unicode: "\u{1F3F7}",
        hex: "U+1F3F7",
        htmlEntity: "&#x1F3F7;",
        cssEscape: "\\1F3F7"
    },
    {
        name: "File Folder",
        symbol: "📁",
        unicode: "\u{1F4C1}",
        hex: "U+1F4C1",
        htmlEntity: "&#x1F4C1;",
        cssEscape: "\\1F4C1"
    },
    {
        name: "Open Folder",
        symbol: "📂",
        unicode: "\u{1F4C2}",
        hex: "U+1F4C2",
        htmlEntity: "&#x1F4C2;",
        cssEscape: "\\1F4C2"
    },
    {
        name: "File Document",
        symbol: "📄",
        unicode: "\u{1F4C4}",
        hex: "U+1F4C4",
        htmlEntity: "&#x1F4C4;",
        cssEscape: "\\1F4C4"
    },
    {
        name: "Page with Curl",
        symbol: "📃",
        unicode: "\u{1F4C3}",
        hex: "U+1F4C3",
        htmlEntity: "&#x1F4C3;",
        cssEscape: "\\1F4C3"
    },
    {
        name: "Clipboard with Paper",
        symbol: "📋",
        unicode: "\u{1F4CB}",
        hex: "U+1F4CB",
        htmlEntity: "&#x1F4CB;",
        cssEscape: "\\1F4CB"
    },
    {
        name: "Calendar",
        symbol: "📅",
        unicode: "\u{1F4C5}",
        hex: "U+1F4C5",
        htmlEntity: "&#x1F4C5;",
        cssEscape: "\\1F4C5"
    },
    {
        name: "Clipboard with Check",
        symbol: "📋",
        unicode: "\u{1F5D1}",
        hex: "U+1F5D1",
        htmlEntity: "&#x1F5D1;",
        cssEscape: "\\1F5D1"
    },
    {
        name: "Pushpin",
        symbol: "📌",
        unicode: "\u{1F4CC}",
        hex: "U+1F4CC",
        htmlEntity: "&#x1F4CC;",
        cssEscape: "\\1F4CC"
    },
    {
        name: "Paperclips",
        symbol: "🖇",
        unicode: "\u{1F587}",
        hex: "U+1F587",
        htmlEntity: "&#x1F587;",
        cssEscape: "\\1F587"
    },
    {
        name: "Envelope",
        symbol: "✉",
        unicode: "\u{2709}",
        hex: "U+2709",
        htmlEntity: "&#x2709;",
        cssEscape: "\\2709"
    },
    {
        name: "Incoming Envelope",
        symbol: "📨",
        unicode: "\u{1F4E8}",
        hex: "U+1F4E8",
        htmlEntity: "&#x1F4E8;",
        cssEscape: "\\1F4E8"
    },
    {
        name: "Outgoing Envelope",
        symbol: "📤",
        unicode: "\u{1F4E4}",
        hex: "U+1F4E4",
        htmlEntity: "&#x1F4E4;",
        cssEscape: "\\1F4E4"
    },
    {
        name: "Email",
        symbol: "📧",
        unicode: "\u{1F4E7}",
        hex: "U+1F4E7",
        htmlEntity: "&#x1F4E7;",
        cssEscape: "\\1F4E7"
    },
    {
        name: "Mailbox",
        symbol: "📫",
        unicode: "\u{1F4EB}",
        hex: "U+1F4EB",
        htmlEntity: "&#x1F4EB;",
        cssEscape: "\\1F4EB"
    },
    {
        name: "Closed Mailbox with Raised Flag",
        symbol: "📬",
        unicode: "\u{1F4EC}",
        hex: "U+1F4EC",
        htmlEntity: "&#x1F4EC;",
        cssEscape: "\\1F4EC"
    },
    {
        name: "Closed Mailbox with Lowered Flag",
        symbol: "📪",
        unicode: "\u{1F4EA}",
        hex: "U+1F4EA",
        htmlEntity: "&#x1F4EA;",
        cssEscape: "\\1F4EA"
    },
    {
        name: "Open Mailbox with Raised Flag",
        symbol: "📭",
        unicode: "\u{1F4ED}",
        hex: "U+1F4ED",
        htmlEntity: "&#x1F4ED;",
        cssEscape: "\\1F4ED"
    },
    {
        name: "Open Mailbox with Lowered Flag",
        symbol: "📬",
        unicode: "\u{1F4EC}",
        hex: "U+1F4EC",
        htmlEntity: "&#x1F4EC;",
        cssEscape: "\\1F4EC"
    },
    {
        name: "Package",
        symbol: "📦",
        unicode: "\u{1F4E6}",
        hex: "U+1F4E6",
        htmlEntity: "&#x1F4E6;",
        cssEscape: "\\1F4E6"
    },
    {
        name: "Round Pushpin",
        symbol: "📍",
        unicode: "\u{1F4CD}",
        hex: "U+1F4CD",
        htmlEntity: "&#x1F4CD;",
        cssEscape: "\\1F4CD"
    },
    {
        name: "Lock",
        symbol: "🔒",
        unicode: "\u{1F512}",
        hex: "U+1F512",
        htmlEntity: "&#x1F512;",
        cssEscape: "\\1F512"
    },
    {
        name: "Unlock",
        symbol: "🔓",
        unicode: "\u{1F513}",
        hex: "U+1F513",
        htmlEntity: "&#x1F513;",
        cssEscape: "\\1F513"
    },
    {
        name: "Lock with Pen",
        symbol: "🔏",
        unicode: "\u{1F50F}",
        hex: "U+1F50F",
        htmlEntity: "&#x1F50F;",
        cssEscape: "\\1F50F"
    },
    {
        name: "Key",
        symbol: "🔑",
        unicode: "\u{1F511}",
        hex: "U+1F511",
        htmlEntity: "&#x1F511;",
        cssEscape: "\\1F511"
    },
    {
        name: "Old Key",
        symbol: "🗝",
        unicode: "\u{1F5DD}",
        hex: "U+1F5DD",
        htmlEntity: "&#x1F5DD;",
        cssEscape: "\\1F5DD"
    },
    {
        name: "Hammer",
        symbol: "🔨",
        unicode: "\u{1F528}",
        hex: "U+1F528",
        htmlEntity: "&#x1F528;",
        cssEscape: "\\1F528"
    },
    {
        name: "Wrench",
        symbol: "🔧",
        unicode: "\u{1F527}",
        hex: "U+1F527",
        htmlEntity: "&#x1F527;",
        cssEscape: "\\1F527"
    },
    {
        name: "Nut and Bolt",
        symbol: "🔩",
        unicode: "\u{1F529}",
        hex: "U+1F529",
        htmlEntity: "&#x1F529;",
        cssEscape: "\\1F529"
    },
    {
        name: "Magnifying Glass",
        symbol: "🔍",
        unicode: "\u{1F50D}",
        hex: "U+1F50D",
        htmlEntity: "&#x1F50D;",
        cssEscape: "\\1F50D"
    },
    {
        name: "Search",
        symbol: "🔎",
        unicode: "\u{1F50E}",
        hex: "U+1F50E",
        htmlEntity: "&#x1F50E;",
        cssEscape: "\\1F50E"
    },
    {
        name: "Dashboard",
        symbol: "📊",
        unicode: "\u{1F4CA}",
        hex: "U+1F4CA",
        htmlEntity: "&#x1F4CA;",
        cssEscape: "\\1F4CA"
    },
    {
        name: "Chart Increasing",
        symbol: "📈",
        unicode: "\u{1F4C8}",
        hex: "U+1F4C8",
        htmlEntity: "&#x1F4C8;",
        cssEscape: "\\1F4C8"
    },
    {
        name: "Chart Decreasing",
        symbol: "📉",
        unicode: "\u{1F4C9}",
        hex: "U+1F4C9",
        htmlEntity: "&#x1F4C9;",
        cssEscape: "\\1F4C9"
    },
    {
        name: "Bar Chart",
        symbol: "📊",
        unicode: "\u{1F4CA}",
        hex: "U+1F4CA",
        htmlEntity: "&#x1F4CA;",
        cssEscape: "\\1F4CA"
    },
    {
        name: "Line Chart",
        symbol: "📈",
        unicode: "\u{1F4C8}",
        hex: "U+1F4C8",
        htmlEntity: "&#x1F4C8;",
        cssEscape: "\\1F4C8"
    },
    {
        name: "Pie Chart",
        symbol: "📉",
        unicode: "\u{1F4C9}",
        hex: "U+1F4C9",
        htmlEntity: "&#x1F4C9;",
        cssEscape: "\\1F4C9"
    },
    {
        name: "Grid",
        symbol: "🔳",
        unicode: "\u{1F533}",
        hex: "U+1F533",
        htmlEntity: "&#x1F533;",
        cssEscape: "\\1F533"
    },
    {
        name: "Empty Square",
        symbol: "⬜",
        unicode: "\u{2B1C}",
        hex: "U+2B1C",
        htmlEntity: "&#x2B1C;",
        cssEscape: "\\2B1C"
    },
    {
        name: "Checked Box",
        symbol: "☑",
        unicode: "\u{2611}",
        hex: "U+2611",
        htmlEntity: "&#x2611;",
        cssEscape: "\\2611"
    },
    {
        name: "Checkmark",
        symbol: "✔",
        unicode: "\u{2714}",
        hex: "U+2714",
        htmlEntity: "&#x2714;",
        cssEscape: "\\2714"
    },
    {
        name: "Crossmark",
        symbol: "❌",
        unicode: "\u{274C}",
        hex: "U+274C",
        htmlEntity: "&#x274C;",
        cssEscape: "\\274C"
    },
    {
        name: "X Mark",
        symbol: "❌",
        unicode: "\u{2716}",
        hex: "U+2716",
        htmlEntity: "&#x2716;",
        cssEscape: "\\2716"
    },
    {
        name: "Star",
        symbol: "⭐",
        unicode: "\u{2B50}",
        hex: "U+2B50",
        htmlEntity: "&#x2B50;",
        cssEscape: "\\2B50"
    },
    {
        name: "Star Outline",
        symbol: "⭐",
        unicode: "\u{2606}",
        hex: "U+2606",
        htmlEntity: "&#x2606;",
        cssEscape: "\\2606"
    },
    {
        name: "Favorite",
        symbol: "❤",
        unicode: "\u{2764}",
        hex: "U+2764",
        htmlEntity: "&#x2764;",
        cssEscape: "\\2764"
    },
    {
        name: "Heart",
        symbol: "❤",
        unicode: "\u{2665}",
        hex: "U+2665",
        htmlEntity: "&#x2665;",
        cssEscape: "\\2665"
    },
    {
        name: "Calendar",
        symbol: "📅",
        unicode: "\u{1F4C5}",
        hex: "U+1F4C5",
        htmlEntity: "&#x1F4C5;",
        cssEscape: "\\1F4C5"
    },
    {
        name: "Calendar Pages",
        symbol: "📆",
        unicode: "\u{1F4C6}",
        hex: "U+1F4C6",
        htmlEntity: "&#x1F4C6;",
        cssEscape: "\\1F4C6"
    },
    {
        name: "Clock",
        symbol: "🕒",
        unicode: "\u{1F552}",
        hex: "U+1F552",
        htmlEntity: "&#x1F552;",
        cssEscape: "\\1F552"
    },
    {
        name: "Timer Clock",
        symbol: "⏲",
        unicode: "\u{23F2}",
        hex: "U+23F2",
        htmlEntity: "&#x23F2;",
        cssEscape: "\\23F2"
    },
    {
        name: "Hourglass",
        symbol: "⏳",
        unicode: "\u{23F3}",
        hex: "U+23F3",
        htmlEntity: "&#x23F3;",
        cssEscape: "\\23F3"
    },
    {
        name: "Alarm Clock",
        symbol: "⏰",
        unicode: "\u{23F0}",
        hex: "U+23F0",
        htmlEntity: "&#x23F0;",
        cssEscape: "\\23F0"
    },
    {
        name: "Stopwatch",
        symbol: "⏱",
        unicode: "\u{23F1}",
        hex: "U+23F1",
        htmlEntity: "&#x23F1;",
        cssEscape: "\\23F1"
    },
    {
        name: "Bell",
        symbol: "🔔",
        unicode: "\u{1F514}",
        hex: "U+1F514",
        htmlEntity: "&#x1F514;",
        cssEscape: "\\1F514"
    },
    {
        name: "Notification Bell",
        symbol: "🔔",
        unicode: "\u{1F6CE}",
        hex: "U+1F6CE",
        htmlEntity: "&#x1F6CE;",
        cssEscape: "\\1F6CE"
    },
    {
        name: "Announcement",
        symbol: "📢",
        unicode: "\u{1F4E2}",
        hex: "U+1F4E2",
        htmlEntity: "&#x1F4E2;",
        cssEscape: "\\1F4E2"
    },
    {
        name: "Megaphone",
        symbol: "📣",
        unicode: "\u{1F4E3}",
        hex: "U+1F4E3",
        htmlEntity: "&#x1F4E3;",
        cssEscape: "\\1F4E3"
    },
    {
        name: "Satellite Antenna",
        symbol: "📡",
        unicode: "\u{1F4E1}",
        hex: "U+1F4E1",
        htmlEntity: "&#x1F4E1;",
        cssEscape: "\\1F4E1"
    },
    {
        name: "Broadcast Tower",
        symbol: "📡",
        unicode: "\u{1F5FC}",
        hex: "U+1F5FC",
        htmlEntity: "&#x1F5FC;",
        cssEscape: "\\1F5FC"
    },
    {
        name: "Movie Camera",
        symbol: "🎥",
        unicode: "\u{1F3A5}",
        hex: "U+1F3A5",
        htmlEntity: "&#x1F3A5;",
        cssEscape: "\\1F3A5"
    },
    {
        name: "Clapper Board",
        symbol: "🎬",
        unicode: "\u{1F3AC}",
        hex: "U+1F3AC",
        htmlEntity: "&#x1F3AC;",
        cssEscape: "\\1F3AC"
    },
    {
        name: "Microphone",
        symbol: "🎤",
        unicode: "\u{1F3A4}",
        hex: "U+1F3A4",
        htmlEntity: "&#x1F3A4;",
        cssEscape: "\\1F3A4"
    },
    {
        name: "Headphone",
        symbol: "🎧",
        unicode: "\u{1F3A7}",
        hex: "U+1F3A7",
        htmlEntity: "&#x1F3A7;",
        cssEscape: "\\1F3A7"
    },
    {
        name: "Radio",
        symbol: "📻",
        unicode: "\u{1F4FB}",
        hex: "U+1F4FB",
        htmlEntity: "&#x1F4FB;",
        cssEscape: "\\1F4FB"
    },
    {
        name: "Film Reel",
        symbol: "🎞",
        unicode: "\u{1F39E}",
        hex: "U+1F39E",
        htmlEntity: "&#x1F39E;",
        cssEscape: "\\1F39E"
    },
    {
        name: "Camera",
        symbol: "📷",
        unicode: "\u{1F4F7}",
        hex: "U+1F4F7",
        htmlEntity: "&#x1F4F7;",
        cssEscape: "\\1F4F7"
    }
]

export const uni_arrow = [
    {
        symbol: "➤",
        unicode: "\u{27A4}",
        hex: "U+27A4",
        htmlEntity: "&#x27A4;",
        cssEscape: "\\27A4"
    },
    {
        symbol: "⤷",
        unicode: "\u{2937}",
        hex: "U+2937",
        htmlEntity: "&#x2937;",
        cssEscape: "\\2937"
    },
    {
        symbol: "⇛",
        unicode: "\u{21DB}",
        hex: "U+21DB",
        htmlEntity: "&#x21DB;",
        cssEscape: "\\21DB"
    },
    {
        symbol: "↞",
        unicode: "\u{219E}",
        hex: "U+219E",
        htmlEntity: "&#x219E;",
        cssEscape: "\\219E"
    },
    {
        symbol: "↟",
        unicode: "\u{219F}",
        hex: "U+219F",
        htmlEntity: "&#x219F;",
        cssEscape: "\\219F"
    },
    {
        symbol: "↠",
        unicode: "\u{21A0}",
        hex: "U+21A0",
        htmlEntity: "&#x21A0;",
        cssEscape: "\\21A0"
    },
    {
        symbol: "↡",
        unicode: "\u{21A1}",
        hex: "U+21A1",
        htmlEntity: "&#x21A1;",
        cssEscape: "\\21A1"
    },
    {
        symbol: "↢",
        unicode: "\u{21A2}",
        hex: "U+21A2",
        htmlEntity: "&#x21A2;",
        cssEscape: "\\21A2"
    },
    {
        symbol: "↣",
        unicode: "\u{21A3}",
        hex: "U+21A3",
        htmlEntity: "&#x21A3;",
        cssEscape: "\\21A3"
    },
    {
        symbol: "↤",
        unicode: "\u{21A4}",
        hex: "U+21A4",
        htmlEntity: "&#x21A4;",
        cssEscape: "\\21A4"
    },
    {
        symbol: "↥",
        unicode: "\u{21A5}",
        hex: "U+21A5",
        htmlEntity: "&#x21A5;",
        cssEscape: "\\21A5"
    },
    {
        symbol: "↦",
        unicode: "\u{21A6}",
        hex: "U+21A6",
        htmlEntity: "&#x21A6;",
        cssEscape: "\\21A6"
    },
    {
        symbol: "↧",
        unicode: "\u{21A7}",
        hex: "U+21A7",
        htmlEntity: "&#x21A7;",
        cssEscape: "\\21A7"
    },
    {
        symbol: "↨",
        unicode: "\u{21A8}",
        hex: "U+21A8",
        htmlEntity: "&#x21A8;",
        cssEscape: "\\21A8"
    },
    {
        symbol: "↩",
        unicode: "\u{21A9}",
        hex: "U+21A9",
        htmlEntity: "&#x21A9;",
        cssEscape: "\\21A9"
    },
    {
        symbol: "↪",
        unicode: "\u{21AA}",
        hex: "U+21AA",
        htmlEntity: "&#x21AA;",
        cssEscape: "\\21AA"
    },
    {
        symbol: "↫",
        unicode: "\u{21AB}",
        hex: "U+21AB",
        htmlEntity: "&#x21AB;",
        cssEscape: "\\21AB"
    },
    {
        symbol: "↬",
        unicode: "\u{21AC}",
        hex: "U+21AC",
        htmlEntity: "&#x21AC;",
        cssEscape: "\\21AC"
    },
    {
        symbol: "↭",
        unicode: "\u{21AD}",
        hex: "U+21AD",
        htmlEntity: "&#x21AD;",
        cssEscape: "\\21AD"
    },
    {
        symbol: "↮",
        unicode: "\u{21AE}",
        hex: "U+21AE",
        htmlEntity: "&#x21AE;",
        cssEscape: "\\21AE"
    },
    {
        symbol: "↯",
        unicode: "\u{21AF}",
        hex: "U+21AF",
        htmlEntity: "&#x21AF;",
        cssEscape: "\\21AF"
    },
    {
        symbol: "⬓",
        unicode: "\u{2B53}",
        hex: "U+2B53",
        htmlEntity: "&#x2B53;",
        cssEscape: "\\2B53"
    },
    {
        symbol: "⭅",
        unicode: "\u{2B85}",
        hex: "U+2B85",
        htmlEntity: "&#x2B85;",
        cssEscape: "\\2B85"
    },
    {
        symbol: "⭆",
        unicode: "\u{2B86}",
        hex: "U+2B86",
        htmlEntity: "&#x2B86;",
        cssEscape: "\\2B86"
    },
    {
        symbol: "⮍",
        unicode: "\u{2B8D}",
        hex: "U+2B8D",
        htmlEntity: "&#x2B8D;",
        cssEscape: "\\2B8D"
    },
    {
        symbol: "➡",
        unicode: "\u{2192}",
        hex: "U+2192",
        htmlEntity: "&#x2192;",
        cssEscape: "\\2192"
    },
    {
        symbol: "⬅",
        unicode: "\u{2190}",
        hex: "U+2190",
        htmlEntity: "&#x2190;",
        cssEscape: "\\2190"
    },
    {
        symbol: "⬆",
        unicode: "\u{2191}",
        hex: "U+2191",
        htmlEntity: "&#x2191;",
        cssEscape: "\\2191"
    },
    {
        symbol: "⬇",
        unicode: "\u{2193}",
        hex: "U+2193",
        htmlEntity: "&#x2193;",
        cssEscape: "\\2193"
    },
    {
        symbol: "↔",
        unicode: "\u{2194}",
        hex: "U+2194",
        htmlEntity: "&#x2194;",
        cssEscape: "\\2194"
    },
    {
        symbol: "↕",
        unicode: "\u{2195}",
        hex: "U+2195",
        htmlEntity: "&#x2195;",
        cssEscape: "\\2195"
    },
    {
        symbol: "⇄",
        unicode: "\u{21C4}",
        hex: "U+21C4",
        htmlEntity: "&#x21C4;",
        cssEscape: "\\21C4"
    },
    {
        symbol: "⇅",
        unicode: "\u{21C5}",
        hex: "U+21C5",
        htmlEntity: "&#x21C5;",
        cssEscape: "\\21C5"
    },
    {
        symbol: "⇆",
        unicode: "\u{21C6}",
        hex: "U+21C6",
        htmlEntity: "&#x21C6;",
        cssEscape: "\\21C6"
    },
    {
        symbol: "⇩",
        unicode: "\u{21E9}",
        hex: "U+21E9",
        htmlEntity: "&#x21E9;",
        cssEscape: "\\21E9"
    },
    {
        symbol: "⇪",
        unicode: "\u{21EA}",
        hex: "U+21EA",
        htmlEntity: "&#x21EA;",
        cssEscape: "\\21EA"
    },
    {
        symbol: "↖",
        unicode: "\u{2196}",
        hex: "U+2196",
        htmlEntity: "&#x2196;",
        cssEscape: "\\2196"
    },
    {
        symbol: "↗",
        unicode: "\u{2197}",
        hex: "U+2197",
        htmlEntity: "&#x2197;",
        cssEscape: "\\2197"
    },
    {
        symbol: "↘",
        unicode: "\u{2198}",
        hex: "U+2198",
        htmlEntity: "&#x2198;",
        cssEscape: "\\2198"
    },
    {
        symbol: "↙",
        unicode: "\u{2199}",
        hex: "U+2199",
        htmlEntity: "&#x2199;",
        cssEscape: "\\2199"
    },
    {
        symbol: "⤴",
        unicode: "\u{2934}",
        hex: "U+2934",
        htmlEntity: "&#x2934;",
        cssEscape: "\\2934"
    },
    {
        symbol: "⤵",
        unicode: "\u{2935}",
        hex: "U+2935",
        htmlEntity: "&#x2935;",
        cssEscape: "\\2935"
    },
    {
        symbol: "⬈",
        unicode: "\u{2196}",
        hex: "U+2196",
        htmlEntity: "&#x2196;",
        cssEscape: "\\2196"
    },
    {
        symbol: "⬉",
        unicode: "\u{2191}",
        hex: "U+2191",
        htmlEntity: "&#x2191;",
        cssEscape: "\\2191"
    },
    {
        symbol: "⬊",
        unicode: "\u{2193}",
        hex: "U+2193",
        htmlEntity: "&#x2193;",
        cssEscape: "\\2193"
    },
    {
        symbol: "⬋",
        unicode: "\u{2198}",
        hex: "U+2198",
        htmlEntity: "&#x2198;",
        cssEscape: "\\2198"
    },
    {
        symbol: "⬏",
        unicode: "\u{2190}",
        hex: "U+2190",
        htmlEntity: "&#x2190;",
        cssEscape: "\\2190"
    },
    {
        symbol: "⬐",
        unicode: "\u{2193}",
        hex: "U+2193",
        htmlEntity: "&#x2193;",
        cssEscape: "\\2193"
    },
    {
        symbol: "⬑",
        unicode: "\u{2191}",
        hex: "U+2191",
        htmlEntity: "&#x2191;",
        cssEscape: "\\2191"
    },
    {
        symbol: "⬒",
        unicode: "\u{2190}",
        hex: "U+2190",
        htmlEntity: "&#x2190;",
        cssEscape: "\\2190"
    }
]

export const uni_currency = [
    {
        name: "US Dollar",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Euro",
        symbol: "€",
        unicode: "\u{20AC}",
        hex: "U+20AC",
        htmlEntity: "&#x20AC;",
        cssEscape: "\\20AC"
    },
    {
        name: "Japanese Yen",
        symbol: "¥",
        unicode: "\u{00A5}",
        hex: "U+00A5",
        htmlEntity: "&#x00A5;",
        cssEscape: "\\00A5"
    },
    {
        name: "British Pound",
        symbol: "£",
        unicode: "\u{00A3}",
        hex: "U+00A3",
        htmlEntity: "&#x00A3;",
        cssEscape: "\\00A3"
    },
    {
        name: "Canadian Dollar",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Australian Dollar",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Swiss Franc",
        symbol: "Fr",
        unicode: "\u{20A3}",
        hex: "U+20A3",
        htmlEntity: "&#x20A3;",
        cssEscape: "\\20A3"
    },
    {
        name: "Chinese Yuan",
        symbol: "¥",
        unicode: "\u{00A5}",
        hex: "U+00A5",
        htmlEntity: "&#x00A5;",
        cssEscape: "\\00A5"
    },
    {
        name: "Indian Rupee",
        symbol: "₹",
        unicode: "\u{20B9}",
        hex: "U+20B9",
        htmlEntity: "&#x20B9;",
        cssEscape: "\\20B9"
    },
    {
        name: "Brazilian Real",
        symbol: "R$",
        unicode: "\u{20A2}",
        hex: "U+20A2",
        htmlEntity: "&#x20A2;",
        cssEscape: "\\20A2"
    },
    {
        name: "South Korean Won",
        symbol: "₩",
        unicode: "\u{20A9}",
        hex: "U+20A9",
        htmlEntity: "&#x20A9;",
        cssEscape: "\\20A9"
    },
    {
        name: "Russian Ruble",
        symbol: "₽",
        unicode: "\u{20BD}",
        hex: "U+20BD",
        htmlEntity: "&#x20BD;",
        cssEscape: "\\20BD"
    },
    {
        name: "Mexican Peso",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "South African Rand",
        symbol: "R",
        unicode: "\u{0052}",
        hex: "U+0052",
        htmlEntity: "&#x0052;",
        cssEscape: "\\0052"
    },
    {
        name: "Turkish Lira",
        symbol: "₺",
        unicode: "\u{20BA}",
        hex: "U+20BA",
        htmlEntity: "&#x20BA;",
        cssEscape: "\\20BA"
    },
    {
        name: "Hong Kong Dollar",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Indonesian Rupiah",
        symbol: "Rp",
        unicode: "\u{20A8}",
        hex: "U+20A8",
        htmlEntity: "&#x20A8;",
        cssEscape: "\\20A8"
    },
    {
        name: "Canadian Dollar",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Israeli New Shekel",
        symbol: "₪",
        unicode: "\u{20AA}",
        hex: "U+20AA",
        htmlEntity: "&#x20AA;",
        cssEscape: "\\20AA"
    },
    {
        name: "Singapore Dollar",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "New Zealand Dollar",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Hungarian Forint",
        symbol: "Ft",
        unicode: "\u{20B8}",
        hex: "U+20B8",
        htmlEntity: "&#x20B8;",
        cssEscape: "\\20B8"
    },
    {
        name: "Swedish Krona",
        symbol: "kr",
        unicode: "\u{20AC}",
        hex: "U+20AC",
        htmlEntity: "&#x20AC;",
        cssEscape: "\\20AC"
    },
    {
        name: "Polish Złoty",
        symbol: "zł",
        unicode: "\u{007A}\u{0142}",
        hex: "U+007A U+0142",
        htmlEntity: "&#x007A;&#x0142;",
        cssEscape: "\\007A\\0142"
    },
    {
        name: "Norwegian Krone",
        symbol: "kr",
        unicode: "\u{20AC}",
        hex: "U+20AC",
        htmlEntity: "&#x20AC;",
        cssEscape: "\\20AC"
    },
    {
        name: "Czech Koruna",
        symbol: "Kč",
        unicode: "\u{004B}\u{010D}",
        hex: "U+004B U+010D",
        htmlEntity: "&#x004B;&#x010D;",
        cssEscape: "\\004B\\010D"
    },
    {
        name: "Danish Krone",
        symbol: "kr",
        unicode: "\u{20AC}",
        hex: "U+20AC",
        htmlEntity: "&#x20AC;",
        cssEscape: "\\20AC"
    },
    {
        name: "Icelandic Króna",
        symbol: "kr",
        unicode: "\u{20AC}",
        hex: "U+20AC",
        htmlEntity: "&#x20AC;",
        cssEscape: "\\20AC"
    },
    {
        name: "Romanian Leu",
        symbol: "lei",
        unicode: "\u{006C}\u{0065}\u{0069}",
        hex: "U+006C U+0065 U+0069",
        htmlEntity: "&#x006C;&#x0065;&#x0069;",
        cssEscape: "\\006C\\0065\\0069"
    },
    {
        name: "Philippine Peso",
        symbol: "₱",
        unicode: "\u{20B1}",
        hex: "U+20B1",
        htmlEntity: "&#x20B1;",
        cssEscape: "\\20B1"
    },
    {
        name: "Thai Baht",
        symbol: "฿",
        unicode: "\u{0E3F}",
        hex: "U+0E3F",
        htmlEntity: "&#x0E3F;",
        cssEscape: "\\0E3F"
    },
    {
        name: "Israeli New Shekel",
        symbol: "₪",
        unicode: "\u{20AA}",
        hex: "U+20AA",
        htmlEntity: "&#x20AA;",
        cssEscape: "\\20AA"
    },
    {
        name: "Argentine Peso",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Peruvian Sol",
        symbol: "S/",
        unicode: "\u{0053}\u{002F}",
        hex: "U+0053 U+002F",
        htmlEntity: "&#x0053;&#x002F;",
        cssEscape: "\\0053\\002F"
    },
    {
        name: "Chilean Peso",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Colombian Peso",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Malaysian Ringgit",
        symbol: "RM",
        unicode: "\u{0052}\u{004D}",
        hex: "U+0052 U+004D",
        htmlEntity: "&#x0052;&#x004D;",
        cssEscape: "\\0052\\004D"
    },
    {
        name: "Bulgarian Lev",
        symbol: "лв",
        unicode: "\u{043B}\u{0432}",
        hex: "U+043B U+0432",
        htmlEntity: "&#x043B;&#x0432;",
        cssEscape: "\\043B\\0432"
    },
    {
        name: "Russian Ruble",
        symbol: "₽",
        unicode: "\u{20BD}",
        hex: "U+20BD",
        htmlEntity: "&#x20BD;",
        cssEscape: "\\20BD"
    },
    {
        name: "Croatian Kuna",
        symbol: "kn",
        unicode: "\u{006B}\u{006E}",
        hex: "U+006B U+006E",
        htmlEntity: "&#x006B;&#x006E;",
        cssEscape: "\\006B\\006E"
    }
]

export const uni_punctuation = [
    {
        name: "Exclamation Mark",
        symbol: "!",
        unicode: "\u{0021}",
        hex: "U+0021",
        htmlEntity: "&#x0021;",
        cssEscape: "\\0021"
    },
    {
        name: "Quotation Mark",
        symbol: "\"",
        unicode: "\u{0022}",
        hex: "U+0022",
        htmlEntity: "&#x0022;",
        cssEscape: "\\0022"
    },
    {
        name: "Number Sign",
        symbol: "#",
        unicode: "\u{0023}",
        hex: "U+0023",
        htmlEntity: "&#x0023;",
        cssEscape: "\\0023"
    },
    {
        name: "Dollar Sign",
        symbol: "$",
        unicode: "\u{0024}",
        hex: "U+0024",
        htmlEntity: "&#x0024;",
        cssEscape: "\\0024"
    },
    {
        name: "Percent Sign",
        symbol: "%",
        unicode: "\u{0025}",
        hex: "U+0025",
        htmlEntity: "&#x0025;",
        cssEscape: "\\0025"
    },
    {
        name: "Ampersand",
        symbol: "&",
        unicode: "\u{0026}",
        hex: "U+0026",
        htmlEntity: "&#x0026;",
        cssEscape: "\\0026"
    },
    {
        name: "Apostrophe",
        symbol: "'",
        unicode: "\u{0027}",
        hex: "U+0027",
        htmlEntity: "&#x0027;",
        cssEscape: "\\0027"
    },
    {
        name: "Left Parenthesis",
        symbol: "(",
        unicode: "\u{0028}",
        hex: "U+0028",
        htmlEntity: "&#x0028;",
        cssEscape: "\\0028"
    },
    {
        name: "Right Parenthesis",
        symbol: ")",
        unicode: "\u{0029}",
        hex: "U+0029",
        htmlEntity: "&#x0029;",
        cssEscape: "\\0029"
    },
    {
        name: "Asterisk",
        symbol: "*",
        unicode: "\u{002A}",
        hex: "U+002A",
        htmlEntity: "&#x002A;",
        cssEscape: "\\002A"
    },
    {
        name: "Plus Sign",
        symbol: "+",
        unicode: "\u{002B}",
        hex: "U+002B",
        htmlEntity: "&#x002B;",
        cssEscape: "\\002B"
    },
    {
        name: "Comma",
        symbol: ",",
        unicode: "\u{002C}",
        hex: "U+002C",
        htmlEntity: "&#x002C;",
        cssEscape: "\\002C"
    },
    {
        name: "Hyphen-Minus",
        symbol: "-",
        unicode: "\u{002D}",
        hex: "U+002D",
        htmlEntity: "&#x002D;",
        cssEscape: "\\002D"
    },
    {
        name: "Full Stop",
        symbol: ".",
        unicode: "\u{002E}",
        hex: "U+002E",
        htmlEntity: "&#x002E;",
        cssEscape: "\\002E"
    },
    {
        name: "Solidus (Slash)",
        symbol: "/",
        unicode: "\u{002F}",
        hex: "U+002F",
        htmlEntity: "&#x002F;",
        cssEscape: "\\002F"
    },
    {
        name: "Colon",
        symbol: ":",
        unicode: "\u{003A}",
        hex: "U+003A",
        htmlEntity: "&#x003A;",
        cssEscape: "\\003A"
    },
    {
        name: "Semicolon",
        symbol: ";",
        unicode: "\u{003B}",
        hex: "U+003B",
        htmlEntity: "&#x003B;",
        cssEscape: "\\003B"
    },
    {
        name: "Less-Than Sign",
        symbol: "<",
        unicode: "\u{003C}",
        hex: "U+003C",
        htmlEntity: "&#x003C;",
        cssEscape: "\\003C"
    },
    {
        name: "Equals Sign",
        symbol: "=",
        unicode: "\u{003D}",
        hex: "U+003D",
        htmlEntity: "&#x003D;",
        cssEscape: "\\003D"
    },
    {
        name: "Greater-Than Sign",
        symbol: ">",
        unicode: "\u{003E}",
        hex: "U+003E",
        htmlEntity: "&#x003E;",
        cssEscape: "\\003E"
    },
    {
        name: "Question Mark",
        symbol: "?",
        unicode: "\u{003F}",
        hex: "U+003F",
        htmlEntity: "&#x003F;",
        cssEscape: "\\003F"
    },
    {
        name: "Commercial At",
        symbol: "@",
        unicode: "\u{0040}",
        hex: "U+0040",
        htmlEntity: "&#x0040;",
        cssEscape: "\\0040"
    },
    {
        name: "Left Square Bracket",
        symbol: "[",
        unicode: "\u{005B}",
        hex: "U+005B",
        htmlEntity: "&#x005B;",
        cssEscape: "\\005B"
    },
    {
        name: "Reverse Solidus (Backslash)",
        symbol: "\\",
        unicode: "\u{005C}",
        hex: "U+005C",
        htmlEntity: "&#x005C;",
        cssEscape: "\\005C"
    },
    {
        name: "Right Square Bracket",
        symbol: "]",
        unicode: "\u{005D}",
        hex: "U+005D",
        htmlEntity: "&#x005D;",
        cssEscape: "\\005D"
    },
    {
        name: "Circumflex Accent (Caret)",
        symbol: "^",
        unicode: "\u{005E}",
        hex: "U+005E",
        htmlEntity: "&#x005E;",
        cssEscape: "\\005E"
    },
    {
        name: "Low Line (Underscore)",
        symbol: "_",
        unicode: "\u{005F}",
        hex: "U+005F",
        htmlEntity: "&#x005F;",
        cssEscape: "\\005F"
    },
    {
        name: "Grave Accent",
        symbol: "`",
        unicode: "\u{0060}",
        hex: "U+0060",
        htmlEntity: "&#x0060;",
        cssEscape: "\\0060"
    },
    {
        name: "Left Curly Bracket",
        symbol: "{",
        unicode: "\u{007B}",
        hex: "U+007B",
        htmlEntity: "&#x007B;",
        cssEscape: "\\007B"
    },
    {
        name: "Vertical Line (Pipe)",
        symbol: "|",
        unicode: "\u{007C}",
        hex: "U+007C",
        htmlEntity: "&#x007C;",
        cssEscape: "\\007C"
    },
    {
        name: "Right Curly Bracket",
        symbol: "}",
        unicode: "\u{007D}",
        hex: "U+007D",
        htmlEntity: "&#x007D;",
        cssEscape: "\\007D"
    },
    {
        name: "Tilde",
        symbol: "~",
        unicode: "\u{007E}",
        hex: "U+007E",
        htmlEntity: "&#x007E;",
        cssEscape: "\\007E"
    },
    {
        name: "Left Single Quotation Mark (‘)",
        symbol: "‘",
        unicode: "\u{2018}",
        hex: "U+2018",
        htmlEntity: "&#x2018;",
        cssEscape: "\\2018"
    },
    {
        name: "Right Single Quotation Mark (’)",
        symbol: "’",
        unicode: "\u{2019}",
        hex: "U+2019",
        htmlEntity: "&#x2019;",
        cssEscape: "\\2019"
    },
    {
        name: "Left Double Quotation Mark (“)",
        symbol: "“",
        unicode: "\u{201C}",
        hex: "U+201C",
        htmlEntity: "&#x201C;",
        cssEscape: "\\201C"
    },
    {
        name: "Right Double Quotation Mark (”)",
        symbol: "”",
        unicode: "\u{201D}",
        hex: "U+201D",
        htmlEntity: "&#x201D;",
        cssEscape: "\\201D"
    },
    {
        name: "Bullet (•)",
        symbol: "•",
        unicode: "\u{2022}",
        hex: "U+2022",
        htmlEntity: "&#x2022;",
        cssEscape: "\\2022"
    },
    {
        name: "En Dash (–)",
        symbol: "–",
        unicode: "\u{2013}",
        hex: "U+2013",
        htmlEntity: "&#x2013;",
        cssEscape: "\\2013"
    },
    {
        name: "Em Dash (—)",
        symbol: "—",
        unicode: "\u{2014}",
        hex: "U+2014",
        htmlEntity: "&#x2014;",
        cssEscape: "\\2014"
    }
]

export const uni_math = [
    {
        name: "Plus-Minus Sign",
        symbol: "±",
        unicode: "\u{00B1}",
        hex: "U+00B1",
        htmlEntity: "&#x00B1;",
        cssEscape: "\\00B1"
    },
    {
        name: "Division Sign",
        symbol: "÷",
        unicode: "\u{00F7}",
        hex: "U+00F7",
        htmlEntity: "&#x00F7;",
        cssEscape: "\\00F7"
    },
    {
        name: "Degree Sign",
        symbol: "°",
        unicode: "\u{00B0}",
        hex: "U+00B0",
        htmlEntity: "&#x00B0;",
        cssEscape: "\\00B0"
    },
    {
        name: "Infinity",
        symbol: "∞",
        unicode: "\u{221E}",
        hex: "U+221E",
        htmlEntity: "&#x221E;",
        cssEscape: "\\221E"
    },
    {
        name: "Square Root",
        symbol: "√",
        unicode: "\u{221A}",
        hex: "U+221A",
        htmlEntity: "&#x221A;",
        cssEscape: "\\221A"
    },
    {
        name: "Less-Than or Equal To",
        symbol: "≤",
        unicode: "\u{2264}",
        hex: "U+2264",
        htmlEntity: "&#x2264;",
        cssEscape: "\\2264"
    },
    {
        name: "Greater-Than or Equal To",
        symbol: "≥",
        unicode: "\u{2265}",
        hex: "U+2265",
        htmlEntity: "&#x2265;",
        cssEscape: "\\2265"
    },
    {
        name: "Not Equal To",
        symbol: "≠",
        unicode: "\u{2260}",
        hex: "U+2260",
        htmlEntity: "&#x2260;",
        cssEscape: "\\2260"
    },
    {
        name: "Element Of",
        symbol: "∈",
        unicode: "\u{2208}",
        hex: "U+2208",
        htmlEntity: "&#x2208;",
        cssEscape: "\\2208"
    },
    {
        name: "Not an Element Of",
        symbol: "∉",
        unicode: "\u{2209}",
        hex: "U+2209",
        htmlEntity: "&#x2209;",
        cssEscape: "\\2209"
    },
    {
        name: "Subset Of",
        symbol: "⊆",
        unicode: "\u{2286}",
        hex: "U+2286",
        htmlEntity: "&#x2286;",
        cssEscape: "\\2286"
    },
    {
        name: "Superset Of",
        symbol: "⊇",
        unicode: "\u{2287}",
        hex: "U+2287",
        htmlEntity: "&#x2287;",
        cssEscape: "\\2287"
    },
    {
        name: "Union",
        symbol: "∪",
        unicode: "\u{222A}",
        hex: "U+222A",
        htmlEntity: "&#x222A;",
        cssEscape: "\\222A"
    },
    {
        name: "Intersection",
        symbol: "∩",
        unicode: "\u{2229}",
        hex: "U+2229",
        htmlEntity: "&#x2229;",
        cssEscape: "\\2229"
    },
    {
        name: "Integral",
        symbol: "∫",
        unicode: "\u{222B}",
        hex: "U+222B",
        htmlEntity: "&#x222B;",
        cssEscape: "\\222B"
    },
    {
        name: "Summation",
        symbol: "∑",
        unicode: "\u{2211}",
        hex: "U+2211",
        htmlEntity: "&#x2211;",
        cssEscape: "\\2211"
    },
    {
        name: "Proportional To",
        symbol: "∝",
        unicode: "\u{221D}",
        hex: "U+221D",
        htmlEntity: "&#x221D;",
        cssEscape: "\\221D"
    },
    {
        name: "Dot Product",
        symbol: "⋅",
        unicode: "\u{22C5}",
        hex: "U+22C5",
        htmlEntity: "&#x22C5;",
        cssEscape: "\\22C5"
    },
    {
        name: "Degree Celsius",
        symbol: "℃",
        unicode: "\u{2103}",
        hex: "U+2103",
        htmlEntity: "&#x2103;",
        cssEscape: "\\2103"
    },
    {
        name: "Per Mille Sign",
        symbol: "‰",
        unicode: "\u{2030}",
        hex: "U+2030",
        htmlEntity: "&#x2030;",
        cssEscape: "\\2030"
    },
    {
        name: "Per Ten Thousand Sign",
        symbol: "‱",
        unicode: "\u{2031}",
        hex: "U+2031",
        htmlEntity: "&#x2031;",
        cssEscape: "\\2031"
    },
    {
        name: "Partial Differential",
        symbol: "∂",
        unicode: "\u{2202}",
        hex: "U+2202",
        htmlEntity: "&#x2202;",
        cssEscape: "\\2202"
    },
    {
        name: "Nabla (Backward Difference)",
        symbol: "∇",
        unicode: "\u{2207}",
        hex: "U+2207",
        htmlEntity: "&#x2207;",
        cssEscape: "\\2207"
    },
    {
        name: "Prime",
        symbol: "′",
        unicode: "\u{2032}",
        hex: "U+2032",
        htmlEntity: "&#x2032;",
        cssEscape: "\\2032"
    },
    {
        name: "Double Prime",
        symbol: "″",
        unicode: "\u{2033}",
        hex: "U+2033",
        htmlEntity: "&#x2033;",
        cssEscape: "\\2033"
    },
    {
        name: "Tilde (Spacing)",
        symbol: "˜",
        unicode: "\u{02DC}",
        hex: "U+02DC",
        htmlEntity: "&#x02DC;",
        cssEscape: "\\02DC"
    },
    {
        name: "Logical And",
        symbol: "∧",
        unicode: "\u{2227}",
        hex: "U+2227",
        htmlEntity: "&#x2227;",
        cssEscape: "\\2227"
    },
    {
        name: "Logical Or",
        symbol: "∨",
        unicode: "\u{2228}",
        hex: "U+2228",
        htmlEntity: "&#x2228;",
        cssEscape: "\\2228"
    },
    {
        name: "For All",
        symbol: "∀",
        unicode: "\u{2200}",
        hex: "U+2200",
        htmlEntity: "&#x2200;",
        cssEscape: "\\2200"
    },
    {
        name: "Exists",
        symbol: "∃",
        unicode: "\u{2203}",
        hex: "U+2203",
        htmlEntity: "&#x2203;",
        cssEscape: "\\2203"
    },
    {
        name: "Empty Set",
        symbol: "∅",
        unicode: "\u{2205}",
        hex: "U+2205",
        htmlEntity: "&#x2205;",
        cssEscape: "\\2205"
    },
    {
        name: "There Exists",
        symbol: "∃!",
        unicode: "\u{2204}",
        hex: "U+2204",
        htmlEntity: "&#x2204;",
        cssEscape: "\\2204"
    },
    {
        name: "N-ary Product",
        symbol: "∏",
        unicode: "\u{220F}",
        hex: "U+220F",
        htmlEntity: "&#x220F;",
        cssEscape: "\\220F"
    },
    {
        name: "N-ary Coproduct",
        symbol: "∐",
        unicode: "\u{2210}",
        hex: "U+2210",
        htmlEntity: "&#x2210;",
        cssEscape: "\\2210"
    },
    {
        name: "Square Root (Radical)",
        symbol: "√",
        unicode: "\u{221A}",
        hex: "U+221A",
        htmlEntity: "&#x221A;",
        cssEscape: "\\221A"
    },
    {
        name: "Cubed Root (Radical)",
        symbol: "∛",
        unicode: "\u{221B}",
        hex: "U+221B",
        htmlEntity: "&#x221B;",
        cssEscape: "\\221B"
    },
    {
        name: "Fourth Root (Radical)",
        symbol: "∜",
        unicode: "\u{221C}",
        hex: "U+221C",
        htmlEntity: "&#x221C;",
        cssEscape: "\\221C"
    },
    {
        name: "Empty Set (Null Set)",
        symbol: "∅",
        unicode: "\u{2205}",
        hex: "U+2205",
        htmlEntity: "&#x2205;",
        cssEscape: "\\2205"
    },
    {
        name: "Not a Subset Of",
        symbol: "⊈",
        unicode: "\u{2288}",
        hex: "U+2288",
        htmlEntity: "&#x2288;",
        cssEscape: "\\2288"
    },
    {
        name: "Not a Superset Of",
        symbol: "⊉",
        unicode: "\u{2289}",
        hex: "U+2289",
        htmlEntity: "&#x2289;",
        cssEscape: "\\2289"
    },
    {
        name: "Ring Operator",
        symbol: "∘",
        unicode: "\u{2218}",
        hex: "U+2218",
        htmlEntity: "&#x2218;",
        cssEscape: "\\2218"
    },
    {
        name: "Angle",
        symbol: "∠",
        unicode: "\u{2220}",
        hex: "U+2220",
        htmlEntity: "&#x2220;",
        cssEscape: "\\2220"
    },
    {
        name: "Logical Not",
        symbol: "¬",
        unicode: "\u{00AC}",
        hex: "U+00AC",
        htmlEntity: "&#x00AC;",
        cssEscape: "\\00AC"
    },
    {
        name: "Perpendicular",
        symbol: "⊥",
        unicode: "\u{22A5}",
        hex: "U+22A5",
        htmlEntity: "&#x22A5;",
        cssEscape: "\\22A5"
    },
    {
        name: "Right Angle",
        symbol: "∟",
        unicode: "\u{221F}",
        hex: "U+221F",
        htmlEntity: "&#x221F;",
        cssEscape: "\\221F"
    },
    {
        name: "Partial Differential",
        symbol: "∂",
        unicode: "\u{2202}",
        hex: "U+2202",
        htmlEntity: "&#x2202;",
        cssEscape: "\\2202"
    }
]

export const uni_UIDesign = [
    {
        name: "Cursor Pointer",
        symbol: "➡",
        unicode: "\u{27A1}",
        hex: "U+27A1",
        htmlEntity: "&#x27A1;",
        cssEscape: "\\27A1"
    },
    {
        name: "Hand Pointer",
        symbol: "☞",
        unicode: "\u{261E}",
        hex: "U+261E",
        htmlEntity: "&#x261E;",
        cssEscape: "\\261E"
    },
    {
        name: "Zoom In",
        symbol: "🔍",
        unicode: "\u{1F50D}",
        hex: "U+1F50D",
        htmlEntity: "&#x1F50D;",
        cssEscape: "\\1F50D"
    },
    {
        name: "Zoom Out",
        symbol: "🔎",
        unicode: "\u{1F50E}",
        hex: "U+1F50E",
        htmlEntity: "&#x1F50E;",
        cssEscape: "\\1F50E"
    },
    {
        name: "Drag Handle",
        symbol: "🖐",
        unicode: "\u{1F590}",
        hex: "U+1F590",
        htmlEntity: "&#x1F590;",
        cssEscape: "\\1F590"
    },
    {
        name: "Mouse",
        symbol: "🖱",
        unicode: "\u{1F5B1}",
        hex: "U+1F5B1",
        htmlEntity: "&#x1F5B1;",
        cssEscape: "\\1F5B1"
    },
    {
        name: "Joystick",
        symbol: "🕹",
        unicode: "\u{1F579}",
        hex: "U+1F579",
        htmlEntity: "&#x1F579;",
        cssEscape: "\\1F579"
    },
    {
        name: "Keyboard",
        symbol: "⌨",
        unicode: "\u{2328}",
        hex: "U+2328",
        htmlEntity: "&#x2328;",
        cssEscape: "\\2328"
    },
    {
        name: "Clipboard",
        symbol: "📋",
        unicode: "\u{1F4CB}",
        hex: "U+1F4CB",
        htmlEntity: "&#x1F4CB;",
        cssEscape: "\\1F4CB"
    },
    {
        name: "Scissors",
        symbol: "✂",
        unicode: "\u{2702}",
        hex: "U+2702",
        htmlEntity: "&#x2702;",
        cssEscape: "\\2702"
    },
    {
        name: "Paperclip",
        symbol: "📎",
        unicode: "\u{1F4CE}",
        hex: "U+1F4CE",
        htmlEntity: "&#x1F4CE;",
        cssEscape: "\\1F4CE"
    },
    {
        name: "Pin",
        symbol: "📌",
        unicode: "\u{1F4CC}",
        hex: "U+1F4CC",
        htmlEntity: "&#x1F4CC;",
        cssEscape: "\\1F4CC"
    },
    {
        name: "Tag",
        symbol: "🏷",
        unicode: "\u{1F3F7}",
        hex: "U+1F3F7",
        htmlEntity: "&#x1F3F7;",
        cssEscape: "\\1F3F7"
    },
    {
        name: "File Folder",
        symbol: "📁",
        unicode: "\u{1F4C1}",
        hex: "U+1F4C1",
        htmlEntity: "&#x1F4C1;",
        cssEscape: "\\1F4C1"
    },
    {
        name: "Open Folder",
        symbol: "📂",
        unicode: "\u{1F4C2}",
        hex: "U+1F4C2",
        htmlEntity: "&#x1F4C2;",
        cssEscape: "\\1F4C2"
    },
    {
        name: "File Document",
        symbol: "📄",
        unicode: "\u{1F4C4}",
        hex: "U+1F4C4",
        htmlEntity: "&#x1F4C4;",
        cssEscape: "\\1F4C4"
    },
    {
        name: "Page with Curl",
        symbol: "📃",
        unicode: "\u{1F4C3}",
        hex: "U+1F4C3",
        htmlEntity: "&#x1F4C3;",
        cssEscape: "\\1F4C3"
    },
    {
        name: "Clipboard with Paper",
        symbol: "📋",
        unicode: "\u{1F4CB}",
        hex: "U+1F4CB",
        htmlEntity: "&#x1F4CB;",
        cssEscape: "\\1F4CB"
    },
    {
        name: "Calendar",
        symbol: "📅",
        unicode: "\u{1F4C5}",
        hex: "U+1F4C5",
        htmlEntity: "&#x1F4C5;",
        cssEscape: "\\1F4C5"
    },
    {
        name: "Clipboard with Check",
        symbol: "📋",
        unicode: "\u{1F5D1}",
        hex: "U+1F5D1",
        htmlEntity: "&#x1F5D1;",
        cssEscape: "\\1F5D1"
    },
    {
        name: "Pushpin",
        symbol: "📌",
        unicode: "\u{1F4CC}",
        hex: "U+1F4CC",
        htmlEntity: "&#x1F4CC;",
        cssEscape: "\\1F4CC"
    },
    {
        name: "Paperclips",
        symbol: "🖇",
        unicode: "\u{1F587}",
        hex: "U+1F587",
        htmlEntity: "&#x1F587;",
        cssEscape: "\\1F587"
    },
    {
        name: "Envelope",
        symbol: "✉",
        unicode: "\u{2709}",
        hex: "U+2709",
        htmlEntity: "&#x2709;",
        cssEscape: "\\2709"
    },
    {
        name: "Incoming Envelope",
        symbol: "📨",
        unicode: "\u{1F4E8}",
        hex: "U+1F4E8",
        htmlEntity: "&#x1F4E8;",
        cssEscape: "\\1F4E8"
    },
    {
        name: "Outgoing Envelope",
        symbol: "📤",
        unicode: "\u{1F4E4}",
        hex: "U+1F4E4",
        htmlEntity: "&#x1F4E4;",
        cssEscape: "\\1F4E4"
    },
    {
        name: "Email",
        symbol: "📧",
        unicode: "\u{1F4E7}",
        hex: "U+1F4E7",
        htmlEntity: "&#x1F4E7;",
        cssEscape: "\\1F4E7"
    },
    {
        name: "Mailbox",
        symbol: "📫",
        unicode: "\u{1F4EB}",
        hex: "U+1F4EB",
        htmlEntity: "&#x1F4EB;",
        cssEscape: "\\1F4EB"
    },
    {
        name: "Closed Mailbox with Raised Flag",
        symbol: "📬",
        unicode: "\u{1F4EC}",
        hex: "U+1F4EC",
        htmlEntity: "&#x1F4EC;",
        cssEscape: "\\1F4EC"
    },
    {
        name: "Closed Mailbox with Lowered Flag",
        symbol: "📪",
        unicode: "\u{1F4EA}",
        hex: "U+1F4EA",
        htmlEntity: "&#x1F4EA;",
        cssEscape: "\\1F4EA"
    },
    {
        name: "Open Mailbox with Raised Flag",
        symbol: "📭",
        unicode: "\u{1F4ED}",
        hex: "U+1F4ED",
        htmlEntity: "&#x1F4ED;",
        cssEscape: "\\1F4ED"
    },
    {
        name: "Open Mailbox with Lowered Flag",
        symbol: "📬",
        unicode: "\u{1F4EC}",
        hex: "U+1F4EC",
        htmlEntity: "&#x1F4EC;",
        cssEscape: "\\1F4EC"
    },
    {
        name: "Package",
        symbol: "📦",
        unicode: "\u{1F4E6}",
        hex: "U+1F4E6",
        htmlEntity: "&#x1F4E6;",
        cssEscape: "\\1F4E6"
    },
    {
        name: "Round Pushpin",
        symbol: "📍",
        unicode: "\u{1F4CD}",
        hex: "U+1F4CD",
        htmlEntity: "&#x1F4CD;",
        cssEscape: "\\1F4CD"
    },
    {
        name: "Lock",
        symbol: "🔒",
        unicode: "\u{1F512}",
        hex: "U+1F512",
        htmlEntity: "&#x1F512;",
        cssEscape: "\\1F512"
    },
    {
        name: "Unlock",
        symbol: "🔓",
        unicode: "\u{1F513}",
        hex: "U+1F513",
        htmlEntity: "&#x1F513;",
        cssEscape: "\\1F513"
    },
    {
        name: "Lock with Pen",
        symbol: "🔏",
        unicode: "\u{1F50F}",
        hex: "U+1F50F",
        htmlEntity: "&#x1F50F;",
        cssEscape: "\\1F50F"
    },
    {
        name: "Key",
        symbol: "🔑",
        unicode: "\u{1F511}",
        hex: "U+1F511",
        htmlEntity: "&#x1F511;",
        cssEscape: "\\1F511"
    },
    {
        name: "Old Key",
        symbol: "🗝",
        unicode: "\u{1F5DD}",
        hex: "U+1F5DD",
        htmlEntity: "&#x1F5DD;",
        cssEscape: "\\1F5DD"
    },
    {
        name: "Hammer",
        symbol: "🔨",
        unicode: "\u{1F528}",
        hex: "U+1F528",
        htmlEntity: "&#x1F528;",
        cssEscape: "\\1F528"
    },
    {
        name: "Wrench",
        symbol: "🔧",
        unicode: "\u{1F527}",
        hex: "U+1F527",
        htmlEntity: "&#x1F527;",
        cssEscape: "\\1F527"
    },
    {
        name: "Nut and Bolt",
        symbol: "🔩",
        unicode: "\u{1F529}",
        hex: "U+1F529",
        htmlEntity: "&#x1F529;",
        cssEscape: "\\1F529"
    },
    {
        name: "Magnifying Glass",
        symbol: "🔍",
        unicode: "\u{1F50D}",
        hex: "U+1F50D",
        htmlEntity: "&#x1F50D;",
        cssEscape: "\\1F50D"
    },
    {
        name: "Search",
        symbol: "🔎",
        unicode: "\u{1F50E}",
        hex: "U+1F50E",
        htmlEntity: "&#x1F50E;",
        cssEscape: "\\1F50E"
    },
    {
        name: "Dashboard",
        symbol: "📊",
        unicode: "\u{1F4CA}",
        hex: "U+1F4CA",
        htmlEntity: "&#x1F4CA;",
        cssEscape: "\\1F4CA"
    },
    {
        name: "Chart Increasing",
        symbol: "📈",
        unicode: "\u{1F4C8}",
        hex: "U+1F4C8",
        htmlEntity: "&#x1F4C8;",
        cssEscape: "\\1F4C8"
    },
    {
        name: "Chart Decreasing",
        symbol: "📉",
        unicode: "\u{1F4C9}",
        hex: "U+1F4C9",
        htmlEntity: "&#x1F4C9;",
        cssEscape: "\\1F4C9"
    },
    {
        name: "Bar Chart",
        symbol: "📊",
        unicode: "\u{1F4CA}",
        hex: "U+1F4CA",
        htmlEntity: "&#x1F4CA;",
        cssEscape: "\\1F4CA"
    },
    {
        name: "Line Chart",
        symbol: "📈",
        unicode: "\u{1F4C8}",
        hex: "U+1F4C8",
        htmlEntity: "&#x1F4C8;",
        cssEscape: "\\1F4C8"
    },
    {
        name: "Pie Chart",
        symbol: "📉",
        unicode: "\u{1F4C9}",
        hex: "U+1F4C9",
        htmlEntity: "&#x1F4C9;",
        cssEscape: "\\1F4C9"
    },
    {
        name: "Grid",
        symbol: "🔳",
        unicode: "\u{1F533}",
        hex: "U+1F533",
        htmlEntity: "&#x1F533;",
        cssEscape: "\\1F533"
    },
    {
        name: "Empty Square",
        symbol: "⬜",
        unicode: "\u{2B1C}",
        hex: "U+2B1C",
        htmlEntity: "&#x2B1C;",
        cssEscape: "\\2B1C"
    },
    {
        name: "Checked Box",
        symbol: "☑",
        unicode: "\u{2611}",
        hex: "U+2611",
        htmlEntity: "&#x2611;",
        cssEscape: "\\2611"
    },
    {
        name: "Checkmark",
        symbol: "✔",
        unicode: "\u{2714}",
        hex: "U+2714",
        htmlEntity: "&#x2714;",
        cssEscape: "\\2714"
    },
    {
        name: "Crossmark",
        symbol: "❌",
        unicode: "\u{274C}",
        hex: "U+274C",
        htmlEntity: "&#x274C;",
        cssEscape: "\\274C"
    },
    {
        name: "X Mark",
        symbol: "❌",
        unicode: "\u{2716}",
        hex: "U+2716",
        htmlEntity: "&#x2716;",
        cssEscape: "\\2716"
    },
    {
        name: "Star",
        symbol: "⭐",
        unicode: "\u{2B50}",
        hex: "U+2B50",
        htmlEntity: "&#x2B50;",
        cssEscape: "\\2B50"
    },
    {
        name: "Star Outline",
        symbol: "⭐",
        unicode: "\u{2606}",
        hex: "U+2606",
        htmlEntity: "&#x2606;",
        cssEscape: "\\2606"
    },
    {
        name: "Favorite",
        symbol: "❤",
        unicode: "\u{2764}",
        hex: "U+2764",
        htmlEntity: "&#x2764;",
        cssEscape: "\\2764"
    },
    {
        name: "Heart",
        symbol: "❤",
        unicode: "\u{2665}",
        hex: "U+2665",
        htmlEntity: "&#x2665;",
        cssEscape: "\\2665"
    },
    {
        name: "Calendar",
        symbol: "📅",
        unicode: "\u{1F4C5}",
        hex: "U+1F4C5",
        htmlEntity: "&#x1F4C5;",
        cssEscape: "\\1F4C5"
    },
    {
        name: "Calendar Pages",
        symbol: "📆",
        unicode: "\u{1F4C6}",
        hex: "U+1F4C6",
        htmlEntity: "&#x1F4C6;",
        cssEscape: "\\1F4C6"
    },
    {
        name: "Clock",
        symbol: "🕒",
        unicode: "\u{1F552}",
        hex: "U+1F552",
        htmlEntity: "&#x1F552;",
        cssEscape: "\\1F552"
    },
    {
        name: "Timer Clock",
        symbol: "⏲",
        unicode: "\u{23F2}",
        hex: "U+23F2",
        htmlEntity: "&#x23F2;",
        cssEscape: "\\23F2"
    },
    {
        name: "Hourglass",
        symbol: "⏳",
        unicode: "\u{23F3}",
        hex: "U+23F3",
        htmlEntity: "&#x23F3;",
        cssEscape: "\\23F3"
    },
    {
        name: "Alarm Clock",
        symbol: "⏰",
        unicode: "\u{23F0}",
        hex: "U+23F0",
        htmlEntity: "&#x23F0;",
        cssEscape: "\\23F0"
    },
    {
        name: "Stopwatch",
        symbol: "⏱",
        unicode: "\u{23F1}",
        hex: "U+23F1",
        htmlEntity: "&#x23F1;",
        cssEscape: "\\23F1"
    },
    {
        name: "Bell",
        symbol: "🔔",
        unicode: "\u{1F514}",
        hex: "U+1F514",
        htmlEntity: "&#x1F514;",
        cssEscape: "\\1F514"
    },
    {
        name: "Notification Bell",
        symbol: "🔔",
        unicode: "\u{1F6CE}",
        hex: "U+1F6CE",
        htmlEntity: "&#x1F6CE;",
        cssEscape: "\\1F6CE"
    },
    {
        name: "Announcement",
        symbol: "📢",
        unicode: "\u{1F4E2}",
        hex: "U+1F4E2",
        htmlEntity: "&#x1F4E2;",
        cssEscape: "\\1F4E2"
    },
    {
        name: "Megaphone",
        symbol: "📣",
        unicode: "\u{1F4E3}",
        hex: "U+1F4E3",
        htmlEntity: "&#x1F4E3;",
        cssEscape: "\\1F4E3"
    },
    {
        name: "Satellite Antenna",
        symbol: "📡",
        unicode: "\u{1F4E1}",
        hex: "U+1F4E1",
        htmlEntity: "&#x1F4E1;",
        cssEscape: "\\1F4E1"
    },
    {
        name: "Broadcast Tower",
        symbol: "📡",
        unicode: "\u{1F5FC}",
        hex: "U+1F5FC",
        htmlEntity: "&#x1F5FC;",
        cssEscape: "\\1F5FC"
    },
    {
        name: "Movie Camera",
        symbol: "🎥",
        unicode: "\u{1F3A5}",
        hex: "U+1F3A5",
        htmlEntity: "&#x1F3A5;",
        cssEscape: "\\1F3A5"
    },
    {
        name: "Clapper Board",
        symbol: "🎬",
        unicode: "\u{1F3AC}",
        hex: "U+1F3AC",
        htmlEntity: "&#x1F3AC;",
        cssEscape: "\\1F3AC"
    },
    {
        name: "Microphone",
        symbol: "🎤",
        unicode: "\u{1F3A4}",
        hex: "U+1F3A4",
        htmlEntity: "&#x1F3A4;",
        cssEscape: "\\1F3A4"
    },
    {
        name: "Headphone",
        symbol: "🎧",
        unicode: "\u{1F3A7}",
        hex: "U+1F3A7",
        htmlEntity: "&#x1F3A7;",
        cssEscape: "\\1F3A7"
    },
    {
        name: "Radio",
        symbol: "📻",
        unicode: "\u{1F4FB}",
        hex: "U+1F4FB",
        htmlEntity: "&#x1F4FB;",
        cssEscape: "\\1F4FB"
    },
    {
        name: "Film Reel",
        symbol: "🎞",
        unicode: "\u{1F39E}",
        hex: "U+1F39E",
        htmlEntity: "&#x1F39E;",
        cssEscape: "\\1F39E"
    },
    {
        name: "Camera",
        symbol: "📷",
        unicode: "\u{1F4F7}",
        hex: "U+1F4F7",
        htmlEntity: "&#x1F4F7;",
        cssEscape: "\\1F4F7"
    }
]



