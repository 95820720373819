import { Route, Routes } from 'react-router-dom'
import { Starter } from '../Starter/Starter'
import { ArtkonRouter } from './ArtkonRouter'
import { QuizRouter } from './QuizRouter'
import { SkeletonRouter } from './SkeletonRouter.jsx'


export const MainRouter = () => {

    return (
        <Routes>
            <Route path='/' element={<Starter />}></Route>
            <Route path='/artkon/*' element={<ArtkonRouter />}></Route>
            <Route path='/Quiz/*' element={<QuizRouter />}></Route>
            <Route path='/Skeleton/*' element={<SkeletonRouter />}></Route>
        </Routes>
    )
}