import { Box, Button, Heading, MenuButton, Spinner, Text, Menu, MenuItem, MenuList, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, FormControl, FormLabel, useDisclosure, Image, Img, Grid } from '@chakra-ui/react';
import { SkeletonData, SkeletonChildData, linear, linear_btn } from '../SkeletonData/SkeletonData.js';
import { useNavigate, useParams } from "react-router-dom";
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { androidstudio } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useState } from "react";



export const SkeletonCard = () => {
    const { isOpen: IsOpen, onOpen: OnOpen, onClose: OnClose } = useDisclosure()
    const { isOpen: IsOpen_c, onOpen: OnOpen_c, onClose: OnClose_c } = useDisclosure()
    const [bg, setBg] = useState('#F5F5F5');
    const [one, setOne] = useState('#E0E0E0');
    const [two, setTwo] = useState('#FAFAFA');
    const [child, setChild] = useState(0);
    const navigate = useNavigate();
    const { id } = useParams();
    const toast = useToast();

    const copyToClipboard = (ele) => {
        navigator.clipboard.writeText(ele);
        toast({ title: 'Copied', status: 'success', duration: 1000, isClosable: true, position: 'top' })
    }

    const handleReset = () => {
        setBg('#F5F5F5');
        setOne('#E0E0E0');
        setTwo('#FAFAFA');
    }

    const handleCopyChild = (id) => {
        setChild(id);
        OnOpen_c();
    }

    function replaceColor(htmlData) {
        if (one === undefined && two === undefined && bg === undefined) return htmlData
        var Z_step = htmlData.replace(/#F5F5F5/g, bg);
        var F_step = Z_step.replace(/#E0E0E0/g, one);
        var L_step = F_step.replace(/#FAFAFA/g, two)
        return L_step;
    }

    var HTML_Stacture = replaceColor(SkeletonData[id]['HTML']);


    return (
        <Box>
            <Box bg={'white'} minH={SkeletonChildData[`${id}fr`] ? 'auto' : '100vh'}>

                <Box>
                    <Box onClick={() => navigate(-1)} cursor={'pointer'} display={'flex'} gap={'10px'} p={'10px 0 10px 20px'} alignItems={'center'}>
                        <Text color={'#298ef3'} fontSize={'30px'} fontWeight={'bold'}>|| </Text>
                        <Text color={'grey'} fontSize={'30px'} fontWeight={'bold'}>Skeleton</Text>
                    </Box>

                    <Box></Box>
                </Box>

                {SkeletonData[id] &&
                    <Box w={'95%'} p={'10px'} m={'auto'} padding={'0.5cm 0'} display={'flex'} flexDirection={['column', 'column', 'row']} justifyContent={'space-between'} alignItems={'center'} borderRadius={'20px'}>

                        <Box w={['100%', '100%', '50%']} h={'100%'} bg={'white'} borderRadius={'20px'} p={'10px 0'}>
                            <Box w={'100%'} borderRadius={'10px'} boxShadow={'transparent 0px 7px 29px 0px'}>
                                <style> {SkeletonData[id].CSS}</style>
                                <Box maxW={'400px'} m={'auto'} display={'flex'} justifyContent={'center'} justifyItems={'center'} dangerouslySetInnerHTML={{ __html: HTML_Stacture }} />
                            </Box>

                            <Box>
                                <Button onClick={handleReset} display={'block'} maxW={'400px'} w={['100%']} h={'50px'} m={'10px auto'} bg={linear_btn} color={'white'}>RESET</Button>
                                <Button onClick={() => OnOpen()} display={'block'} maxW={'400px'} w={['100%']} h={'50px'} m={'10px auto'} bg={linear_btn} color={'white'}>COPY</Button>
                            </Box>
                        </Box>

                        <Box w={['100%', '100%', '30%']} bg={'white'} h={'100%'} p={'10px'} borderRadius={'10px'} mt={['10px', '0', '0']} boxShadow={'rgba(145, 158, 171, 0.16) -20px 40px 80px'}>
                            <Heading textAlign={'center'} fontSize={'25px'} m={'0 auto 3px auto'} color={"#04225a64"}>Colour Adjustment</Heading>
                            <Input onChange={(e) => setBg(e.target.value)} type="color" value={bg} style={{ width: '100%', height: '50px' }} border={'1px solid transparent'} />
                            <Input type="text" onChange={(e) => setBg(e.target.value)} value={bg} color={'white'} bg={linear_btn} textAlign={'center'} border={'1px solid transparent'} m={'10px auto'} />

                            <Input onChange={(e) => setOne(e.target.value)} type="color" value={one} style={{ width: '100%', height: '50px' }} border={'1px solid transparent'} />
                            <Input type="text" onChange={(e) => setOne(e.target.value)} value={one} color={'white'} bg={linear_btn} textAlign={'center'} border={'1px solid transparent'} m={'10px auto'} />

                            <Input onChange={(e) => setTwo(e.target.value)} type="color" value={two} style={{ width: '100%', height: '50px' }} border={'1px solid transparent'} />
                            <Input type="text" onChange={(e) => setTwo(e.target.value)} value={two} color={'white'} bg={linear_btn} textAlign={'center'} border={'1px solid transparent'} m={'10px auto'} />
                        </Box>

                    </Box>
                }
            </Box>

            {SkeletonChildData[`${id}fr`] && SkeletonChildData[`${id}fr`] && <Box w={'100%'} p={['0 0.5cm 0 0.5cm', '0 1cm 0 1cm']} boxShadow={'rgba(145, 158, 171, 0.678) -40px 40px 80px'}>
                <Heading m={'1.5cm 0 1cm 0'} color={'grey'}>Try Similar Skeleton</Heading>
                <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={['20px']}>
                    {SkeletonChildData[`${id}fr`] && SkeletonChildData[`${id}fr`]?.map((ele, index) => (
                        <Box onClick={() => handleCopyChild(ele.id)} border={'1px solid #E0E0E0'} bg={'white'} key={index} cursor={'pointer'} borderRadius={'10px'}>
                            <style>
                                {ele.CSS}
                            </style>
                            {/* <Text>{index}</Text> */}
                            <Box display={'flex'} justifyContent={'center'} justifyItems={'center'}
                                key={index}
                                dangerouslySetInnerHTML={{ __html: ele.HTML }} />
                        </Box>
                    ))}
                </Grid>
            </Box>
            }

            <Modal isOpen={IsOpen} onClose={OnClose}>
                <ModalOverlay />
                <ModalContent mt={['4cm', '3cm']} bg={'white'} boxShadow={'#373131 0px 0px 30px'} w={'95%'} borderRadius={'20px'}>
                    <ModalHeader color={'#616161'} textAlign={'center'} fontWeight={'bold'}>Copy the HTML & CSS Code</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody mt={'15px'} mb={'15px'} display={"flex"} flexDirection={'column'} gap={'20px'}>
                        <Box w={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'} gap={'5px'}>
                            <SyntaxHighlighter SyntaxHighlighter language="javascript" style={androidstudio} customStyle={{ width: '100%' }} >
                                {HTML_Stacture}
                            </SyntaxHighlighter>
                            <Button onClick={() => copyToClipboard(HTML_Stacture)} bg={linear} w={'100%'} color={'white'} h={'50px'}>Copy</Button>
                        </Box>

                        <Box w={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'} gap={'5px'}>
                            <SyntaxHighlighter SyntaxHighlighter language="javascript" style={androidstudio} customStyle={{ width: '100%' }} >
                                {SkeletonData[id]['CSS']}
                            </SyntaxHighlighter>
                            <Button onClick={() => copyToClipboard(SkeletonData[id]['CSS'])} bg={linear} w={'100%'} color={'white'} h={'50px'}>Copy</Button>
                        </Box>
                    </ModalBody>

                </ModalContent>
            </Modal>


            <Modal isOpen={IsOpen_c} onClose={OnClose_c}>
                <ModalOverlay />
                <ModalContent mt={['4cm', '3cm']} bg={'white'} boxShadow={'#373131 0px 0px 30px'} w={'95%'} borderRadius={'20px'}>
                    <ModalHeader color={'#616161'} textAlign={'center'} fontWeight={'bold'}>Copy the HTML & CSS Code</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody mt={'15px'} mb={'15px'} display={"flex"} flexDirection={'column'} gap={'20px'}>
                        <Box w={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'} gap={'5px'}>
                            <SyntaxHighlighter SyntaxHighlighter language="javascript" style={androidstudio} customStyle={{ width: '100%' }} >
                                {SkeletonChildData[`${id}fr`] && SkeletonChildData[`${id}fr`][child]['HTML']}
                            </SyntaxHighlighter>
                            <Button onClick={() => copyToClipboard(SkeletonChildData[`${id}fr`] && SkeletonChildData[`${id}fr`][child]['HTML'])} bg={linear} w={'100%'} color={'white'} h={'50px'}>Copy</Button>
                        </Box>

                        <Box w={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'} gap={'5px'}>
                            <SyntaxHighlighter SyntaxHighlighter language="javascript" style={androidstudio} customStyle={{ width: '100%' }} >
                                {SkeletonChildData[`${id}fr`] && SkeletonChildData[`${id}fr`][child]['CSS']}
                            </SyntaxHighlighter>
                            <Button onClick={() => copyToClipboard(SkeletonChildData[`${id}fr`] && SkeletonChildData[`${id}fr`][child]['CSS'])} bg={linear} w={'100%'} color={'white'} h={'50px'}>Copy</Button>
                        </Box>
                    </ModalBody>

                </ModalContent>
            </Modal>
        </Box>
    )
}