import { Box, Grid, Heading, Text } from "@chakra-ui/react"
import { SkeletonData, linear } from '../SkeletonData/SkeletonData.js';
import { useNavigate } from "react-router-dom";

export const SkeletonPage = () => {
    const bg_linear = "linear-gradient(30deg, rgb(0, 75, 173), rgb(1, 81, 178), rgb(1, 87, 182), rgb(2, 93, 187), rgb(2, 99, 191), rgb(3, 106, 196), rgb(3, 112, 200), rgb(4, 118, 205), rgb(4, 124, 209), rgb(5, 130, 214), rgb(5, 130, 214), rgb(4, 121, 206), rgb(4, 112, 198), rgb(3, 103, 190), rgb(3, 94, 182), rgb(2, 84, 175), rgb(2, 75, 167), rgb(1, 66, 159), rgb(1, 57, 151), rgb(0, 48, 143))";
    const navigate = useNavigate();

    return (
        <Box w={'100%'} h={'100vh'} overflow={'scroll'} bg={'white'}>

            <Box>
                <Box display={'flex'} gap={'10px'} p={'10px'} alignItems={'center'}>
                    <Text color={'#303F9F'} fontSize={'30px'} fontWeight={'bold'}>|| </Text>
                    <Text color={'#424242'} fontSize={'30px'} fontWeight={'medium'}>Skeleton</Text>
                </Box>

                {/* banner */}
                <Box textAlign={'center'} mt={['1.5cm', '2cm', '2cm']} mb={'1cm'}>
                    <Box color={'#424242'} fontSize={['30px', '40px', '50px']} fontWeight={'medium'} display={'flex'} justifyContent={'center'}>
                        <Text>Free</Text>
                        <Text ml={'10px'} color={'#303F9F'}>Skeletons</Text>
                    </Box>
                    <Text color={'#616161'} fontSize={['20px']}>Made for you</Text>
                </Box>

            </Box>

            <Box bg={'white'} borderRadius={'20px 20px 0 0'} p={'0.5cm 0'} boxShadow={'#919eab6b -40px 40px 80px'}>
                <Grid w={'95%'} m={'1cm auto'} templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={['20px']}>                    {SkeletonData && SkeletonData.length !== 0 && SkeletonData?.map((ele, index) => (
                    <Box onClick={() => navigate(`Card/${ele.id}`)} bg={'white'} key={index} cursor={'pointer'} borderRadius={'10px'} boxShadow={'transparent 0px 7px 29px 0px'}>
                        <style>{ele.CSS}</style>
                        <Box display={'flex'} justifyContent={'center'} justifyItems={'center'}
                            key={index}
                            dangerouslySetInnerHTML={{ __html: ele.HTML }}
                        />
                    </Box>
                ))}
                </Grid>
            </Box>
        </Box>
    )
}