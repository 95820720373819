import { Box, Text, Grid, useToast, Input, Button } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { gradient_data } from '../assets/gradient_data.js'



export const GradientColors = ({ setSide, side }) => {
  const navigate = useNavigate()
  const toast = useToast();


  const copyToClipboard = (ele) => {
    navigator.clipboard.writeText(ele);
    toast({ title: 'Gradient Color Copied', status: 'success', duration: 1000, isClosable: true, position: 'top' })
  }

  return (
    <Box w={'95%'} m={side ? '1.4cm auto 1cm auto' : '0.5cm auto'}>
      <Box>
        {!side && <Box display={'flex'} alignItems={'center'} fontSize={['20px', '25px']} fontWeight={'bold'} m={'0.5cm auto 0.5cm auto'}>
          <Text ml={'10px'} cursor={'pointer'} onClick={() => setSide(!side)}>|||</Text>
          <Text ml={'15px'} cursor={'pointer'} onClick={() => navigate('/')}>ArtKorner</Text>
        </Box>}
      </Box>


      <Grid templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']} gap={['30px 15px']}>
        {gradient_data && gradient_data?.map((ele, index) => (
          <Box onClick={() => copyToClipboard(ele)} key={index} w={'100%'} h={'200px'} display={'flex'} justifyContent={'center'} alignItems={'center'} bg={ele} borderRadius={'20px'} cursor={'pointer'} color={'white'}>
            #{index+1}
          </Box>
        ))}
      </Grid>


    </Box>
  );
}
