export const clipPathArray = [
    'inset(5% 20% 15% 10%)',
    'circle(50% at 50% 50%)',
    'ellipse(25% 40% at 50% 50%)',
    'polygon(50% 0%, 0% 100%, 100% 100%)',
    'polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)',
    'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
    'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)',
    'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
    'polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%)',
    'polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)',
    'polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%)',
    'polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%)',
    'polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)',
    'polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%)',
    'polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%)',
    'polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%)',
    'polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%)',
    'polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)',
    'polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%)',
    'polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)',
    'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
    'polygon(10% 25%, 35% 25%, 35% 0%, 65% 0%, 65% 25%, 90% 25%, 90% 50%, 65% 50%, 65% 100%, 35% 100%, 35% 50%, 10% 50%)',
    'polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%)',
    'polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%)',
    'polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%)',
    'polygon(0% 0%, 100% 0%, 100% 75%, 50% 100%, 0% 75%)',
    'polygon(10% 10%, 90% 10%, 50% 90%)',
    'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    'polygon(10% 10%, 90% 10%, 90% 90%, 10% 90%)',
    'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)',
    'ellipse(50% 50% at 50% 50%)',
    'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)',
    'polygon(20% 10%, 20% 90%, 80% 90%, 80% 10%)',
    'polygon(0% 0%, 100% 0%, 70% 100%, 30% 100%)',
    'polygon(10% 0%, 100% 50%, 90% 100%, 0% 50%)',
    'polygon(30% 10%, 70% 10%, 50% 90%)',
    'polygon(10% 0%, 90% 0%, 50% 100%)',
    'polygon(0% 0%, 100% 0%, 80% 100%, 20% 100%)',
    'polygon(40% 0%, 100% 40%, 60% 100%, 0% 60%)',
    'polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)',
    'polygon(20% 0%, 60% 100%, 80% 0%)',
    'polygon(0% 20%, 100% 20%, 100% 80%, 0% 80%)',
    'polygon(10% 10%, 10% 90%, 90% 90%, 90% 10%)',
    'polygon(20% 0%, 100% 50%, 0% 50%)',
    'polygon(0% 20%, 100% 20%, 80% 100%)',
    'polygon(30% 0%, 100% 70%, 0% 70%)',
    'polygon(20% 0%, 100% 60%, 60% 100%, 0% 40%)',
    'polygon(0% 30%, 100% 30%, 70% 100%, 30% 100%)',
    'polygon(40% 0%, 100% 80%, 0% 80%)',
    'polygon(20% 0%, 100% 40%, 80% 100%, 0% 60%)',
    'polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)',
    'polygon(10% 0%, 90% 50%, 0% 50%)',
    'polygon(0% 40%, 100% 40%, 60% 100%)',
    'polygon(30% 0%, 70% 0%, 100% 100%, 0% 100%)',
    'polygon(20% 0%, 80% 100%, 60% 0%)',
    'polygon(0% 50%, 100% 50%, 50% 100%)',
    'polygon(40% 0%, 60% 100%, 100% 60%, 0% 20%)',
    'polygon(30% 0%, 70% 100%, 0% 100%)',
    'polygon(20% 0%, 100% 80%, 80% 0%)',
    'polygon(20% 0%, 80% 60%, 100% 100%, 0% 100%)',
    'polygon(10% 0%, 90% 40%, 0% 40%)',
    'polygon(0% 60%, 100% 60%, 60% 100%)',
    'polygon(30% 0%, 70% 60%, 100% 100%, 0% 100%)',
    'polygon(20% 0%, 60% 0%, 100% 100%, 0% 100%)',
    'polygon(0% 70%, 100% 70%, 70% 100%)',
    'polygon(40% 0%, 100% 20%, 60% 100%, 0% 80%)',
    'polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)',
    'polygon(10% 0%, 90% 60%, 0% 60%)',
    'polygon(0% 80%, 100% 80%, 80% 100%)',
    'polygon(30% 0%, 70% 20%, 100% 100%, 0% 100%)',
    'polygon(20% 0%, 60% 100%, 100% 0%)',
    'polygon(0% 90%, 100% 90%, 90% 100%)',
    'polygon(40% 0%, 60% 0%, 100% 100%, 0% 100%)',
    'polygon(20% 0%, 100% 70%, 80% 100%, 0% 30%)',
    'polygon(10% 0%, 90% 80%, 0% 80%)',
    'polygon(0% 100%, 100% 100%, 60% 0%, 40% 0%)',
];
