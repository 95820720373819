import React, { useState } from "react";
import { Box, Text, useToast, Heading, Input, Button } from '@chakra-ui/react'
import "./ColorConverter.css";
import { useNavigate } from "react-router-dom";

function ColorConverter({setSide,side}) {
    const [colorValue, setColorValue] = useState("");
    const [convertedRgb, setConvertedRgb] = useState("");
    const [convertedHex, setConvertedHex] = useState("");
    const [convertedHsl, setConvertedHsl] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate()
    const toast = useToast();


    const convertColor = () => {
        const sanitizedColorValue = colorValue.trim();

        if (sanitizedColorValue.startsWith("#")) {
            convertHexToRgbAndHsl(sanitizedColorValue);
        } else if (sanitizedColorValue.startsWith("rgb(") && sanitizedColorValue.endsWith(")")) {
            convertRgbToHexAndHsl(sanitizedColorValue);
        } else if (sanitizedColorValue.startsWith("hsl(") && sanitizedColorValue.endsWith(")")) {
            convertHslToRgbAndHex(sanitizedColorValue);
        } else {
            setErrorMessage("Invalid color format. Please use HEX, RGB, or HSL format.");
            clearConvertedValues();
        }
    };

    const convertHexToRgbAndHsl = (hexColor) => {
        const hexWithoutHash = hexColor.substring(1);
        if (!/^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hexWithoutHash)) {
            setErrorMessage("Invalid HEX color format.");
            clearConvertedValues();
            return;
        }

        const r = parseInt(hexWithoutHash.slice(0, 2), 16);
        const g = parseInt(hexWithoutHash.slice(2, 4), 16);
        const b = parseInt(hexWithoutHash.slice(4, 6), 16);

        const rgbColor = `RGB(${r}, ${g}, ${b})`;
        const hslColor = rgbToHsl(r, g, b);

        setConvertedRgb(rgbColor);
        setConvertedHex(hexColor);
        setConvertedHsl(hslColor);
        setErrorMessage("");
    };

    const convertRgbToHexAndHsl = (rgbColor) => {
        const rgbValues = rgbColor.slice(4, -1).split(",").map(value => parseInt(value.trim(), 10));

        if (rgbValues.length !== 3 || rgbValues.some(value => isNaN(value) || value < 0 || value > 255)) {
            setErrorMessage("Invalid RGB color format.");
            clearConvertedValues();
            return;
        }

        const hexColor = rgbValues.map(value => value.toString(16).padStart(2, "0")).join("");
        const hslColor = rgbToHsl(rgbValues[0], rgbValues[1], rgbValues[2]);

        setConvertedRgb(rgbColor);
        setConvertedHex(`#${hexColor}`);
        setConvertedHsl(hslColor);
        setErrorMessage("");
    };

    const convertHslToRgbAndHex = (hslColor) => {
        const hslValues = hslColor.slice(4, -1).split(",").map(value => parseFloat(value.trim()));

        if (hslValues.length !== 3 || hslValues.some(value => isNaN(value))) {
            setErrorMessage("Invalid HSL color format.");
            clearConvertedValues();
            return;
        }

        const rgbValues = hslToRgb(hslValues[0], hslValues[1] / 100, hslValues[2] / 100);
        const hexColor = rgbValues.map(value => value.toString(16).padStart(2, "0")).join("");
        const rgbColor = `RGB(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]})`;

        setConvertedRgb(rgbColor);
        setConvertedHex(`#${hexColor}`);
        setConvertedHsl(hslColor);
        setErrorMessage("");
    };

    const clearConvertedValues = () => {
        setConvertedRgb("");
        setConvertedHex("");
        setConvertedHsl("");
    };

    const rgbToHsl = (r, g, b) => {
        r /= 255;
        g /= 255;
        b /= 255;
        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max === min) {
            h = s = 0; // achromatic
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }

        return `HSL(${Math.round(h * 360)}, ${Math.round(s * 100)}%, ${Math.round(l * 100)}%)`;
    };

    const hslToRgb = (h, s, l) => {
        h /= 360;
        s = Math.min(1, Math.max(0, s));
        l = Math.min(1, Math.max(0, l));

        if (s === 0) {
            return [Math.round(l * 255), Math.round(l * 255), Math.round(l * 255)];
        } else {
            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;
            const r = hueToRgb(p, q, h + 1 / 3);
            const g = hueToRgb(p, q, h);
            const b = hueToRgb(p, q, h - 1 / 3);
            return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
        }
    };

    const hueToRgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
    };

    const copyToClipboard = (ele) => {
        navigator.clipboard.writeText(ele);
        toast({ title: 'Color Code Copied', status: 'success', duration: 2000, isClosable: true, position: 'top' })
    }

    return (
        <Box w={'100%'} m={side?['2.3cm auto 1cm auto','1.5cm auto 1cm auto','1.5cm auto 1cm auto']:'0.5cm auto'}>
            {!side && <Box display={'flex'} alignItems={'center'} fontSize={'25px'} fontWeight={'bold'} m={'0cm auto 1cm auto'}>
                <Text ml={'10px'} cursor={'pointer'} onClick={() => setSide(!side)}>|||</Text>
                <Text ml={'15px'} cursor={'pointer'} onClick={() => navigate('/')}>ArtKorner</Text>
            </Box>}

            <Heading textAlign={'center'}>Color Converter</Heading>
            <Box  w={['100%']} m={'auto'}>
                <Box w={['80%', '65%', '50%']} m={'auto'}>
                    <Text textAlign={'center'}>Enter color value</Text>
                    <Input textAlign={'center'} w={'100%'} h={'55px'} type="text" display={'block'} m={'10px auto'} border={'1px solid grey'} id="colorValue" placeholder="HEX, RGB, or HSL" value={colorValue} onChange={(e) => setColorValue(e.target.value)} />
                </Box>
                <Button w={['50%', '20%', '15%']} display={'block'} m={'10px auto'} bg={"white"} color={convertedRgb ? convertedRgb : "#5d5d5d"} border={`1.2px solid ${convertedRgb}`} onClick={convertColor}>Convert</Button>
                <Box w={'100%'} m={'0.5cm auto'}>
                {errorMessage && <Text textAlign={'center'} mt={'15px'} className="error-message">{errorMessage}</Text>}
                {convertedHex && <Text onClick={() => copyToClipboard(convertedHex)} w={['80%', '65%', '50%']} cursor={'pointer'} textAlign={'center'} color={'grey'} border={`1px solid ${convertedHex}`} p={'10px'} m={'7px auto'}>HEX color : {convertedHex}</Text>}
                {convertedRgb && <Text onClick={() => copyToClipboard(convertedRgb)} w={['80%', '65%', '50%']} cursor={'pointer'} textAlign={'center'} color={'grey'} border={`1px solid ${convertedHex}`} p={'10px'} m={'7px auto'}>RGB color : {convertedRgb}</Text>}
                {convertedHsl && <Text onClick={() => copyToClipboard(convertedHsl)} w={['80%', '65%', '50%']} cursor={'pointer'} textAlign={'center'} color={'grey'} border={`1px solid ${convertedHex}`} p={'10px'} m={'7px auto'}>HSL color : {convertedHsl}</Text>}
                </Box>
            </Box>
        </Box>
    );
}

export default ColorConverter;
