import { Box, Button, Grid, Heading, Image, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import './HomePage.css';
import color_material from '../../assets/Artkon/color_material.png';
import box_shadow from '../../assets/Artkon/box_shadow.png';
import color_shade from '../../assets/Artkon/color_shade.png';
import color_picker from '../../assets/Artkon/color_picker.png';
import color_converter from '../../assets/Artkon/color_converter.png';
import unicode from '../../assets/Artkon/unicode.png';
import emoji from '../../assets/Artkon/emoji.png';
import gDrive from '../../assets/Artkon/gDrive.png';
import gradient_maker from '../../assets/Artkon/gradient_maker.png';
import gradient_color from '../../assets/Artkon/gradient_color.png';
import animation from '../../assets/Artkon/animation.png';
import clip_path from '../../assets/Artkon/clip_path.png';

const dataService_one = [
    {
        name: 'Color Material',
        url: '/artkon/ColorMaterial',
        img: color_material,
    },
    {
        name: 'Box Shadow',
        url: '/artkon/Box-Shadow',
        img: box_shadow,
    },
    {
        name: 'Color Shade',
        url: '/artkon/ColorShade',
        img: color_shade,
    },
    {
        name: 'Color Picker',
        url: '/artkon/ColorPicker',
        img: color_picker,
    },
    {
        name: 'Color Converter',
        url: '/artkon/ColorConverter',
        img: color_converter,
    },
    {
        name: 'UniCode',
        url: '/artkon/UniCode',
        img: unicode,
    },
    {
        name: 'Emoji',
        url: '/artkon/Emoji',
        img: emoji,
    },
    {
        name: "GDrive convertar",
        url: '/artkon/GDriveURLConverter',
        img: gDrive,
    },
    {
        name: 'Gradient Maker',
        url: '/artkon/GradientMaker',
        img: gradient_maker,
    },
    {
        name: 'Gradient Color',
        url: '/artkon/GradientColors',
        img: gradient_color,
    },
    {
        name: 'Animation',
        url: '/artkon/Animation',
        img: animation,
    },
    {
        name: 'Clip Path',
        url: '/artkon/ClipPath',
        img: clip_path,
    }
];

export const HomePage = ({ setSide, side }) => {
    const navigate = useNavigate()



    return (
        <Box w={'95%'} m={side ? '1.4cm auto 1cm auto' : '0.5cm auto'}>
            {!side && <Box display={'flex'} alignItems={'center'} fontSize={'25px'} fontWeight={'bold'} m={'0cm auto 1cm auto'}>
                <Text m={'0 10px'} cursor={'pointer'} onClick={() => setSide(!side)}>|||</Text>
                <Box display={'flex'} cursor={'pointer'} onClick={() => navigate('/artkon')}>
                    <Text color={'#66BB6A'}>Art</Text>
                    <Text color={'#424242'}>Korner</Text>
                </Box>
            </Box>}

            {/* banner */}
            <Box textAlign={'center'} mt={['1.5cm', '2cm', '2cm']} mb={'1cm'}>
                <Box color={'#424242'} fontSize={['30px', '40px', '50px']} fontWeight={'medium'} display={'flex'} justifyContent={'center'} gap={'10px'}>
                    <Text ml={'10px'} color={'#66BB6A'}>Art</Text>
                    <Text>Korner</Text>
                </Box>
                <Text color={'#616161'} textAlign={'center'} fontSize={['15px']}>Art Korner provide you free frontend tools.</Text>
            </Box>

            {/* service one */}
            <Grid w={'90%'} m={'1cm auto'} templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={['30px 20px']}>
                {dataService_one && dataService_one?.map((item, index) => (
                    <Box key={index} onClick={() => navigate(item.url)} display={'flex'} flexDirection={'column'} justifyContent={'center'} textAlign={'center'} w={'100%'} minH={'220px'} p={'20px'} cursor={'pointer'} boxShadow={index % 2 === 1 && 'rgba(145, 158, 171, 0.16) -40px 40px 80px'}>
                        <Box h={'70px'} display={'flex'} justifyContent={'center'}>
                            {/* blanck content will added soon */}
                            <Image w={'50px'} h={'50px'} src={item.img && item.img} />
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
                            <Box w={'50px'} m={'auto'} h={'2px'} bg={'#66BB6A'} transition={"0.5s"} _hover={{ w: '70px' }}></Box>
                            <Box fontSize={'20px'} color={'#424242'} fontWeight={'bold'}>{item.name}</Box>
                            {/* <Box fontSize={'14px'} color={'grey'}>{item.name}</Box> */}
                        </Box>
                    </Box>
                ))}
            </Grid>
        </Box>
    );
};
