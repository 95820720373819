import { Box, Button, Heading, MenuButton, Spinner, Text, Menu, MenuItem, MenuList, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input, FormControl, FormLabel, useDisclosure, Image, Img } from '@chakra-ui/react';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { quiz_page } from '../Quiz_data/Quiz_data.js'
import axios from 'axios';


export const QuizPage = () => {
    const personal = JSON.parse(localStorage.getItem('personal')) || {};
    const [result, setResult] = useState('waiting');
    const [status, setStatus] = useState('');
    const [count, setCount] = useState(0);
    const [score, setScore] = useState(0);
    const [time, setTime] = useState(0);
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const language = queryParams.get('language');
    const level = queryParams.get('level');
    const { isOpen: IsOpen, onOpen: OnOpen, onClose: OnClose } = useDisclosure()

    const handleCheck = (value) => {
        if (result === 'waiting') {
            setResult(value['index']);

            if (value['ele'] === quiz_page[language][level][count]['correctAnswer']) {
                setStatus('#00E676');
                setScore(score + 1);
            } else {
                setStatus('#EF5350');
            }
        }
    }

    const handleCount = (id) => {
        let value = count + id;

        if (value >= 0) {
            setCount(value);
            setResult('waiting');
            setStatus('');
        }
    }

    const handleSubmit = () => {
        OnOpen()

        // if (personal['token']) {
        //     const submit_time = formatTime(time);
        //     let obj = {
        //         total: quiz_page[language][level].length,
        //         currect: score,
        //         wrong: quiz_page[language][level].length - score,
        //         persentage: Math.floor(score / quiz_page[language][level].length * 100),
        //         language: language,
        //         level: level,
        //         time: submit_time,
        //     }

        //     axios.post(`http://localhost:8080/quiz/add`, obj, {
        //         headers: { "Authorization": personal['token'] }
        //     })
        //         .then((res) => {
        //             toast({ title: 'Great', status: 'success', duration: 4000, isClosable: true, position: 'top', })

        //         })
        //         .catch(() => {
        //             toast({ title: "Somthing went wrong Please try again!", status: 'error', duration: 3000, isClosable: true, position: 'top', })
        //         })
        // }
    }

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;

        if (String(hours).padStart(2, '0') !== '00') {
            return (String(hours).padStart(2, '0') + ' : ' + String(minutes).padStart(2, '0') + ' : ' + String(seconds).padStart(2, '0'))
        }
        else {
            return (String(minutes).padStart(2, '0') + ' : ' + String(seconds).padStart(2, '0'))
        }
    }


    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime((prev) => prev + 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);


    return (
        <Box minH={'100vh'} bg={'linear-gradient(30deg, rgb(0, 7, 61), rgb(0, 10, 63), rgb(0, 13, 66), rgb(0, 16, 68), rgb(0, 19, 71), rgb(0, 22, 73), rgb(0, 25, 76), rgb(0, 28, 78), rgb(0, 31, 80), rgb(0, 34, 83), rgb(0, 37, 85), rgb(0, 40, 88), rgb(0, 43, 90), rgb(0, 46, 92), rgb(0, 49, 95), rgb(0, 52, 97), rgb(0, 55, 100), rgb(0, 58, 102), rgb(0, 61, 105), rgb(0, 64, 107))'}>

            <Box display={'flex'} justifyContent={'space-between'} p={'25px'} pb={'0.5cm'} pt={'15px'}>
                <Box onClick={() => navigate(-1)} cursor={'pointer'} w={'fit-content'} display={'flex'} gap={'10px'}>
                    <Text color={'orange'} fontSize={'25px'} fontWeight={'bold'}>|| Quiz</Text>
                    <Text color={'white'} fontSize={'25px'} fontWeight={'bold'}>Time</Text>
                </Box>

                <Box fontSize={'20px'} fontWeight={'medium'} color={'white'}>
                    {formatTime(time)}
                </Box>
            </Box>

            <Box border={'2.5px solid orange'} p={'10px'} m={'auto'} mt={'0.5cm'} w={['95%', '70%', '50%', '45%']} borderRadius={'7px'}>

                <Box display={'flex'} gap={'15px'} m={'10px auto'}>
                    <Text fontSize={['14px', '16px', '18px']} color={'white'}>{count + 1}.</Text>
                    <Text fontSize={['14px', '16px', '18px']} color={'orange'} fontWeight={'bold'}>{quiz_page[language][level][count]['question']}</Text>
                </Box>

                {quiz_page && quiz_page[language][level].length != 0 && quiz_page[language][level][count]['options']?.map((ele, index) => (
                    <Box onClick={() => handleCheck({ ele, index })} fontSize={['13px', '14px', '15px']} bg={index == result && status} color={index == result ? 'black' : 'whiteAlpha.900'} key={index} _hover={status == '' && { border: '1.5px solid #00D9F8', color: '#00D9F8' }} cursor={'pointer'} transition={'0.2s ease'} fontWeight={'medium'} border={'1.5px solid white'} p={'15px 10px'} m={'15px'}>
                        {ele}
                    </Box>
                ))}

                <Box display={'flex'} justifyContent={'space-between'} m={'1cm 15px .5cm 15px'}>
                    <Box w={['80px', '100px', '150px']} bg={'white'} display={'flex'} justifyContent={'center'} alignItems={'center'} fontWeight={'medium'} borderRadius={'7px'}>{count + 1} / {quiz_page[language][level].length}</Box>

                    {count + 1 !== quiz_page[language][level].length && <Button onClick={() => handleCount(+1)} w={['80px', '100px', '150px']}>Next</Button>}
                    {count + 1 === quiz_page[language][level].length && <Button onClick={() => handleSubmit()} w={['80px', '100px', '150px']}>Submit</Button>}
                </Box>

            </Box>

            {/* ending */}
            <Modal isOpen={IsOpen}>
                <ModalOverlay />
                <ModalContent mt={'3cm'} bg={'#00001f'} boxShadow={'#373131 0px 0px 30px'} w={'92%'} borderRadius={'20px'}>
                    <ModalHeader color={'orange'} textAlign={'center'} fontWeight={'bold'}>Score Board</ModalHeader>
                    {/* <ModalCloseButton /> */}
                    <ModalBody>
                        <Box color={'orange'} m={'15px auto'} fontSize={'25px'} fontWeight={'medium'} display={'flex'} justifyContent={'center'} alignItems={'center'} border={'1px solid #00E5FF'} borderRadius={'50%'} width={'130px'} height={'130px'} boxShadow={'#00E5FF 0px 0px 30px'}>
                            {Math.floor(score / quiz_page[language][level].length * 100)}%
                        </Box>

                        <Box m={'1cm auto 0.8cm auto'}>
                            <Box w={['90%', '80%', '80%']} m={'10px auto'} color={'whiteAlpha.900'} display={'flex'} justifyContent={'space-between'}>
                                <Text>Total Question - </Text>
                                <Text>{quiz_page[language][level].length}</Text>
                            </Box>

                            <Box w={['90%', '80%', '80%']} m={'10px auto'} color={'whiteAlpha.900'} display={'flex'} justifyContent={'space-between'}>
                                <Text>Currect Answer - </Text>
                                <Text color={'#00E676'}>{score}</Text>
                            </Box>

                            <Box w={['90%', '80%', '80%']} m={'10px auto'} color={'whiteAlpha.900'} display={'flex'} justifyContent={'space-between'}>
                                <Text>Wrong Answer - </Text>
                                <Text color={'#FF5252'}>{quiz_page[language][level].length - score}</Text>
                            </Box>

                            <Button onClick={() => navigate(`/quiz/QuizSelector?language=${language}`)} w={['90%', '80%', '80%']} display={'block'} m={'20px auto'}>Continue</Button>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>

        </Box>
    )
}