import { Box, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const pathdata = [
    {
        name: 'Home',
        path: '/artkon',
    },
    {
        name: 'ColorMaterial',
        path: '/artkon/ColorMaterial',
    },
    {
        name: 'Box-Shadow',
        path: '/artkon/Box-Shadow',
    },
    {
        name: 'ColorShade',
        path: '/artkon/ColorShade',
    },
    {
        name: 'ColorPicker',
        path: '/artkon/ColorPicker',
    },
    {
        name: 'ColorConverter',
        path: '/artkon/ColorConverter',
    },
    {
        name: 'UniCode',
        path: '/artkon/UniCode',
    },
    {
        name: 'Emoji',
        path: '/artkon/Emoji',
    },
    // {
    //     name: 'DownloadMedia',
    //     path: '/artkon/DownloadMedia',
    // },
    // {
    //     name: 'SocialMediaColor',
    //     path: '/artkon/SocialMediaColor',
    // },
    {
        name: 'GradientMaker',
        path: '/artkon/GradientMaker',
    },
    {
        name: 'GradientColors',
        path: '/artkon/GradientColors',
    },
    {
        name: 'GDriveURLConverter',
        path: '/artkon/GDriveURLConverter',
    },
    {
        name: 'ClipPath',
        path: '/artkon/ClipPath',
    },
    {
        name: 'Animation',
        path: '/artkon/Animation',
    },
];

export const Sidebar = ({ setSide, side, isSmallerThan1000 }) => {
    const navigate = useNavigate()
    const currentPath = window.location.pathname;


    return (
        <Box p={'10px'} mb={'1cm'}>
            <Box display={'flex'} alignItems={'center'} fontSize={'25px'} fontWeight={'bold'} m={'0.5cm auto 1cm auto'}>
                <Text color={isSmallerThan1000 ? 'whiteAlpha.900' : 'black'} m={'0 10px'} cursor={'pointer'} onClick={() => setSide(!side)}>|||</Text>
                <Box display={'flex'} cursor={'pointer'} onClick={() => navigate('/artkon')}>
                    <Text color={'#66BB6A'}>Art</Text>
                    <Text color={'#424242'}>Korner</Text>
                </Box>
            </Box>

            {pathdata && pathdata.map((item, index) => (
                <Box key={index} onClick={() => navigate(item.path)}
                    color={currentPath === item.path ? 'white' : '#4b4848'}
                    bg={currentPath === item.path ? '#66BB6A' : 'white'}
                    boxShadow={'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'}
                    p={'10px'} fontSize={['13px', '14px', '14px']}
                    fontFamily={'monospace'}
                    fontWeight={'medium'}
                    borderRadius={'4px'}
                    textAlign={'center'}
                    cursor={'pointer'}
                    m={'10px 0px'}
                >
                    {item.name}
                </Box>
            ))}
        </Box>
    )
}