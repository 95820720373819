// import { Navbar } from "../../Components/Navbar/Navbar"
import { Box, Text, Grid, useToast } from '@chakra-ui/react'
import { box_shadow } from '../assets/data_Collection.js'
import { useNavigate } from 'react-router-dom';

export const BoxShadow = ({setSide,side}) => {
    const toast = useToast();
    const navigate = useNavigate()

    const copyToClipboard = (ele) => {
        navigator.clipboard.writeText(ele);
        toast({ title: 'Box-Shadow Copied', status: 'success', duration: 4000, isClosable: true, position: 'top' })
    }

    return (
        <Box>
            {/* <Navbar /> */}
            <Box w={'95%'} m={side?'1.4cm auto 1cm auto':'0.5cm auto'}>
                {!side && <Box display={'flex'} alignItems={'center'} fontSize={'25px'} fontWeight={'bold'} m={'0cm auto 1cm auto'}>
                    <Text ml={'10px'} cursor={'pointer'} onClick={() => setSide(!side)}>|||</Text>
                    <Text ml={'15px'} cursor={'pointer'} onClick={() => navigate('/')}>ArtKorner</Text>
                </Box>}

                <Grid templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']} gap={['30px 20px']}>
                    {box_shadow && box_shadow?.map((ele, index) => (
                        <Box onClick={() => copyToClipboard(ele)} key={index} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Box cursor={'pointer'} w={'100%'} h={'200px'} display={'flex'} justifyContent={'center'} alignItems={'center'} borderRadius={'10px'} boxShadow={ele}>#{index + 1}</Box>
                        </Box>
                    ))}
                </Grid>
            </Box>
        </Box>
    )
}
