import { Box, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { starter_products } from './starter_Data.js'


export const Starter = () => {
    const navigate = useNavigate();

    return (
        <Box>
            {/* navbar */}
            <Box w={['95%']} m={'auto'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'10px'}>
                <Box display={'flex'} gap={'7px'}>
                    <Text color={'#63ADF6'} fontSize={'25px'} fontWeight={'bold'}>|| Dev</Text>
                    <Text color={'#9E9E9E'} fontSize={'25px'} fontWeight={'bold'}>Free</Text>
                </Box>
            </Box>

            {/* banner */}
            <Box textAlign={'center'} mt={['1.5cm', '2cm', '2cm']} mb={'1cm'}>
                <Box color={'#424242'} fontSize={['30px', '40px', '50px']} fontWeight={'medium'} display={'flex'} justifyContent={'center'}>
                    <Text>Free</Text>
                    <Text ml={'10px'} color={'#63ADF6'}>Dev</Text>
                    <Text>eloper Tools</Text>
                </Box>
                <Text color={'#616161'} fontSize={['20px']}>Made for you</Text>
            </Box>

            {/* content */}
            <Box p={'1cm 0'} borderRadius={'20px 20px 0 0'}>
                <Box display={'grid'} gridTemplateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={'10px'} w={'95%'} m={'auto'}>
                    {starter_products && starter_products?.map((ele, index) => (
                        <Box onClick={() => navigate(ele['url'])} display={'flex'} flexDirection={'column'} textAlign={'center'} key={index} w={'100%'} minH={'300px'} p={'20px'} cursor={'pointer'} boxShadow={index === 1 && 'rgba(145, 158, 171, 0.16) -40px 40px 80px'}>
                            <Box h={'100px'}>
                                {/* blanck content will added soon */}
                            </Box>
                            <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
                                <Box fontSize={'25px'} color={'#424242'} fontWeight={'bold'}>{ele.title}</Box>
                                <Box w={'50px'} m={'auto'} h={'2px'} bg={'#63ADF6'} transition={"0.5s"} _hover={{ w: '70px' }}></Box>
                                <Box fontSize={'14px'} color={'#b3b0b0'}>{ele.description}</Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};