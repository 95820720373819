import { Box, Text, Grid, useToast } from '@chakra-ui/react'
import { red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green, lightGreen, lime, yellow, amber, orange, deepOrange, brown, grey, blueGrey } from '../assets/materialColor.js'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const colorsteps = [red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green, lightGreen, lime, yellow, amber, orange, deepOrange, brown, grey, blueGrey]

export const ColorMaterial = ({ setSide, side }) => {
    const [category, setCategory] = useState('hex')
    const navigate = useNavigate()
    const toast = useToast()

    const copyToClipboard = (ele) => {
        navigator.clipboard.writeText(ele);
        toast({ title: 'Color copied', description: ele, status: 'success', duration: 1000, isClosable: true, position: 'top' })
    }

    return (
        <Box m={'0.5cm auto 0.7cm auto'} >

            <Box w={'100%'} display={'flex'} justifyContent={'space-between'} m={'10px auto'}>
                <Box>
                    {!side && <Box display={'flex'} alignItems={'center'} fontSize={['20px', '25px']} fontWeight={'bold'} m={'0cm auto'}>
                        <Text ml={'10px'} cursor={'pointer'} onClick={() => setSide(!side)}>|||</Text>
                        <Text ml={'15px'} cursor={'pointer'} onClick={() => navigate('/')}>ArtKorner</Text>
                    </Box>}
                </Box>

                <Box display={'flex'} >
                    <Text onClick={() => setCategory('hex')} borderBottom={category === 'hex' && '1px solid grey'} p={'3px 6px'} cursor={'pointer'} fontWeight={'medium'} color={'grey'}>HEX</Text>
                    <Text onClick={() => setCategory('rgb')} borderBottom={category === 'rgb' && '1px solid grey'} p={'3px 6px'} cursor={'pointer'} fontWeight={'medium'} color={'grey'}>RGB</Text>
                    <Text onClick={() => setCategory('hsl')} borderBottom={category === 'hsl' && '1px solid grey'} p={'3px 6px'} cursor={'pointer'} fontWeight={'medium'} color={'grey'}>HSL</Text>
                </Box>
            </Box>

            <Box display={['block', 'block', 'block', 'flex', 'flex']} m={'0 auto'}>

                {colorsteps && colorsteps?.map((item, index) => (
                    <Grid templateColumns="repeat(auto-fill, 50px)" m={['20px 0', '20px 0', '20px 0', '20px 0', '0']}>
                        {item && item?.map((ele, index) => (
                            <Box key={index} onClick={() => copyToClipboard(ele[category])}>
                                <Box cursor={'pointer'} w={'100%'} h={'35px'} bg={ele.hex} color={'white'}></Box>
                            </Box>
                        ))}
                    </Grid>
                ))
                }
            </Box>

            {/* <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} m={'0.5cm auto'}>
                <select onChange={(e) => setCategory(e.target.value)} style={{ width: '200px', fontSize: '14px', padding: '10px', border: '1px solid grey', borderRadius: '25px' }}>
                    <option value="hex">HEX</option>
                    <option value="rgb">RGB</option>
                    <option value="hsl">HSL</option>
                </select>
            </Box> */}

        </Box>
    )
}