export const animation = [
    {
        ID: 0,
        HTML_ORG:
            `<div style={{width:'200px',height:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div class="container">
        <div class="text">
        <span>H</span>
        <span>e</span>
        <span>l</span>
        <span>l</span>
        <span>o</span>
        </div>
        </div>
        </div>`,
        HTML:
            `<center>
            <div class="container">
            <div class="text">
            <span>H</span>
            <span>e</span>
            <span>l</span>
            <span>l</span>
            <span>o</span>
            </div>
            </div>
            </center>`,
        CSS: `
       
        .container {
            transform-style: preserve-3d;
            animation: rotate 5s infinite linear;
        }
        
        .text {
            position: relative;
            transform-style: preserve-3d;
            transform: rotateY(45deg);
        }
        
        .text span {
            display: inline-block;
            transform: translateZ(50px);
            font-size: 22px;
            font-weight: bold;
            color: #29B6F6;
            margin: 0 5px;
            animation: move 5s alternate infinite ease-in-out;
        }
        
        @keyframes move {
            0% {
                transform: translateZ(50px);
            }
            100% {
                transform: translateZ(-50px);
            }
        }
        
        @keyframes rotate {
            0% {
                transform: rotateY(0deg);
            }
            100% {
                transform: rotateY(360deg);
            }
        }`
    },
    {
        ID: 1,
        HTML_ORG: `
        <div style={{width:'200px',height:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div class="letter_0">Hi</div>
        </div>`,
        HTML:
            `<div class="letter_0">Hi</div>`,
        CSS: `
        @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
                transform: translateY(0);
            }
            40% {
                transform: translateY(-30px);
            }
            60% {
                transform: translateY(-15px);
            }
        }

        .letter_0{
            font-size: 62px;
            color: #D500F9;
            animation: bounce 2s infinite;
        }

      `
    },
    {
        ID: 2,
        HTML_ORG: `
        <div style={{width:'200px',height:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div class="letter_1">Y</div>
        </div>
        `,
        HTML:
            `<div class="letter_1">Y</div>`,
        CSS: `
        @keyframes rotate {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        .letter_1 {
            font-size: 72px;
            color: #FDD835;
            animation: rotate 4s linear infinite;
        }`
    },
    {
        ID: 3,
        HTML_ORG: `
        <div style={{width:'200px',height:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div class="letter_2">M</div>
        </div>
        `,
        HTML:
            `<div class="letter_2">M</div>`,
        CSS: `
        @keyframes rotate_1 {
            0% {
                transform: rotate(360deg);
            }
            100% {
                transform: rotate(0deg);
            }
        }

        .letter_2 {
            font-size: 72px;
            color: #4CAF50;
            animation: rotate_1 4s linear infinite;
        }`
    },
    {
        ID: 4,
        HTML_ORG: `
        <div style={{width:'200px',height:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div class="letter_3">C</div>
        </div>
        `,
        HTML:
            `<div class="letter_3">C</div>`,
        CSS: `
        @keyframes moveDiagonal {
            0% {
                transform: translate(0, 0);
            }
            25% {
                transform: translate(50px, 50px);
            }
            50% {
                transform: translate(100px, 0);
            }
            75% {
                transform: translate(50px, -50px);
            }
            100% {
                transform: translate(0, 0);
            }
        }

        .letter_3 {
            font-size: 72px;
            color: #EF5350;
            animation: moveDiagonal 4s linear infinite;
        }`
    },
    {
        ID: 5,
        HTML_ORG: `
        <div style={{width:'200px',height:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div class="letter_4">D</div>
        </div>
        `,
        HTML:
            `<div class="letter_4">D</div>`,
        CSS: `
        @keyframes scaleUpDown {
            0%, 100% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.3);
            }
        }

        .letter_4 {
            display: flex;
            font-size: 72px;
            color: #6200EA;
            animation: scaleUpDown 2s ease-in-out infinite;
        }`
    },
    {
        ID: 6,
        HTML_ORG: `
        <div style={{width:'200px',height:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div class="letter_5">E</div>
        </div>
        `,
        HTML:
            `<div class="letter_5">E</div>`,
        CSS: `
        @keyframes pulse {
            0%, 100% {
                opacity: 1;
            }
            50% {
                opacity: 0.5;
            }
        }

        .letter_5 {
            font-size: 72px;
            color: #004D40;
            animation: pulse 2s ease-in-out infinite;
        }`
    },
    {
        ID: 7,
        HTML_ORG: `
        <div style={{width:'200px',height:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div class="letter_6">F</div>
        </div>
        `,
        HTML:
            `<div class="letter_6">F</div>`,
        CSS: `
        @keyframes rotateAndColorChange {
            0% {
                transform: rotate(0deg);
                color: #E91E63;
            }
            25% {
                transform: rotate(90deg);
                color: #2196F3;
            }
            50% {
                transform: rotate(180deg);
                color: #00BCD4;
            }
            75% {
                transform: rotate(270deg);
                color: #FFA000;
            }
            100% {
                transform: rotate(360deg);
                color: #E040FB;
            }
        }

        .letter_6 {
            font-size: 72px;
            padding: 10px;
            border-radius: 10px;
            color: white;
            animation: rotateAndColorChange 4s linear infinite;
        }`
    },
    {
        ID: 8,
        HTML_ORG: `
        <div style={{width:'200px',height:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div class="letter_7">G</div>
        </div>
        `,
        HTML:
            `<div class="letter_7">G</div>`,
        CSS: `
        @keyframes bounceAndColorChange {
            0%, 100% {
                transform: translateY(0);
                background-color: #ff5733; /* Initial background color */
            }
            50% {
                transform: translateY(-30px);
                background-color: #33ff57; /* Midway background color */
            }
        }

        .letter_7 {
            font-size: 72px;
            padding: 20px;
            color: white;
            border-radius: 10px;
            animation: bounceAndColorChange 2s ease-in-out infinite alternate;
            display: inline-block;
        }`
    },
    {
        ID: 9,
        HTML_ORG: `
        <div style={{width:'200px',height:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div class="shape"></div>
        </div>
        `,
        HTML:
            `<div class="shape"></div>`,
        CSS: `
        @keyframes rotateAndColorChange {
            0% {
                transform: rotate(0deg);
                background-color: #ff5733; /* Initial background color */
            }
            25% {
                transform: rotate(90deg);
                background-color: #33ff57; /* Midway background color */
            }
            50% {
                transform: rotate(180deg);
                background-color: #5733ff; /* Midway background color */
            }
            75% {
                transform: rotate(270deg);
                background-color: #ff3357; /* Midway background color */
            }
            100% {
                transform: rotate(360deg);
                background-color: #33ffdd; /* Final background color */
            }
        }

        .shape {
            width: 100px;
            height: 100px;
            animation: rotateAndColorChange 4s linear infinite;
        }`
    },
    {
        ID: 10,
        HTML_ORG: `
        <div style={{width:'200px',height:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div class="container_1">
        <div class="shape_1"></div>
        <div class="shape_1"></div>
        <div class="shape_1"></div>
        </div>
        </div>
        `,
        HTML:
            `<div class="container_1">
            <div class="shape_1"></div>
            <div class="shape_1"></div>
            <div class="shape_1"></div>
            </div>`,
        CSS: `
        @keyframes rotateAndColorChange {
            0% {
                transform: rotate(0deg);
                background-color: #ff5733;
            }
            25% {
                transform: rotate(90deg);
                background-color: #33ff57;
            }
            50% {
                transform: rotate(180deg);
                background-color: #5733ff;
            }
            75% {
                transform: rotate(270deg);
                background-color: #ff3357;
            }
            100% {
                transform: rotate(360deg);
                background-color: #33ffdd;
            }
        }

        .shape_1 {
            width: 40px;
            height: 40px;
            margin: 10px;
            animation: rotateAndColorChange 4s linear infinite alternate;
            display: inline-block;
        }

        .container_1 {
            text-align: center;
        }`
    },
    {
        ID: 11,
        HTML_ORG: `
        <div style={{width:'200px',height:'200px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div class="container_2">
        <div class="circle_1"></div>
        <div class="circle_1"></div>
        <div class="circle_1"></div>
        </div>
        </div>
        `,
        HTML:
            `<div class="container_2">
            <div class="circle_1"></div>
            <div class="circle_1"></div>
            <div class="circle_1"></div>
        </div>`,
        CSS: `
        @keyframes rotateAndColorChange {
            0% {
                transform: rotate(0deg);
                background-color: #ff5733;
            }
            25% {
                transform: rotate(90deg);
                background-color: #33ff57;
            }
            50% {
                transform: rotate(180deg);
                background-color: #5733ff;
            }
            75% {
                transform: rotate(270deg);
                background-color: #ff3357;
            }
            100% {
                transform: rotate(360deg);
                background-color: #33ffdd;
            }
        }

        .circle_1 {
            width: 45px;
            height: 45px;
            border-radius: 40%;
            margin: 10px;
            animation: rotateAndColorChange 4s linear infinite alternate;
            display: inline-block;
        }

        .container_2 {
            text-align: center;
        }`
    },
];


