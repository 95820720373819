import { Route, Routes } from 'react-router-dom'
import { SkeletonPage } from '../../Skeleton/SkeletonPage/SkeletonPage'
import { SkeletonCard } from '../../Skeleton/SkeletonCard/SkeletonCard'
import { useEffect } from 'react';



export const SkeletonRouter = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the new page
    }, []);

    return (
        <Routes>
            <Route path='/' element={<SkeletonPage />}></Route>
            <Route path='/card/:id' element={<SkeletonCard />}></Route>
        </Routes>
    )
}