import { Box, Text, Grid, useToast } from '@chakra-ui/react'
import React from 'react';
import { animation } from '../../BackData/animation.js'
import { useNavigate } from 'react-router-dom';


export const Animation = ({ setSide, side }) => {
    const toast = useToast();
    const navigate = useNavigate()

    return (
        <Box>
            {/* <Navbar /> */}
            <Box w={['95%', '90%', '80%']} m={side ? '1.4cm auto 1cm auto' : '0.5cm auto'}>
                {!side && <Box display={'flex'} alignItems={'center'} fontSize={'25px'} fontWeight={'bold'} m={'0cm auto 1cm auto'}>
                    <Text m={'0 10px'} cursor={'pointer'} onClick={() => setSide(!side)}>|||</Text>
                    {/* <Text ml={'15px'} cursor={'pointer'} onClick={() => navigate('/')}>ArtKorner</Text> */}
                    <Box display={'flex'} cursor={'pointer'} onClick={() => navigate('/artkon')}>
                        <Text color={'#66BB6A'}>Art</Text>
                        <Text color={'#424242'}>Korner</Text>
                    </Box>
                </Box>}

                <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={['30px 20px']}>
                    {animation && animation?.map((ele, index) => (
                        <Box key={index} onClick={() => navigate(`/artkon/AnimationPage/${ele.ID}`)} cursor={'pointer'} boxShadow={index % 2 === 1 && 'rgba(145, 158, 171, 0.16) -40px 40px 80px'} p={'1.5cm 10px'}>
                            <style>
                                {ele.CSS}
                            </style>
                            <Box display={'flex'} justifyContent={'center'} justifyItems={'center'}
                                key={index}
                                dangerouslySetInnerHTML={{ __html: ele.HTML }} />
                        </Box>
                    ))}
                </Grid>

            </Box>
        </Box>
    );
};

