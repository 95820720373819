import { Box, Text, Grid, useToast } from '@chakra-ui/react'
import { all_color_shades } from '../assets/data_Collection.js'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'


export const ColorShade = ({ setSide, side }) => {
    // const [category, setCategory] = useState('hex')
    const navigate = useNavigate()
    const toast = useToast()

    const copyToClipboard = (ele) => {
        navigator.clipboard.writeText(ele);
        toast({ title: 'Color Copied', description: ele, status: 'success', duration: 1000, isClosable: true, position: 'top' })
    }

    return (
        <Box w={'95%'} m={side ? '1cm auto' : '0.5cm auto'}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} m={'0.5cm auto'}>
                <Box>{!side && <Box display={'flex'} alignItems={'center'} fontSize={['20px', '25px']} fontWeight={'bold'} m={'0.5cm auto 0.5cm auto'}>
                    <Text ml={'10px'} cursor={'pointer'} onClick={() => setSide(!side)}>|||</Text>
                    <Text ml={'15px'} cursor={'pointer'} onClick={() => navigate('/')}>ArtKorner</Text>
                </Box>}
                </Box>
                {/* <select onChange={(e) => setCategory(e.target.value)} style={{ width: '150px', fontSize: '14px', padding: '10px', border: '1px solid grey', borderRadius: '25px' }}>
                    <option value="hex">HEX</option>
                    <option value="rgb">RGB</option>
                    <option value="hsl">HSL</option>
                </select> */}
            </Box>

            <Grid templateColumns={['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']} gap={['30px 20px']}>
                {all_color_shades && all_color_shades?.map((ele, index) => (
                    <Box key={index} onClick={() => copyToClipboard(ele)} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Box cursor={'pointer'} w={'100%'} h={'180px'} display={'flex'} justifyContent={'center'} alignItems={'center'} bg={ele} color={'white'}>#{index + 1}</Box>
                    </Box>
                ))}
            </Grid>
        </Box>
    )
}