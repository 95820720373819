export const emoji_data = [
    "😄", "😃", "😀", "😁", "😆", "😅", "🤣", "😂", "🙂", "🙃", "😉", "😊", "😇", "😍", "🥰", "😋", "😛", "😜", "🤪", "😝", "🤑", "🤗", "🤩", "🤔", "🤨", "😐", "😑", "😶", "🙄", "😏", "😒", "🙁", "😔", "😕", "🙃", "🙂", "😉", "😊",
    "😇", "😍", "🥰", "😋", "😛", "😜", "🤪", "😝", "🤑", "🤗", "🤩", "😎", "🤓", "🧐", "😕", "😟", "🙁", "☹️", "😮", "😯", "😲", "😳", "🥺", "😦", "😧", "😨", "😰", "😥", "😢", "😭", "😱", "😖", "😣", "😞", "😓", "😩", "😫", "🥱",
    "😤", "😡", "😠", "🤬", "😷", "🤒", "🤕", "🤢", "🤮", "🤧", "😇", "🥳", "🥴", "🥵", "🥶", "😈", "👿", "👹", "👺", "💀", "👻", "👽", "👾", "🤖", "👋", "🤙", "🖖", "🤟", "✌️", "🤘", "🤞", "🤜", "🤛", "👏", "🙌", "👐", "🤲", "🤝", "🙏",
    "✍️", "💅", "🤳", "💪", "🦾", "🦵", "🦿", "🦶", "👂", "🦻", "👃", "👁️‍🗨️", "👀", "👄", "💋", "👅", "👶", "🧒", "👦", "👧", "🧑", "👱", "👨", "👩", "🧔", "👵", "👴", "👲", "👳", "👮", "👷", "💂", "🕵️", "👩‍⚕️", "👨‍⚕️", "👩‍🌾", "👨‍🌾"
]

export const body_emoji_data = [
    "👶", "👦", "👧", "🧒", "👩‍🦱", "👨‍🦱", "👩‍🦰", "👨‍🦰", "👱‍♀️", "👱‍♂️", "👩‍🦳", "👨‍🦳", "👩‍🦲", "👨‍🦲", "🧔", "👵", "🧓", "👴", "👲", "👳‍♀️", "👳‍♂️", "🧕", "🧔", "👮‍♀️", "👮‍♂️", "👷‍♀️", "👷‍♂️", "💂‍♀️", "💂‍♂️", "🕵️‍♀️", "🕵️‍♂️", "👩‍⚕️", "👨‍⚕️", "👩‍🌾", "👨‍🌾", "👩‍🍳", "👨‍🍳",
    "👩‍🎓", "👨‍🎓", "👩‍🎤", "👨‍🎤", "👩‍🏫", "👨‍🏫", "👩‍🏭", "👨‍🏭", "👩‍💻", "👨‍💻", "👩‍💼", "👨‍💼", "👩‍🔧", "👨‍🔧", "👩‍🔬", "👨‍🔬", "👩‍🎨", "👨‍🎨", "👩‍🚒", "👨‍🚒", "👩‍✈️", "👨‍✈️", "👩‍🚀", "👨‍🚀", "👩‍⚖️", "👨‍⚖️", "👰", "🤵", "👸", "🤴", "👷‍♀️", "👷‍♂️", "🕺", "💃", "🕴️", "🧖‍♀️", "🧖‍♂️", "🧗‍♀️", "🧗‍♂️", "🏃‍♀️", "🏃‍♂️", "🚶‍♀️",
    "🚶‍♂️", "🧍‍♀️", "🧍‍♂️", "🧎‍♀️", "🧎‍♂️", "🧘‍♀️", "🧘‍♂️", "🛀", "🛌"
]

export const animal_emojis_data = [
    "🐶", "🐱", "🐭", "🐹", "🐰", "🦊", "🦝", "🐻", "🐼", "🦄", "🐴", "🦓", "🦌", "🐮", "🐷", "🐽", "🐗", "🦛", "🐏", "🐑",
    "🐐", "🐪", "🐫", "🦙", "🦒", "🐘", "🦏", "🦍", "🦌", "🐎", "🐖", "🐃", "🐄", "🐕", "🐩", "🐾", "🐈", "🐅", "🐆", "🐊",
    "🐍", "🐲", "🐉", "🐳", "🐋", "🐬", "🐟", "🐠", "🐡", "🦈", "🐙", "🐚", "🐌", "🦋", "🐛", "🐜", "🐝", "🐞", "🦗", "🕷️",
    "🕸️", "🦂", "🦟", "🦠", "🐢", "🦕", "🦖", "🐍", "🐲", "🐉", "🐊", "🐢", "🦎", "🐍", "🐲", "🐉", "🦕", "🦖", "🐍", "🐲",
    "🐉", "🦕", "🦖", "🕊️", "🦅", "🦆", "🦢", "🦉", "🦚", "🦜", "🦩", "🦗", "🕷️", "🕸️", "🦂", "🦟", "🐞", "🦗", "🕷️", "🕸️",
    "🦂", "🦟", "🦠"
]

export const food_emojis_data = [
    "🍏", "🍎", "🍐", "🍊", "🍋", "🍌", "🍉", "🍇", "🍓", "🍈", "🍒", "🍑", "🍍", "🥭", "🥥", "🥝", "🍅", "🍆", "🥑", "🥦",
    "🥒", "🌽", "🥕", "🧄", "🧅", "🥔", "🍠", "🥐", "🥯", "🍞", "🥖", "🥨", "🧀", "🥚", "🍳", "🧈", "🥞", "🧇", "🥓", "🥩",
    "🍗", "🍖", "🌭", "🍔", "🍟", "🍕", "🥪", "🥙", "🧆", "🌮", "🌯", "🥗", "🥘", "🍝", "🍜", "🍲", "🍛", "🍣", "🍱", "🥟",
    "🍤", "🍙", "🍚", "🍘", "🍥", "🥠", "🥮", "🍢", "🍡", "🍧", "🍨", "🍦", "🥧", "🍰", "🎂", "🧁", "🥩", "🍮", "🍬", "🍭",
    "🍫", "🍿", "🍩", "🍪", "🌰", "🥜", "🍯", "🥛", "🍼", "☕", "🍵", "🍶", "🍾", "🍷", "🍸", "🍹", "🍺", "🍻", "🥂", "🥃",
    "🍽️", "🍴", "🥄", "🍽️", "🍴", "🥄", "🍽️", "🍴", "🥄", "🍽️", "🍴", "🥄", "🥤", "🍦", "🧁", "🍩", "🍪", "🍰", "🎂", "🥧",
    "🍮", "🍬", "🍭", "🍫", "🍿", "🍯", "🥛", "🍼", "☕", "🍵", "🍶", "🍾", "🍷", "🍸", "🍹", "🍺", "🍻", "🥂", "🥃"
]

export const activities_emojis_data = [
    "⚽", "🏀", "🏈", "⚾", "🥎", "🎾", "🏐", "🏉", "🎱", "🥏", "🪀", "🏓", "🏸", "🏒", "🏑", "🥍", "🏏", "🪃", "🥅", "⛳",
    "🪁", "🏹", "🎣", "🤿", "🥊", "🥋", "🎽", "🛹", "🛷", "🪂", "⛸️", "🥌", "🎿", "⛷️", "🏂", "🪂", "🏋️", "🏋️‍♂️", "🏋️‍♀️", "🏋️",
    "🏋️‍♂️", "🏋️‍♀️", "🏌️", "🏌️‍♂️", "🏌️‍♀️", "🏌️", "🏌️‍♂️", "🏌️‍♀️", "🏇", "🏇", "🏇‍♂️", "🏇‍♀️", "🏇", "🏇‍♂️", "🏇‍♀️", "🧘", "🧘‍♂️", "🧘‍♀️", "🧘",
    "🧘‍♂️", "🧘‍♀️", "🎪", "🛶", "🎠", "🎡", "🎢", "🎪", "🚣", "🛶", "🎠", "🎡", "🎢", "🎭", "🎨", "🎤", "🎧", "🎼", "🎹",
    "🥁", "🎷", "🎺", "🎸", "🪕", "🎻", "🎬", "🎮", "🕹️", "🎯", "🎲", "🎰", "🧩", "🚗", "🚕", "🚆", "🛻", "🚜", "🏍️", "🛵",
    "🛺", "🚝", "🚄", "🚅", "🚈", "🚂", "🚆", "🚇", "🚊", "🚉", "🚁", "🛩️", "✈️", "🛫", "🛬", "🚀", "🛰️", "💺", "🛶", "🛦",
    "🛥️", "🚤", "⛵", "🛴", "🚲", "🛵", "🏍️", "🛺", "🚗", "🚕", "🚆", "🛻", "🚜", "🚝", "🚄", "🚅", "🚈", "🚂", "🚆", "🚇",
    "🚊", "🚉", "🚁", "🛩️", "✈️", "🛫", "🛬", "🚀", "🛰️", "💺", "🛶", "🛦", "🛥️", "🚤", "⛵", "🛴", "🚲"
]


export const travel_emojis_data = [
    "🚗", "🚕", "🚆", "🛻", "🚜", "🏍️", "🛵", "🛺", "🚝", "🚄", "🚅", "🚈", "🚂", "🚆", "🚇", "🚊", "🚉", "🚁", "🛩️", "✈️",
    "🛫", "🛬", "🚀", "🛰️", "💺", "🛶", "🛦", "🛥️", "🚤", "⛵", "🛴", "🚲", "🚏", "🛣️", "🛤️", "🛢️", "⛽", "🚧", "🚦", "🚥",
    "🛑", "🎡", "🎢", "🎠", "⛱️", "🏖️", "🏝️", "⛰️", "🏔️", "🗻", "🌋", "🏜️", "🏕️", "⛺", "🏞️", "🛶", "🛦", "🛥️", "🚤", "⛵",
    "🛴", "🚲", "🚏", "🛣️", "🛤️", "🛢️", "⛽", "🚧", "🚦", "🚥", "🛑", "🎡", "🎢", "🎠", "⛱️", "🏖️", "🏝️", "⛰️", "🏔️", "🗻",
    "🌋", "🏜️", "🏕️", "⛺", "🏞️", "🏟️", "🎭", "🎨", "🎰", "🎪", "🎤", "🎧", "🎼", "🎹", "🥁", "🎷", "🎺", "🎸", "🪕", "🎻",
    "🎬", "🎮", "🕹️", "🎯", "🎲", "🎳", "🎾", "🎳", "🏀", "🏐", "🏈", "🏉", "🎱", "🥏", "🪀", "🏓", "🏸", "🏒", "🏑", "🥍",
    "🏏", "🪃", "🥅", "⛳", "🪁", "🏹", "🎣", "🤿"
]

export const object_emojis_data = [
    "⌚", "📱", "💻", "⌨️", "🖥️", "🖨️", "🖱️", "🖲️", "🕹️", "🗜️", "💽", "💾", "💿", "📀", "📼", "📷", "📸", "📹", "🎥", "🎞️",
    "📽️", "🎬", "📺", "📻", "🎙️", "🎚️", "🎛️", "🧭", "⏱️", "⏲️", "⏰", "🕰️", "⌛", "⏳", "📡", "🔋", "🔌", "💡", "🔦", "🕯️",
    "🪔", "🧯", "🛢️", "💸", "💵", "💴", "💶", "💷", "💰", "💳", "💎", "⚖️", "🧰", "🔧", "🔨", "⚒️", "🛠️", "🔩", "⛏️", "⚙️",
    "🧲", "🧪", "🧫", "🧬", "🔬", "🔭", "📡", "💉", "💊", "🚪", "🛏️", "🛋️", "🪑", "🚽", "🚿", "🛁", "🧴", "🧻", "🪒", "🧼",
    "🧽", "🧹", "🧺", "🧦", "🧤", "🧣", "🎩", "🧢", "👓", "🕶️", "🥽", "🥼", "🦺", "👔", "👕", "👖", "🧥", "👗", "👘", "👙",
    "🩱", "👚", "👛", "👜", "👝", "🎒", "👞", "👟", "🥾", "🥿", "👠", "👡", "🩰", "👢", "👑", "👒", "🎩", "🎓", "⛑️", "📿",
    "💄", "💍", "💎", "🔮", "🧢", "👑", "🛍️", "🎈", "🎉", "🎊", "🎎", "🎏", "🎐", "🧧", "🎀", "🎁", "🕹️", "🔮", "🧦", "🧤",
    "🧣", "🎩", "🧢", "👑", "🛍️", "🎈", "🎉", "🎊", "🎎", "🎏", "🎐", "🧧", "🎀", "🎁", "🎗️", "🎟️", "🎫", "🎖️", "🏆", "🏴", "🏳", "🏳️‍⚧️", "🏁"
]

export const symbol_emojis_data = [
    "❤️", "🧡", "💛", "💚", "💙", "💜", "🖤", "🤍", "🤎", "❣️", "💕", "💞", "💓", "💗", "💖", "💘", "💝", "💟", "☮️", "✝️",
    "☪️", "🕉️", "☸️", "✡️", "🔯", "🕎", "☯️", "☦️", "🛐", "⛎", "♈", "♉", "♊", "♋", "♌", "♍", "♎", "♏", "♐", "♑",
    "♒", "♓", "🆔", "⚛️", "🉑", "☢️", "☣️", "📴", "📳", "🈶", "🈚", "🈸", "🈺", "🈷️", "✴️", "🆚", "💮", "🉐", "㊙️", "㊗️",
    "🈴", "🈵", "🔰", "🔱", "📛", "🔳", "🔲", "▪️", "▫️", "◾", "◽", "◼️", "◻️", "⬛", "⬜", "🔶", "🔷", "🔸", "🔹", "🔺",
    "🔻", "💠", "🔘", "🔳", "🔲", "🏁", "🚩", "🎌", "🏴", "🏳️", "🏳️‍🌈", "🏳️‍⚧️", "🇦🇨", "🇦🇩", "🇦🇪", "🇦🇫", "🇦🇬", "🇦🇮", "🇦🇱", "🇦🇲",
    "🇦🇴", "🇦🇶", "🇦🇷", "🇦🇸", "🇦🇹", "🇦🇺", "🇦🇼", "🇦🇽", "🇦🇿", "🇧🇦", "🇧🇧", "🇧🇩", "🇧🇪", "🇧🇫", "🇧🇬", "🇧🇭", "🇧🇮", "🇧🇯", "🇧🇱", "🇧🇲",
    "🇧🇳", "🇧🇴", "🇧🇶", "🇧🇷", "🇧🇸", "🇧🇹", "🇧🇻", "🇧🇼", "🇧🇾", "🇧🇿", "🇨🇦", "🇨🇨", "🇨🇩", "🇨🇫", "🇨🇬", "🇨🇭", "🇨🇮", "🇨🇰", "🇨🇱", "🇨🇲",
    "🇨🇳", "🇨🇴", "🇨🇵", "🇨🇷", "🇨🇺", "🇨🇻", "🇨🇼", "🇨🇽", "🇨🇾", "🇨🇿"
]

export const country_flag_emojis = [
    "🏴", "🏳", "🏳️‍⚧️", "🏁", "🇦🇨", "🇦🇩", "🇦🇪", "🇦🇫", "🇦🇬", "🇦🇮", "🇦🇱", "🇦🇲", "🇦🇴", "🇦🇶", "🇦🇷", "🇦🇸", "🇦🇹", "🇦🇺", "🇦🇼", "🇦🇽", "🇦🇿", "🇧🇦", "🇧🇧", "🇧🇩", "🇧🇪", "🇧🇫", "🇧🇬", "🇧🇭", "🇧🇮", "🇧🇯", "🇧🇱", "🇧🇲", "🇧🇳", "🇧🇴", "🇧🇶", "🇧🇷", "🇧🇸", "🇧🇹", "🇧🇻", "🇧🇼", "🇧🇾", "🇧🇿", "🇨🇦", "🇨🇨", "🇨🇩", "🇨🇫", "🇨🇬", "🇨🇭", "🇨🇮", "🇨🇰", "🇨🇱", "🇨🇲", "🇨🇳", "🇨🇴",
    "🇨🇵", "🇨🇷", "🇨🇺", "🇨🇻", "🇨🇼", "🇨🇽", "🇨🇾", "🇨🇿", "🇩🇪", "🇩🇬", "🇩🇯", "🇩🇰", "🇩🇲", "🇩🇴", "🇩🇿", "🇪🇦", "🇪🇨", "🇪🇪", "🇪🇬", "🇪🇭", "🇪🇷", "🇪🇸", "🇪🇹", "🇪🇺", "🇫🇮", "🇫🇯", "🇫🇰", "🇫🇲", "🇫🇴", "🇫🇷", "🇬🇦", "🇬🇧", "🇬🇩", "🇬🇪", "🇬🇫", "🇬🇬", "🇬🇭", "🇬🇮", "🇬🇱", "🇬🇲",
    "🇬🇳", "🇬🇵", "🇬🇶", "🇬🇷", "🇬🇸", "🇬🇹", "🇬🇺", "🇬🇼", "🇬🇾", "🇭🇰", "🇭🇲", "🇭🇳", "🇭🇷", "🇭🇹", "🇭🇺", "🇮🇨", "🇮🇩", "🇮🇪", "🇮🇱", "🇮🇲", "🇮🇳", "🇮🇴", "🇮🇶", "🇮🇷", "🇮🇸", "🇮🇹", "🇯🇪", "🇯🇲", "🇯🇴", "🇯🇵", "🇰🇪", "🇰🇬", "🇰🇭", "🇰🇮", "🇰🇲", "🇰🇳", "🇰🇵", "🇰🇷", "🇰🇼", "🇰🇾", "🇰🇿", "🇱🇦", "🇱🇧", "🇱🇨", "🇱🇮", "🇱🇰", "🇱🇷", "🇱🇸", "🇱🇹", "🇱🇺",
    "🇱🇻", "🇱🇾", "🇲🇦", "🇲🇨", "🇲🇩", "🇲🇪", "🇲🇫", "🇲🇬", "🇲🇭", "🇲🇰", "🇲🇱", "🇲🇲", "🇲🇳", "🇲🇴", "🇲🇵", "🇲🇶", "🇲🇷", "🇲🇸", "🇲🇹", "🇲🇺", "🇲🇻", "🇲🇼", "🇲🇽", "🇲🇾", "🇲🇿", "🇳🇦", "🇳🇨", "🇳🇪", "🇳🇫", "🇳🇬", "🇳🇮", "🇳🇱", "🇳🇴", "🇳🇵", "🇳🇷", "🇳🇺", "🇳🇿", "🇴🇲", "🇵🇦", "🇵🇪",
    "🇵🇫", "🇵🇬", "🇵🇭", "🇵🇰", "🇵🇱", "🇵🇲", "🇵🇳", "🇵🇷", "🇵🇸", "🇵🇹", "🇵🇼", "🇵🇾", "🇶🇦", "🇷🇪", "🇷🇴", "🇷🇸", "🇷🇺", "🇷🇼", "🇸🇦", "🇸🇧", "🇸🇨", "🇸🇩", "🇸🇪", "🇸🇬", "🇸🇭", "🇸🇮", "🇸🇯", "🇸🇰", "🇸🇱", "🇸🇲", "🇸🇳", "🇸🇴", "🇸🇷", "🇸🇸", "🇸🇹", "🇸🇻", "🇸🇽", "🇸🇾", "🇸🇿", "🇹🇦", "🇹🇨", "🇹🇩", "🇹🇫", "🇹🇬", "🇹🇭", "🇹🇯", "🇹🇰", "🇹🇱", "🇹🇲", "🇹🇳",
    "🇹🇴", "🇹🇷", "🇹🇹", "🇹🇻", "🇹🇼", "🇹🇿", "🇺🇦", "🇺🇬", "🇺🇲", "🇺🇳", "🇺🇸", "🇺🇾", "🇺🇿", "🇻🇦", "🇻🇨", "🇻🇪", "🇻🇬", "🇻🇮", "🇻🇳", "🇻🇺",
    "🇼🇫", "🇼🇸", "🇽🇰", "🇾🇪", "🇾🇹", "🇿🇦", "🇿🇲", "🇿🇼"
]
