import { Box, Text, useToast } from '@chakra-ui/react'
import React, { useState } from "react";
import { SketchPicker } from "react-color"; // Importing the SketchPicker component
import "./ColorPicker.css";
import { useNavigate } from 'react-router-dom';

export const ColorPicker = ({ setSide, side }) => {
  const [selectedColor, setSelectedColor] = useState({ hex: '#d93b3b', rgb: 'rgb(217, 59, 59)', hsl: 'hsl(0, 67.5%, 54.1%)' });
  const toast = useToast();
  const navigate = useNavigate()


  const handleColorChange = (color) => {
    setSelectedColor((prevData) => ({ ...prevData, ['hex']: color.hex }));
    setSelectedColor((prevData) => ({ ...prevData, ['rgb']: `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})` }));
    setSelectedColor((prevData) => ({ ...prevData, ['hsl']: `hsl(${Math.round(color.hsl.h)}, ${Math.round(color.hsl.s * 100)}%, ${Math.round(color.hsl.l * 100)}%)` }));
  };

  const copyToClipboard = (ele) => {
    navigator.clipboard.writeText(ele);
    toast({ title: `Color Code Copied`, description: ele, status: 'success', duration: 2000, isClosable: true, position: 'top' })
  }

  return (
    <Box w={'95%'} m={side?'1.2cm auto 1cm auto':'0.5cm auto'}>
      {!side && <Box display={'flex'} alignItems={'center'} fontSize={'25px'} fontWeight={'bold'} m={'0cm auto 0.5cm auto'}>
        <Text ml={'10px'} cursor={'pointer'} onClick={() => setSide(!side)}>|||</Text>
        <Text ml={'15px'} cursor={'pointer'} onClick={() => navigate('/')}>ArtKorner</Text>
      </Box>}

      <Box display={'flex'} justifyContent={'space-between'} flexDirection={['column', 'column', 'row', 'row']} gap={'10px'}>
        <Box w={['90%', '90%', '50%', '50%']} m={'auto'}>
          <SketchPicker width={'100%'} color={selectedColor} onChange={handleColorChange} />
        </Box>
        <Box w={['90%', '90%', '40%', '40%']} p={'10px'} m={'auto'}>
          <Box w={'100%'} h={'200px'} boxShadow={'none'} bg={`${selectedColor.hex}`}></Box>
          <Box w={'100%'} cursor={'pointer'} textAlign={'center'} border={`1px solid ${selectedColor.hex}`} p={'10px'} m={'10px auto'} onClick={() => copyToClipboard(selectedColor.hex)}>{selectedColor.hex}</Box>
          <Box w={'100%'} cursor={'pointer'} textAlign={'center'} border={`1px solid ${selectedColor.hex}`} p={'10px'} m={'10px auto'} onClick={() => copyToClipboard(selectedColor.rgb)}>{selectedColor.rgb}</Box>
          <Box w={'100%'} cursor={'pointer'} textAlign={'center'} border={`1px solid ${selectedColor.hex}`} p={'10px'} m={'10px auto'} onClick={() => copyToClipboard(selectedColor.hsl)}>{selectedColor.hsl}</Box>
        </Box>
      </Box>
    </Box>
  );
}
