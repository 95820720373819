export const gradient_data = [
    'linear-gradient(30deg, rgb(83, 20, 255), rgb(67, 69, 255), rgb(52, 118, 255), rgb(36, 167, 255), rgb(20, 216, 255), rgb(20, 216, 255), rgb(15, 212, 204), rgb(10, 208, 153), rgb(5, 203, 101), rgb(0, 199, 50))',
    'linear-gradient(30deg, rgb(89, 0, 255), rgb(81, 26, 249), rgb(72, 52, 242), rgb(64, 78, 236), rgb(55, 104, 230), rgb(47, 130, 223), rgb(38, 156, 217), rgb(39, 152, 214), rgb(32, 167, 199), rgb(25, 182, 185), rgb(17, 196, 170), rgb(10, 211, 156), rgb(3, 226, 141), rgb(-4, 241, 126), rgb(31, 229, 120), rgb(76, 226, 96), rgb(121, 223, 72), rgb(165, 219, 48), rgb(210, 216, 24), rgb(255, 213, 0))',
    'linear-gradient(30deg, rgb(68, 255, 0), rgb(50, 231, 138), rgb(32, 207, 276), rgb(103, 146, 138), rgb(228, 1, 1))',
    'linear-gradient(30deg, rgb(255, 0, 0), rgb(81, 63, 302))',
    'linear-gradient(50deg, rgb(255, 102, 0), rgb(200, -173, 390))',
    'linear-gradient(40deg, rgb(255, 0, 0), rgb(241, 9, 14), rgb(228, 19, 28), rgb(214, 28, 42), rgb(200, 38, 56), rgb(187, 47, 70), rgb(173, 56, 84), rgb(159, 66, 98), rgb(146, 75, 112), rgb(132, 84, 126), rgb(118, 94, 140), rgb(105, 103, 154), rgb(91, 113, 168), rgb(77, 122, 182), rgb(64, 131, 196), rgb(50, 141, 210), rgb(36, 150, 224), rgb(40, 149, 217), rgb(38, 154, 212), rgb(35, 160, 207), rgb(32, 165, 201), rgb(30, 170, 196), rgb(27, 176, 191), rgb(25, 181, 185), rgb(22, 186, 180), rgb(20, 192, 175), rgb(17, 197, 170), rgb(15, 202, 164), rgb(12, 208, 159), rgb(10, 213, 154), rgb(7, 218, 148), rgb(4, 224, 143), rgb(2, 229, 138), rgb(-1, 235, 132), rgb(12, 230, 130), rgb(28, 229, 122), rgb(44, 228, 113), rgb(60, 227, 104), rgb(77, 226, 95), rgb(93, 224, 87), rgb(109, 223, 78), rgb(125, 222, 69), rgb(142, 221, 61), rgb(158, 220, 52), rgb(174, 219, 43), rgb(190, 218, 35), rgb(206, 216, 26), rgb(223, 215, 17), rgb(239, 214, 9), rgb(255, 213, 0))',
    'linear-gradient(30deg, rgb(255, 0, 221), rgb(-97, 210, 331))',
    'linear-gradient(30deg, rgb(83, 20, 255), rgb(67, 69, 255), rgb(52, 118, 255), rgb(36, 167, 255), rgb(20, 216, 255), rgb(20, 216, 255), rgb(15, 212, 204), rgb(10, 208, 153), rgb(5, 203, 101), rgb(0, 199, 50))',
    'linear-gradient(30deg, rgb(255, 20, 83), rgb(255, 69, 67), rgb(255, 118, 52), rgb(255, 167, 36), rgb(255, 216, 20), rgb(255, 216, 20), rgb(204, 212, 15), rgb(153, 208, 10), rgb(101, 203, 5), rgb(50, 199, 0))',
    'linear-gradient(30deg, rgb(68, 255, 0), rgb(68, 254, 10), rgb(67, 253, 20), rgb(67, 252, 29), rgb(67, 251, 39), rgb(66, 249, 49), rgb(66, 248, 59), rgb(66, 247, 69), rgb(65, 246, 78), rgb(65, 245, 88), rgb(65, 244, 98), rgb(65, 243, 108), rgb(64, 242, 118), rgb(64, 240, 127), rgb(64, 239, 137), rgb(63, 238, 147), rgb(63, 237, 157), rgb(63, 236, 167), rgb(62, 235, 177), rgb(62, 234, 186), rgb(62, 233, 196), rgb(61, 231, 206), rgb(61, 230, 216), rgb(61, 229, 226), rgb(59, 227, 220), rgb(56, 223, 222), rgb(54, 218, 223), rgb(51, 214, 225), rgb(48, 210, 227), rgb(46, 205, 228), rgb(43, 201, 230), rgb(40, 196, 231), rgb(37, 192, 233), rgb(35, 188, 235), rgb(32, 183, 236), rgb(29, 179, 238), rgb(26, 174, 239), rgb(24, 170, 241), rgb(21, 166, 243), rgb(18, 161, 244), rgb(15, 157, 246), rgb(13, 152, 247), rgb(10, 148, 249), rgb(7, 144, 251), rgb(5, 139, 252), rgb(2, 135, 254), rgb(-1, 131, 256), rgb(0, 130, 255), rgb(0, 124, 255), rgb(0, 118, 255), rgb(1, 112, 255), rgb(1, 106, 255), rgb(1, 100, 255), rgb(1, 95, 255), rgb(1, 89, 255), rgb(1, 83, 255), rgb(2, 77, 255), rgb(2, 71, 255), rgb(2, 65, 255), rgb(2, 59, 255), rgb(2, 53, 255), rgb(3, 47, 255), rgb(3, 41, 255), rgb(3, 35, 255), rgb(3, 30, 255), rgb(3, 24, 255), rgb(3, 18, 255), rgb(4, 12, 255), rgb(4, 6, 255), rgb(4, 0, 255))',
    'linear-gradient(45deg, rgb(255, 0, 0), rgb(255, 165, 0), rgb(0, 128, 0))',
    'linear-gradient(45deg, rgb(0, 0, 255), rgb(0, 255, 255), rgb(0, 128, 0))',
    'linear-gradient(45deg, rgb(0, 0, 255), rgb(0, 128, 128), rgb(0, 255, 0))',
    'linear-gradient(45deg, rgb(0, 0, 255), rgb(128, 0, 128), rgb(0, 128, 0))',
    'linear-gradient(45deg, rgb(128, 0, 0), rgb(128, 0, 128), rgb(0, 128, 0))',
    'linear-gradient(45deg, rgb(255, 0, 0), rgb(255, 255, 0), rgb(0, 128, 0))',
    'linear-gradient(45deg, rgb(128, 0, 0), rgb(128, 128, 0), rgb(0, 128, 0))',
    'linear-gradient(45deg, rgb(128, 0, 0), rgb(128, 128, 0), rgb(0, 0, 128))',
    'linear-gradient(45deg, rgb(255, 0, 150), rgb(255, 150, 0))',
    'linear-gradient(45deg, rgb(150, 0, 255), rgb(0, 150, 255))',
    'linear-gradient(45deg, rgb(255, 0, 0), rgb(0, 255, 0), rgb(0, 0, 255))',
    'linear-gradient(45deg, rgb(255, 255, 0), rgb(0, 255, 255), rgb(255, 0, 255))',
    'linear-gradient(45deg, rgb(0, 255, 0), rgb(255, 0, 255), rgb(0, 255, 0))',
    'linear-gradient(45deg, rgb(0, 0, 255), rgb(255, 255, 0), rgb(0, 0, 255))',
    'linear-gradient(45deg, rgb(128, 0, 128), rgb(0, 128, 128), rgb(128, 128, 0))',
    'linear-gradient(45deg, rgb(255, 0, 0), rgb(0, 255, 0), rgb(0, 0, 255), rgb(255, 255, 0), rgb(0, 255, 255), rgb(255, 0, 255))',
    'linear-gradient(45deg, rgb(255, 0, 0), rgb(255, 150, 0), rgb(255, 255, 0), rgb(0, 255, 0), rgb(0, 255, 255), rgb(0, 0, 255))',
    'linear-gradient(45deg, rgb(0, 0, 0), rgb(64, 64, 64), rgb(128, 128, 128))',
    'linear-gradient(45deg, rgb(255, 0, 0), rgb(255, 150, 0), rgb(255, 255, 0), rgb(0, 255, 0), rgb(0, 255, 255), rgb(0, 0, 255), rgb(128, 0, 128), rgb(128, 128, 0))',
    'linear-gradient(45deg, rgb(0, 255, 0), rgb(255, 0, 255), rgb(0, 0, 0), rgb(255, 255, 255))',
    'linear-gradient(45deg, rgb(0, 0, 0), rgb(255, 255, 255), rgb(0, 0, 0), rgb(255, 255, 255))',
    'linear-gradient(45deg, rgb(255, 0, 0), rgb(0, 255, 0), rgb(0, 0, 255), rgb(0, 255, 0), rgb(255, 0, 0))',
    'linear-gradient(45deg, rgb(0, 0, 0), rgb(255, 255, 0), rgb(0, 255, 255), rgb(255, 0, 255), rgb(255, 0, 0), rgb(0, 255, 0), rgb(0, 0, 0))',
    'linear-gradient(45deg, rgb(0, 0, 128), rgb(0, 128, 0), rgb(128, 128, 0))',
    'linear-gradient(45deg, rgb(255, 0, 0), rgb(255, 165, 0), rgb(255, 255, 0))',
    'linear-gradient(45deg, rgb(128, 0, 128), rgb(0, 128, 0), rgb(0, 128, 128))',
    'linear-gradient(45deg, rgb(0, 0, 128), rgb(128, 0, 128), rgb(0, 128, 128))',
    'linear-gradient(45deg, rgb(0, 0, 128), rgb(128, 0, 0), rgb(128, 128, 0))',
    'linear-gradient(45deg, rgb(0, 128, 0), rgb(0, 128, 128), rgb(0, 128, 255))',
    'linear-gradient(45deg, rgb(0, 128, 0), rgb(0, 0, 128), rgb(128, 0, 128))',
    'linear-gradient(45deg, rgb(128, 128, 0), rgb(0, 128, 128), rgb(0, 128, 255))',
    'linear-gradient(45deg, rgb(128, 128, 0), rgb(0, 128, 0), rgb(0, 0, 128))',
    'linear-gradient(45deg, rgb(0, 128, 128), rgb(0, 128, 0), rgb(128, 0, 128))',
    'linear-gradient(45deg, rgb(0, 0, 0), rgb(255, 255, 255), rgb(0, 0, 0), rgb(255, 255, 255), rgb(0, 0, 0))',
    'linear-gradient(45deg, rgb(255, 0, 0), rgb(0, 255, 0), rgb(0, 0, 255), rgb(0, 255, 0), rgb(255, 0, 0), rgb(0, 255, 0), rgb(0, 0, 255), rgb(0, 0, 0))',
  ];    