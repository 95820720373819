import { Box, Text, Grid, useToast } from '@chakra-ui/react'
import { uni_arrow, uni_currency, uni_all_arrow, uni_punctuation, uni_math, uni_UIDesign } from '../assets/unicode.js'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const UniCode = ({setSide,side}) => {
    const toast = useToast();
    const [content, setContent] = useState(uni_all_arrow)
    const [category, setCategory] = useState('symbol')
    const navigate = useNavigate()

    const copyToClipboard = (ele) => {
        navigator.clipboard.writeText(ele);
        toast({ title: `Code copied`, description: ele, status: 'success', duration: 2000, isClosable: true, position: 'top' })
    }

    return (
        <Box w={'90%'} m={side?'0.7cm auto 1cm auto':'0.5cm auto'}>
            {!side && <Box display={'flex'} alignItems={'center'} fontSize={'25px'} fontWeight={'bold'} m={'0cm auto 0.5cm auto'}>
                <Text ml={'10px'} cursor={'pointer'} onClick={() => setSide(!side)}>|||</Text>
                <Text ml={'15px'} cursor={'pointer'} onClick={() => navigate('/')}>ArtKorner</Text>
            </Box>}

            <Box display={'flex'} flexDirection={['column', 'column', 'row']} justifyContent={'space-between'} m={'15px auto 30px auto'}>
                <Box w={'100%'} display={'flex'} justifyContent={'space-evenly'}>
                    <Text onClick={() => setContent(uni_all_arrow)} borderBottom={content == uni_all_arrow && '1.3px solid grey'} m={['0px 0px 7px 0px','5px']} p={['3px','5px']} fontSize={['12px','14px','14px']} cursor={'pointer'} sx={{ _hover: { borderBottom: '1.3px solid grey', color: '#f5a623' } }} transition={'0.4s ease'}>All</Text>
                    <Text onClick={() => setContent(uni_arrow)} borderBottom={content == uni_arrow && '1.3px solid grey'} m={['0px 0px 7px 0px','5px']} p={['3px','5px']} fontSize={['12px','14px','14px']} cursor={'pointer'} sx={{ _hover: { borderBottom: '1.3px solid grey', color: '#f5a623' }, }} transition={'0.4s ease'}>Arrows</Text>
                    <Text onClick={() => setContent(uni_math)} borderBottom={content == uni_math && '1.3px solid grey'} m={['0px 0px 7px 0px','5px']} p={['3px','5px']} fontSize={['12px','14px','14px']} cursor={'pointer'} sx={{ _hover: { borderBottom: '1.3px solid grey', color: '#f5a623' }, }} transition={'0.4s ease'}>Math</Text>
                    <Text onClick={() => setContent(uni_currency)} borderBottom={content == uni_currency && '1.3px solid grey'} m={['0px 0px 7px 0px','5px']} p={['3px','5px']} fontSize={['12px','14px','14px']} cursor={'pointer'} sx={{ _hover: { borderBottom: '1.3px solid grey', color: '#f5a623' }, }} transition={'0.4s ease'}>Currencies</Text>
                    <Text onClick={() => setContent(uni_punctuation)} borderBottom={content == uni_punctuation && '1.3px solid grey'} m={['0px 0px 7px 0px','5px']} p={['3px','5px']} fontSize={['12px','14px','14px']} cursor={'pointer'} sx={{ _hover: { borderBottom: '1.3px solid grey', color: '#f5a623' }, }} transition={'0.4s ease'}>Punctuation</Text>
                    <Text onClick={() => setContent(uni_UIDesign)} borderBottom={content == uni_UIDesign && '1.3px solid grey'} m={['0px 0px 7px 0px','5px']} p={['3px','5px']} fontSize={['12px','14px','14px']} cursor={'pointer'} sx={{ _hover: { borderBottom: '1.3px solid grey', color: '#f5a623' }, }} transition={'0.4s ease'}>UI Design</Text>
                </Box>
                <Box w={['100%','100%','40%']} m={'atuo'}>
                    <select onChange={(e) => setCategory(e.target.value)} style={{ width: '100%', fontSize: '14px', border: '1px solid grey', borderRadius: '25px', padding: '10px' }}>
                        <option value="" disabled>Select</option>
                        <option value="symbol">Symbol</option>
                        <option value="unicode">Unicode</option>
                        <option value="hex">HEX</option>
                        <option value="htmlEntity">HTML Entity</option>
                        <option value="cssEscape">CSS Escape</option>
                    </select>
                </Box>
            </Box>

            <Grid templateColumns={['repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)', 'repeat(5, 1fr)']} gap={['10px']}>
                {content && content?.map((ele, index) => (
                    <Box key={index} onClick={() => copyToClipboard(ele[`${category}`])} cursor={'pointer'} display={'flex'} justifyContent={'center'} alignItems={'center'} border={'1px solid #E0E0E0'} borderRadius={'10px'} boxShadow={'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'}>
                        <Box h={'100px'} fontSize={'25px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Box>
                                <Box textAlign={'center'}>{ele.symbol}</Box>
                                {ele.name && <Box textAlign={'center'} mt={'3px'} fontSize={'12px'} color={'grey'}>{ele.name}</Box>}
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Grid>
        </Box>
    )
}