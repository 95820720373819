import { Box, Text, Grid, useToast } from '@chakra-ui/react'
import { emoji_data, body_emoji_data, animal_emojis_data, food_emojis_data, activities_emojis_data, travel_emojis_data, object_emojis_data, symbol_emojis_data, country_flag_emojis } from '../assets/emoji.js'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Emoji = ({setSide,side}) => {
    const [content, setContent] = useState(emoji_data)
    const navigate = useNavigate()
    const toast = useToast();

    const copyToClipboard = (ele) => {
        navigator.clipboard.writeText(ele);
        toast({ title: `Code copied ${ele}`, status: 'success', duration: 4000, isClosable: true, position: 'top' })
    }

    return (
        <Box m={side?'1cm auto 1cm auto':'0.5cm auto'}>
            {!side && <Box display={'flex'} alignItems={'center'} fontSize={'25px'} fontWeight={'bold'} m={'0cm auto 0.5cm auto'}>
                <Text ml={'10px'} cursor={'pointer'} onClick={() => setSide(!side)}>|||</Text>
                <Text ml={'15px'} cursor={'pointer'} onClick={() => navigate('/')}>ArtKorner</Text>
            </Box>}

            <Box w={'90%'} display={'flex'} justifyContent={'space-evenly'} flexDirection={['column', 'column', 'row']} m={'1cm auto 0.5cm auto'}>
                <Box w={'100%'} display={'flex'} justifyContent={'space-around'}>
                    <Text onClick={() => setContent(emoji_data)} borderBottom={content == emoji_data && '1.3px solid grey'} m={'5px'} p={'5px'} fontSize={'14px'} cursor={'pointer'} sx={{ _hover: { color: '#f5a623' } }} transition={'0.4s ease'}>Smiley</Text>
                    <Text onClick={() => setContent(body_emoji_data)} borderBottom={content == body_emoji_data && '1.3px solid grey'} m={'5px'} p={'5px'} fontSize={'14px'} cursor={'pointer'} sx={{ _hover: { color: '#f5a623' } }} transition={'0.4s ease'}>People</Text>
                    <Text onClick={() => setContent(animal_emojis_data)} borderBottom={content == animal_emojis_data && '1.3px solid grey'} m={'5px'} p={'5px'} fontSize={'14px'} cursor={'pointer'} sx={{ _hover: { color: '#f5a623' } }} transition={'0.4s ease'}>Animal</Text>
                    <Text onClick={() => setContent(food_emojis_data)} borderBottom={content == food_emojis_data && '1.3px solid grey'} m={'5px'} p={'5px'} fontSize={'14px'} cursor={'pointer'} sx={{ _hover: { color: '#f5a623' } }} transition={'0.4s ease'}>Food</Text>
                </Box>
                <Box w={'100%'} display={'flex'} justifyContent={'space-around'}>
                    <Text onClick={() => setContent(activities_emojis_data)} borderBottom={content == activities_emojis_data && '1.3px solid grey'} m={'5px'} p={'5px'} fontSize={'14px'} cursor={'pointer'} sx={{ _hover: { color: '#f5a623' } }} transition={'0.4s ease'}>Activities</Text>
                    <Text onClick={() => setContent(travel_emojis_data)} borderBottom={content == travel_emojis_data && '1.3px solid grey'} m={'5px'} p={'5px'} fontSize={'14px'} cursor={'pointer'} sx={{ _hover: { color: '#f5a623' } }} transition={'0.4s ease'}>Travel</Text>
                    <Text onClick={() => setContent(object_emojis_data)} borderBottom={content == object_emojis_data && '1.3px solid grey'} m={'5px'} p={'5px'} fontSize={'14px'} cursor={'pointer'} sx={{ _hover: { color: '#f5a623' } }} transition={'0.4s ease'}>Object</Text>
                    <Text onClick={() => setContent(symbol_emojis_data)} borderBottom={content == symbol_emojis_data && '1.3px solid grey'} m={'5px'} p={'5px'} fontSize={'14px'} cursor={'pointer'} sx={{ _hover: { color: '#f5a623' } }} transition={'0.4s ease'}>Symbol</Text>

                </Box>

            </Box>
            <Grid templateColumns={['repeat(4, 1fr)', 'repeat(6, 1fr)', 'repeat(9, 1fr)']} gap={['20px']}>
                {content && content?.map((ele, index) => (
                    <Box key={index} display={'flex'} justifyContent={'center'} alignItems={'center'} cursor={'pointer'} borderRadius={'10px'} sx={{ _hover: { bg: '#ebebeb' } }}>
                        <Box onClick={() => copyToClipboard(ele)} fontSize={'25px'} key={index} p={'15px 10px'} display={'flex'} justifyContent={'center'} alignItems={'center'} bg={ele} color={'white'}>{ele}</Box>
                    </Box>
                ))}
            </Grid>
        </Box>
    )
}